import React from "react";
import styled from "styled-components";
import { PageNotFound } from "../../assets/index";

const NotFoundContainer = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #dae9fa;
`;

const ImagesWrapper = styled.div`
	height: 50%;
	width: 60%;
`;

const StatusCode = styled.div`
	font-size: 6em;
	text-shadow: 0px 1px, 1px 0px, 1px 0px;
	color: #3c4faf;
`;

const Message = styled.div`
	font-size: 2em;
	font-weight: 600;
`;

const NotFoundImageWrapper = styled.img`
	width: 100%;
	height: 100%;
`;

const NotFound = () => {
	return (
		<NotFoundContainer>
			<ImagesWrapper>
				<StatusCode>404</StatusCode>
				<Message>Page Not Found</Message>
				<NotFoundImageWrapper src={PageNotFound}></NotFoundImageWrapper>
			</ImagesWrapper>
		</NotFoundContainer>
	);
};

export default NotFound;
