import React from "react";
import styled from "styled-components";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";

const LineChartWrapper = styled.div`
	padding: 10px;
	height: 300px;
	border-radius: 10px;
	border: 0.1 solid;
	margin: 10px;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
`;

const SimpleLineChart = (props) => {
	const { data, title } = props;

	console.log(data);

	return (
		<LineChartWrapper>
			<h4>{title}</h4>
			<LineChart width={500} height={250} data={data}>
				<XAxis dataKey="month" />
				<YAxis />
				<CartesianGrid stroke="#eee" strokeDasharray="5 5" />
				<Line type="monotone" dataKey="requests" stroke="#fd847c" fill="red" />
				<Line type="monotone" dataKey="responses" stroke="#82ca9d" fill="red" />
			</LineChart>
		</LineChartWrapper>
	);
};

export default SimpleLineChart;
