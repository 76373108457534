import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { useCallback } from "react";
import {
	fetchFreeTrialStatus,
	updateFreeTrialState,
} from "../../../../../store/slices/dispatcherSlice";
import { Breadcrumb } from "antd";

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1em;
	padding: 1em;
	box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px;
`;

const Message = styled.p`
	padding: 0.5em;
	text-transform: ${(props) =>
		props.textTransform ? props.textTransform : "uppercase"};
	text-align: left;
`;

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 1em;
	background: #f5f6fa;
`;

const SubscriptionCard = styled.div`
	background: #ffffff;
	width: 50%;
	margin: 2em;
	border-radius: 2px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	display: flex;
	padding: 1em;
`;

const InstructionCard = styled.div`
	width: 65%;
	padding: 1em;
	display: flex;
	flex-direction: column;
`;

const CountCard = styled.div`
	padding: 1em;
	flex: 1;
	display: flex;
	flex-direction: column;
	border-left: solid 1px #dee5f4;
`;

const SwitchWrapper = styled.div`
	background: #f5f6fa;
	padding: 1em;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const BreadcrumbWrapper = styled.div`
	text-transform: capitalize;
	font-size: 10px;
	padding: 1em;
`;

const ReviewfyFreeTrial = () => {
	const dispatch = useDispatch();
	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const { freeTrialUnitsRemaining, freeTrialActivated } = useSelector(
		(state) => state.dispatcher
	);

	const getFreeTrailStatus = useCallback(
		() => dispatch(fetchFreeTrialStatus()),
		[dispatch]
	);

	const handleToggle = () => {
		dispatch(updateFreeTrialState({ freeTrialActivated: !freeTrialActivated }));
	};

	useEffect(() => {
		getFreeTrailStatus();
	}, [getFreeTrailStatus, dispatch]);

	return (
		<Container>
			<Header>
				<BreadcrumbWrapper>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings`}>
							{"Settings"}
						</Breadcrumb.Item>
						<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings/channels`}>
							{"Channels"}
						</Breadcrumb.Item>
					</Breadcrumb>
				</BreadcrumbWrapper>
				<Message>REVIEWFY FREE TRIAL</Message>
				<Message textTransform={"none"}>
					{
						"If you're yet to configure your email service provider and looking to send out forms, please use our email service for sending up to 10 emails as trial"
					}
				</Message>
			</Header>
			<FlexBox>
				<SubscriptionCard>
					<InstructionCard>
						<Message textTransform={"none"}>
							{
								"Reviewfy will use it's email service providers to send out forms to your customers!"
							}
							<br />
							{"You cannot use free emails to dispatch bulk emails!"}
						</Message>
						<Message textTransform={"none"}>
							By enabling Reviewfy Free Trial emails, we will be using this
							integration as your default email dispatcher
						</Message>
						<SwitchWrapper>
							<p>{`${
								freeTrialActivated ? "Disable" : "Enable"
							} reviewfy email free trial units:`}</p>
							<Switch
								checked={freeTrialActivated}
								disabled={freeTrialUnitsRemaining === 0}
								onChange={handleToggle}
								style={{
									background: `${
										freeTrialActivated ? "var(--primaryColor)" : "grey"
									}`,
								}}
							/>
						</SwitchWrapper>
					</InstructionCard>
					<CountCard>
						<span>{"Your remaining trial email units"}</span>
						<div
							style={{
								fontSize: "6em",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{freeTrialUnitsRemaining}
						</div>
					</CountCard>
				</SubscriptionCard>
			</FlexBox>
		</Container>
	);
};

export default ReviewfyFreeTrial;
