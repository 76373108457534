import React from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import SingleDispatcher from "./components/SingleDispatcher.jsx";
import BulkDispatcher from "./components/BulkDispatcher.jsx";

const { TabPane } = Tabs;

const Container = styled.div`
	background-color: #f5f6fa;
	height: calc(100vh - 60px -80px);
`;

const tabStyle = { backgroundColor: "#ffffff" };

const WhatsappDispatcher = () => {
	return (
		<Container>
			<Tabs defaultActiveKey="1" centered={true} style={tabStyle}>
				<TabPane tab={"WHATSAPP DISPATCHER"} key="1">
					<SingleDispatcher />
				</TabPane>
				<TabPane tab={"BULK WHATSAPP DISPATCHER"} key="2">
					<BulkDispatcher> Sample</BulkDispatcher>
				</TabPane>
			</Tabs>
		</Container>
	);
};

export default WhatsappDispatcher;
