import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";
import { pageLoaded, isSessionExpired } from "./uiSlice";
import { dataURLtoFile } from "../../helpers/utils";

const initialState = {
	isLoggedIn: false,
	session: {},
	msgAlert: "",
};
let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

export const authenticateUser = createAsyncThunk(
	"auth/login",
	async (credentials, ThunkAPI) => {
		const response = await axios.post(
			`${apiEndPoint}/api/auth/login`,
			credentials
		);
		if (response.data) {
			console.log("response.data====", response.data);
			ThunkAPI.dispatch(isSessionExpired(false));
			window.localStorage.setItem("authInfo", JSON.stringify(response.data));
		}

		return response.data;
	}
);

export const registerOrg = createAsyncThunk(
	"auth/register",
	async (orgValues) => {
		const response = await axios.post(
			`${apiEndPoint}/api/org/register`,
			orgValues
		);
		return response.data;
	}
);

export const SendResetLink = createAsyncThunk(
	"auth/resetPwdOtp",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const { email } = params;
			const response = await axios.get(
				`${apiEndPoint}/api/auth/reset-password/send-reset-link?email=${email}`
			);

			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return response.data;
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const updatePassword = createAsyncThunk(
	"auth/update-password",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));

			const response = await axios.post(
				`${apiEndPoint}/api/auth/reset-user-password`,
				params
			);

			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return response.data;
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const validateOrg = createAsyncThunk(
	"auth/orgsignup",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const data = new FormData();
			const { imageUrl } = params;
			if (imageUrl) {
				const file = dataURLtoFile(imageUrl, new Date().toDateString());
				data.append("template", file, file.name);
			}
			const {
				orgName,
				email,
				password,
				confirmPassword,
				domain,
				mobileNumber,
			} = params;
			data.append(
				"metadata",
				JSON.stringify({
					orgName,
					email,
					password,
					domain,
					confirmPassword,
					mobileNumber,
				})
			);

			const response = await axios.post(
				`${apiEndPoint}/api/auth/signup`,
				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			if (response) {
				return response.data;
			}
		} catch (err) {
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const subscribePlan = createAsyncThunk(
	"auth/subscribePlan",
	async (params = {}, ThunkAPI) => {
		const { plan, orgId } = params;
		const response = await axios.post(
			`${apiEndPoint}/api/subscription/create-checkout-session`,
			{ plan, orgId }
		);
		// window.location = response.data.url;
		return response.data;
	}
);

export const verifyPayment = createAsyncThunk(
	"auth/verifypayment",
	async ({ sessionId, orgId }, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		console.log(sessionId, sessionId);
		const response = await axios.post(
			`${apiEndPoint}/api/subscription/verify-subscription`,
			{ sessionId, orgId }
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		isLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload;
		},
	},
	extraReducers: {
		[authenticateUser.fulfilled]: (state, action) => {
			const session = { ...action.payload };
			state.isLoggedIn = true;
			state.session = { ...session };
		},
		[authenticateUser.rejected]: (state, action) => {
			notification.error({
				message: "Authentication Failed",
				description: "Invalid username or password",
			});
		},
		[registerOrg.fulfilled]: (state, action) => {
			state.count.push("asdasda");
		},
		[validateOrg.fulfilled]: (state, action) => {
			state.session = { ...state.session, orgId: action.payload.orgId };
		},
		[validateOrg.rejected]: (state, action) => {
			console.log("action.payload-=-=-=-=", action.payload.message);
			state.msgAlert = "Error message here";
		},
		[subscribePlan.fulfilled]: (state, action) => {
			state.subscribedPlan = true;
		},
		[verifyPayment.fulfilled]: (state, action) => {
			if (action.payload.code === "payment_failed") {
				state.subscribedPlan = false;
			} else if (action.payload.code === "payment_success") {
				state.subscribedPlan = true;
			}
		},
	},
});

export const { isLoggedIn } = authSlice.actions;
export default authSlice.reducer;
