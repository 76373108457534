import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

const EmojiSetWrapper = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	padding: 10px;
	justify-content: flex-start;
	align-items: center;
`;

const EmojiContainer = styled.div`
	padding: 10px 20px 10px 20px;
	text-align: center;
	display: flex;
	align-items: center;
	border-radius: 8px;
	font-size: 14px;
	cursor: ${(props) => (!props.isDisabled ? "pointer" : "default")};
	background-color: ${(props) => (props.selected ? "#ffca4240" : "white")};

	border: ${(props) =>
		props.selected && !props.isDisabled ? "solid 0.2px #8080802e" : "none"};
`;

const DisplaySelected = styled.span`
	text-align: center;
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: center;
`;

const DisplaySelectedEmoji = styled.span`
	font-size: 1.5rem;
`;

const EmojiResponse = (props) => {
	const { disabled, handleChange, contentId, selectedIndex, showSelected } =
		props;
	const [selectedEmoji, setSelectedEmoji] = useState(6);

	const emojiSet = ["😡", "🙁", "😐", "🙂", "😊"];
	const tooltipDesc = [
		"Terrible",
		"Not Satisfied",
		"Okayish",
		"Good",
		"Fantastic",
	];

	useEffect(() => {
		if (selectedIndex) {
			setSelectedEmoji(selectedIndex - 1);
		}
	}, [selectedIndex]);

	return (
		<EmojiSetWrapper>
			{showSelected ? (
				<DisplaySelected>
					<DisplaySelectedEmoji>
						{emojiSet[selectedIndex - 1]}
					</DisplaySelectedEmoji>
					<span
						style={{
							padding: "15px",
						}}
					>
						{tooltipDesc[selectedIndex - 1]}
					</span>
				</DisplaySelected>
			) : (
				emojiSet.map((item, index) => (
					<Tooltip title={tooltipDesc[index]}>
						<EmojiContainer
							onClick={() => {
								if (!disabled) {
									setSelectedEmoji(index);
									handleChange(contentId, index + 1);
								}
							}}
							selected={index === selectedEmoji}
							isDisabled={disabled}
						>
							{item}
						</EmojiContainer>
					</Tooltip>
				))
			)}
		</EmojiSetWrapper>
	);
};

export default EmojiResponse;
