import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, notification, message } from "antd";
import {
	getReviewResponses,
	approveResponses,
	updateUnApprovedResponses,
} from "../../../store/slices/approvalSlice";
import { fetchForms } from "../../../store/slices/formSlice";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import styled from "styled-components";
import { contractConfig } from "./config.js";
import { unApprovedColumns } from "./TableConfig";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";
import { MetamaskFox, ReviewfyCoin } from "../../../assets/index";
import {
	validateReviewState,
	getResponseHash,
} from "../../../store/slices/approvalSlice";

const Container = styled.div`
	height: calc(100vh - 106px);
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f5f7f9;
`;

const FilterBar = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: white;
`;

const UnApprovedAnalytics = styled.div`
	height: 18vh;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
	grid-gap: 1em;
	padding: 1em;
`;

const KPICard = styled.div`
	background: white;
	padding: 0.5em;
	display: flex;
	flex-direction: column;
`;

const WidgetTitle = styled.span`
	width: 100%;
	padding: 0.5em;
	font-size: 12px;
	font-weight: 500;
`;

const StyledKPINumber = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	font-size: 3em;
	font-weight: 500;
	flex: 1 1;
`;

const UnApprovedResponses = (props) => {
	const [selectedRowKeys, setselectedRowKeys] = useState([]);
	// const [showTxnStatus, setShowTxnStatus] = useState(false);
	const [walletAnalytics, setWalletAnalytics] = useState({
		present: 0,
		notPresent: 0,
	});
	// const [searchText, setSearchText] = useState("");
	// const [filteredForm, setFilteredForm] = useState("");

	const [ethParams, setEthParams] = useState({
		provider: "",
		chainId: "",
		orgAddress: "",
		walletBalance: "",
	});

	const { unapprovedResponses } = useSelector((state) => state.approvals);
	const [dataSource, setDataSource] = useState([]);
	const dispatch = useDispatch();

	const fetchFormList = useCallback(() => dispatch(fetchForms()), [dispatch]);
	useEffect(() => {
		dispatch(getReviewResponses({ responseState: "unapproved" }));
		fetchFormList();
	}, [dispatch, fetchFormList]);

	const updateTokenBalance = async () => {
		const ethereum = window.ethereum;
		const provider = await detectEthereumProvider();
		let web3 = new Web3(provider);
		const jsonAbi = contractConfig.abi;
		const contractAddress = contractConfig.contractAddress;
		const contract = new web3.eth.Contract(jsonAbi, contractAddress);
		const chainId = await ethereum.request({ method: "eth_chainId" });
		const accounts = await window.ethereum.request({
			method: "eth_requestAccounts",
		});
		const balanceOf = await contract.methods.balanceOf(accounts[0]).call({
			from: accounts[0],
		});

		setEthParams({
			orgAddress: accounts[0],
			chainId: chainId,
			provider: provider,
			walletBalance: web3.utils.fromWei(balanceOf.toString(), "ether"),
		});
	};

	useEffect(() => {
		setDataSource([...unapprovedResponses]);

		const addrPresent = unapprovedResponses.reduce((n, val) => {
			return n + (val.walletAddress && val.walletAddress.length > 0 ? 1 : 0);
		}, 0);

		const addrNotPresent = unapprovedResponses.reduce((n, val) => {
			return n + (val.walletAddress && val.walletAddress.length === 0 ? 1 : 0);
		}, 0);

		setWalletAnalytics({ present: addrPresent, notPresent: addrNotPresent });

		updateTokenBalance();
	}, [unapprovedResponses]);

	// const handleFormSelection = (formId = null) => {
	// 	// setFilteredForm(formId);
	// 	if (formId) {
	// 		const updatedSource = unapprovedResponses.filter((value) => {
	// 			return value && value.formId === formId;
	// 		});
	// 		setDataSource([...updatedSource]);
	// 	} else {
	// 		setDataSource([...unapprovedResponses]);
	// 	}
	// };

	// const { allForms } = useSelector((state) => state.forms);

	useEffect(() => {
		try {
			(async () => {
				const ethereum = window.ethereum;

				if (typeof ethereum !== "undefined") {
					const provider = await detectEthereumProvider();
					let web3 = new Web3(provider);
					const jsonAbi = contractConfig.abi;
					const contractAddress = contractConfig.contractAddress;
					const contract = new web3.eth.Contract(jsonAbi, contractAddress);
					const chainId = await ethereum.request({ method: "eth_chainId" });
					const accounts = await window.ethereum.request({
						method: "eth_requestAccounts",
					});
					const balanceOf = await contract.methods.balanceOf(accounts[0]).call({
						from: accounts[0],
					});

					setEthParams({
						orgAddress: accounts[0],
						chainId: chainId,
						provider: provider,
						walletBalance: web3.utils.fromWei(balanceOf.toString(), "ether"),
					});
				} else {
					// setMetamaskPresent(true);
				}
			})();
		} catch (err) {
			console.log(err);
		}
	}, []);

	const onSelectChange = (selectedRowKeys) => {
		setselectedRowKeys([...selectedRowKeys]);
	};

	const rowSelection = {
		type: "checkbox",
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const approveTokens = async () => {
		try {
			if (
				selectedRowKeys &&
				selectedRowKeys.length &&
				selectedRowKeys.length < 50
			) {
				const response = await dispatch(
					validateReviewState({
						responseIds: selectedRowKeys,
					})
				).unwrap();

				if (response.code !== "APPROVAL_VALID_REVIEWS") {
					notification.error({
						title: response.message,
					});
					return;
				}

				const updateDataSource = dataSource.filter((item) =>
					selectedRowKeys.includes(item._id)
				);

				let userAddresses = updateDataSource
					.filter(
						(review) => review.walletAddress && review.walletAddress.length
					)
					.map((item) => {
						return {
							rewardAmount: item.tokenValue,
							rewardAddress: item.walletAddress,
						};
					});

				const formattedAddress = userAddresses.map((item) => {
					return [item.rewardAddress, item.rewardAmount];
				});

				const { payload } = await dispatch(
					getResponseHash({ responseIds: selectedRowKeys })
				).unwrap();

				const { hashValue } = payload;

				if (userAddresses.length > 0) {
					let web3 = new Web3(ethParams.provider);
					const jsonAbi = contractConfig.abi;
					const contractAddress = contractConfig.contractAddress;
					const reviewfyContract = new web3.eth.Contract(
						jsonAbi,
						contractAddress
					);

					const surveyId = 10;
					let extraData = await reviewfyContract.methods.distributeRewards(
						formattedAddress,
						200,
						surveyId,
						hashValue
					);
					extraData = extraData.encodeABI();
					const transactionParameters = {
						value: "0x00", // Only required to send ether to the recipient from the initiating external account.
						data: extraData, // Select.Optional, but used for defining smart contract creation and interaction.
						chainId: 80001, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
						from: ethParams.orgAddress,
						to: contractAddress,
					};
					const ethereum = window.ethereum;
					const txnHash = await ethereum.request({
						method: "eth_sendTransaction",
						params: [transactionParameters],
					});
					if (txnHash) {
						await dispatch(
							approveResponses({
								selectedRowKeys,
								txnHash: txnHash,
								surveyHash: hashValue,
							})
						);
						const updateDataSource = dataSource.filter(
							(item) => !selectedRowKeys.includes(item._id)
						);
						dispatch(updateUnApprovedResponses(updateDataSource));
						let transactionComplete = false;
						message.loading("Transaction in progress..");
						while (!transactionComplete) {
							const response = await web3.eth.getTransactionReceipt(txnHash);
							if (response) {
								updateTokenBalance();
								transactionComplete = true;
								message.success("Transaction Completed!");
							}
						}
					}
				} else {
					await dispatch(
						approveResponses({
							selectedRowKeys,
							txnHash: null,
							surveyHash: hashValue,
						})
					);
					const updateDataSource = dataSource.filter(
						(item) => !selectedRowKeys.includes(item._id)
					);
					dispatch(updateUnApprovedResponses(updateDataSource));
				}
			} else if (selectedRowKeys && selectedRowKeys.length > 50) {
				notification.error({
					message: "Maximum of 50 responses can be approved at once.",
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Container>
			<FilterBar>
				<div>
					<Button
						type={"dashed"}
						disabled={selectedRowKeys.length < 1}
						onClick={() => approveTokens()}
					>
						{"Approve"}
					</Button>
				</div>
			</FilterBar>
			<UnApprovedAnalytics>
				<KPICard>
					<WidgetTitle>{"Total Unapproved Responses"}</WidgetTitle>
					<StyledKPINumber>
						{unapprovedResponses && unapprovedResponses.length}
					</StyledKPINumber>
				</KPICard>
				<KPICard>
					<WidgetTitle>{" Responses With Wallet Address"}</WidgetTitle>
					<StyledKPINumber>{walletAnalytics.present}</StyledKPINumber>
				</KPICard>
				<KPICard>
					<WidgetTitle>{"Responses Without Wallet Address"}</WidgetTitle>
					<StyledKPINumber>{walletAnalytics.notPresent}</StyledKPINumber>
				</KPICard>

				<KPICard>
					<img alt={""} src={MetamaskFox} height={"40%"}></img>
					<span
						style={{
							padding: "1em",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontWeight: 500,
						}}
					>
						<CheckCircleOutlined
							style={{ color: "green", marginRight: "0.5em", fontSize: "1em" }}
						/>
						{"Connected"}
					</span>
				</KPICard>
				<KPICard>
					<WidgetTitle>{"Token Balance"}</WidgetTitle>
					<div
						style={{
							display: "flex",
							padding: "1em",
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							fontSize: "2em",
						}}
					>
						<img
							alt={""}
							src={ReviewfyCoin}
							height={"25px"}
							width={"25px"}
							style={{ marginRight: "0.5em" }}
						></img>
						{ethParams && ethParams.walletBalance}
					</div>
				</KPICard>
			</UnApprovedAnalytics>
			<div
				style={{
					padding: "1em",
					flex: 1,
					overflowY: "auto",
				}}
			>
				<Table
					tableLayout={"fixed"}
					dataSource={dataSource}
					columns={unApprovedColumns}
					rowSelection={{ ...rowSelection, selectedRowKeys }}
					align={"center"}
					scroll={{ y: "calc(70vh - 0.5em)" }}
					pagination={false}
					rowKey={"_id"}
					bordered={true}
				/>
			</div>
		</Container>
	);
};

export default UnApprovedResponses;
