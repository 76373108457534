import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	selectedRootNavTab: "",
	orgLogo: null,
	themePalette: {},
	sessionExpired: false,
	alertMsg: "",
	hideNavBar: false,
};

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

function getBearerToken() {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
}

export const getOrgIcon = createAsyncThunk(
	"ui/orgIcon",
	async (params = {}, ThunkAPI) => {
		const response = await axios.get(
			`${apiEndPoint}/api/settings/getOrgConfig`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		return response.data;
	}
);

export const setUiTheme = createAsyncThunk(
	"ui/setTheme",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/settings/console/setTheme`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);

		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		if (response.data) return params;
	}
);

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		pageLoaded: (state, action) => {
			const { loading } = action.payload;
			state.isLoading = loading;
		},
		setRootNavTab: (state, action) => {
			state.selectedRootNavTab = action.payload;
		},
		setThemePalette: (state, action) => {
			state.themePalette = { ...action.payload };
		},
		isSessionExpired: (state, action) => {
			state.sessionExpired = action.payload;
		},
		setAlertMsg: (state, action) => {
			state.alertMsg = action.payload;
		},
		setNavBarVisible: (state, action) => {
			state.hideNavBar = action.payload;
		},
		setOrgLogo: (state, action) => {
			console.log("payyyyyyyloaaaddd", action.payload);
			state.orgLogo = action.payload;
		},
	},
	extraReducers: {
		[getOrgIcon.fulfilled]: (state, action) => {
			state.orgLogo = action.payload.orgLogo;
			state.orgName = action.payload.orgName;
			if (!action.payload.hasOwnProperty("consoleTheme")) {
				state.themePalette = {
					primaryColor: "#1db6ff",
					secondaryColor: "#e97a43",
					secondaryLighterColor: "#ffe5d9",
					buttonBGColor: "#1d7aff",
					buttonFGColor: "#fff",
				};
			} else {
				state.themePalette = action.payload.consoleTheme;
			}
		},
		[setUiTheme.fulfilled]: (state, action) => {
			state.themePalette = { ...action.payload };
		},
	},
});

export const {
	pageLoaded,
	setRootNavTab,
	isSessionExpired,
	setAlertMsg,
	setNavBarVisible,
	setOrgLogo,
} = uiSlice.actions;
export default uiSlice.reducer;
