import React from "react";
import { AreaChart, Area } from "recharts";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import styled from "styled-components";

const KPIContainer = styled.div`
	min-width: 15%;
	border: 0.5 solid;
	border-radius: 10px;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex: 1 1;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0.5em;
	background: #ffffff;
`;

const WidgetTitle = styled.span`
	width: 100%;
	padding: 1em;
	font-size: 12px;
	font-weight: 600;
`;

const TinyAreaChartWrapper = styled.div`
	width: 100%;
	height: 30%;
	padding: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 0px 10px 10px;
	display: ${(props) => (props.showTrends ? "block" : "none")};
`;

const StyledKPINumber = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	font-size: 4em;
	font-weight: 500;
	flex: 1 1;
`;

const KPIAreaChart = (props) => {
	const { title, data, KPINumber, showTrends } = props;

	return (
		<KPIContainer>
			<WidgetTitle>{title}</WidgetTitle>
			<StyledKPINumber>{KPINumber}</StyledKPINumber>
			<TinyAreaChartWrapper showTrends={showTrends}>
				<ResponsiveContainer>
					<AreaChart data={data}>
						<Area
							type="monotone"
							dataKey="count"
							stroke="#fdc300"
							fill="#fdc300"
							strokeWidth={2}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</TinyAreaChartWrapper>
		</KPIContainer>
	);
};

export default KPIAreaChart;
