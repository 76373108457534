import React from "react";
import { Redirect, withRouter } from "react-router";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNavBarVisible } from "../../store/slices/uiSlice";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	const dispatch = useDispatch();
	let authInfo = null;
	const { hideNavBar } = { ...rest };
	try {
		authInfo = JSON.parse(localStorage.getItem("authInfo"));
	} catch (err) {
		console.error("Unable to fetch AuthInfo");
	}

	if (hideNavBar) {
		dispatch(setNavBarVisible(true));
	}
	return (
		<Route
			{...rest}
			render={(props) => {
				if (authInfo && authInfo.token) {
					return <Component {...rest} />;
				}
				return (
					<Redirect
						to={{
							pathname: "/signin",
							state: { from: props.location },
						}}
					></Redirect>
				);
			}}
		/>
	);
};

export default withRouter(PrivateRoute);
