import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Switch, Radio, List } from "antd";
import { useDispatch } from "react-redux";
import { getGenerativeContents } from "../../../../store/slices/contentSlice";
import { FormHeaderLogo } from "../../../../assets/index";
import { ContentResponseTypes } from "../../../../helpers/utils";

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const IconEnabler = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
`;

const QuestionBox = styled.div`
	display: flex;
`;

const SuggestionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1em;
	width: 100%;
`;

const StyledButton = styled.button`
	width: 100%;
	display: ${(props) => (props.show ? "block" : "none")};
	height: 50px;
	padding: 10px;
	border-radius: 5px;
	background-color: var(--primaryColor);
	color: white;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`;

const SelectedIcon = styled.div`
	display: ${(props) => (props.showIcon ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	padding: 1em;
`;

const ChooseIcon = styled.div`
	width: 100%;
	background-color: white;
	display: ${(props) => (props.showIcon ? "flex" : "none")};
	flex-direction: row;
	align-items: center;
	padding: 13px 20px;
	gap: 10px;
	border-radius: 10px;
	height: 55px;
	margin-top: 1.8em;
	border: 1px solid #e1e4f2;
	cursor: pointer;
`;

const ResponseTypeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1.8em;
	width: 100%;
`;

const CreateByAI = (props) => {
	const dispatch = useDispatch();
	const {
		selectedIcon,
		createQuestion,
		closePane,
		setSelectedIcon,
		question,
		setQuestion,
		setAiText,
		aiText,
	} = props;

	const [needIcons, setNeedIcons] = useState(false);
	const [selectedSuggestion, setSelectedSuggestion] = useState(null);
	const [suggestions, setSuggestions] = useState([]);
	const [reviewQuestion, setReviewQuestion] = useState(false);

	const handleReview = () => {
		setReviewQuestion(true);
	};

	const handleSubmit = () => {
		createQuestion(question);
		setQuestion({
			responseType: "",
			questionText: "",
			iconURL: "",
		});
	};

	const getQuestionSuggestions = async () => {
		let { questionText } = question;
		if (questionText.length) {
			const response = await dispatch(
				getGenerativeContents({ questionText: questionText.trim() })
			).unwrap();

			if (response.payload) {
				const { suggestions } = response.payload;
				setSuggestions([...suggestions]);
			}
		}
	};

	const handleIconSwitch = () => {
		if (needIcons && selectedIcon && selectedIcon._id) {
			setSelectedIcon();
		}
		setNeedIcons(!needIcons);
	};

	return (
		<Container>
			{reviewQuestion ? (
				<>
					<SelectedIcon showIcon={selectedIcon && selectedIcon._id}>
						<img
							alt={""}
							src={selectedIcon && selectedIcon.iconURL}
							width="86px"
							height="86px"
						></img>
					</SelectedIcon>
					<Input
						showCount={true}
						placeholder={"Type your Question (100 chars max)"}
						style={{ padding: "17px 20px", borderRadius: "10px" }}
						maxLength={100}
						value={aiText}
						disabled={true}
					></Input>
					<ResponseTypeWrapper>
						<span
							style={{
								fontWeight: 400,
								fontSize: "12px",
								lineHeight: "12px",
								color: "#474E6C",
								padding: "0.4em 0px",
							}}
						>
							SELECT RESPONSE TYPE
						</span>
						<Radio.Group
							onChange={({ target }) =>
								setQuestion({ ...question, responseType: target.value })
							}
							value={question && question.responseType}
						>
							<List
								bordered
								dataSource={ContentResponseTypes}
								renderItem={(item) => (
									<List.Item
										style={{
											// border: "2px solid var(--primaryColor)",
											display: "flex",
											height: "60px",
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Radio value={item.responseType}></Radio>
											<img
												alt={""}
												src={item.responseTypeIcon}
												height="100%"
												style={{ marginLeft: "1em" }}
											></img>
										</div>
									</List.Item>
								)}
							/>
						</Radio.Group>
					</ResponseTypeWrapper>
					<StyledButton show={true} onClick={() => handleSubmit()}>
						{"SUBMIT"}
					</StyledButton>
				</>
			) : (
				<>
					<IconEnabler>
						{"ENABLE ICON FOR THIS QUESTION"}
						<Switch
							checked={needIcons}
							onChange={() => handleIconSwitch()}
						></Switch>
					</IconEnabler>
					<SelectedIcon showIcon={selectedIcon && selectedIcon._id}>
						<img
							alt={""}
							src={selectedIcon && selectedIcon.iconURL}
							width="86px"
							height="86px"
						></img>
					</SelectedIcon>
					<QuestionBox>
						<Input
							// style={{ borderRadius: "10px 0px 0px 10px" }}
							placeholder={"Type your prompt!"}
							value={question && question.questionText}
							onChange={({ target }) =>
								setQuestion({ ...question, questionText: target.value })
							}
						></Input>
						<Button
							disabled={question && question.questionText.length < 3}
							onClick={() => getQuestionSuggestions()}
						>
							{"GENERATE SUGGESTIONS"}
						</Button>
					</QuestionBox>
					<SuggestionsWrapper
						style={{ display: `${suggestions.length ? "flex" : "none"}` }}
					>
						<span style={{ padding: "1em 0px" }}>{"SELECT A SUGGESTION "}</span>
						<Radio.Group
							onChange={({ target }) => {
								setSelectedSuggestion(target.value);
								setAiText(suggestions[target.value]);
							}}
							value={selectedSuggestion}
						>
							<List
								bordered
								dataSource={suggestions}
								renderItem={(item, index) => (
									<List.Item
										key={index}
										style={{
											display: "flex",
											height: "60px",
											border: `${
												index === selectedSuggestion
													? "border: 2px solid #6080F5"
													: "none"
											}`,
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Radio value={index}></Radio>
											<span>{item}</span>
										</div>
									</List.Item>
								)}
							/>
						</Radio.Group>
					</SuggestionsWrapper>
					<ChooseIcon
						onClick={() => closePane(true)}
						showIcon={!(selectedIcon && selectedIcon._id) && needIcons}
					>
						<img src={FormHeaderLogo} alt={""}></img>
						<span
							style={{
								color: "#6080F5",
								fontWeight: 700,
							}}
						>
							Choose Icon
						</span>
					</ChooseIcon>
					<StyledButton
						show={suggestions.length > 0 ? true : false}
						onClick={() => handleReview()}
					>
						{"Choose"}
					</StyledButton>
				</>
			)}
		</Container>
	);
};

export default CreateByAI;
