import React, { useContext } from "react";
import styled from "styled-components";
import FormNavItems from "./common/FormNavItems";
import { FormManagementContext } from ".";
import ContentNavIcon from "../../assets/images/ContentNavIcon.svg";
import FormNavIcon from "../../assets/images/FormNavIcon.svg";

const FormContentsContainer = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex: 1;
`;

const FormLeftNavBar = styled.div`
	width: 15%;
	min-width: 200px;
	height: 100%;
	padding: 1em;
	display: inline-block;
	border-right: solid 1px #dee5f4;
`;

const FormNavContentWrapper = styled.div`
	display: flex;
	overflow-y: auto;
	flex: 1;
`;

const FormManagement = (props) => {
	const navItems = [
		{ _id: "contents", tabName: "Question Library", srcIcon: ContentNavIcon },
		{ _id: "forms", tabName: "Forms", srcIcon: FormNavIcon },
	];

	// selectedSubNav,
	const { selectedFormNavTab, setSelectedFormNavTab, setSelectedSubNav } =
		useContext(FormManagementContext);

	const handleSelectedTab = (itemName) => {
		setSelectedFormNavTab(itemName);
		if (itemName === "forms") {
			setSelectedSubNav("displayForms");
		} else {
			setSelectedSubNav("displayContents");
		}
	};

	return (
		<FormContentsContainer>
			<FormLeftNavBar>
				{navItems.map((item, index) => {
					return (
						<FormNavItems
							key={index}
							itemName={item.tabName}
							handleSelectedTab={() => handleSelectedTab(item._id)}
							isSelectedTab={selectedFormNavTab === item._id}
							srcIcon={item.srcIcon}
						></FormNavItems>
					);
				})}
			</FormLeftNavBar>
			<FormNavContentWrapper>{props.children}</FormNavContentWrapper>
		</FormContentsContainer>
	);
};

export default FormManagement;
