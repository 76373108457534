import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Avatar, Tag, Alert, Input, Button, notification } from "antd";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../../store/slices/settingSlice";
import { isLoggedIn } from "../../../../store/slices/authSlice";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: calc(100vh - 60px);
`;

const Header = styled.div`
	text-align: left;
	padding: 0.5em;
	font-weight: 600;
`;
const ContextContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 4px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
`;

const StyledParagraph = styled.p`
	font-weight: 500;
	font-size: 1em;
	text-align: left;
	padding: 0.5em;
	line-height: 1.6;
`;

const ProfileContext = styled.div`
	background: #f5f6fa;
	padding: 1em;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const FieldsContainer = styled.div`
	min-height: 80%;
	width: 60%;
	display: flex;
	padding: 0.5em;
	flex-direction: column;
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 80%;
	}
	@media screen and (max-width: 800px) {
		width: 90%;
	}
`;

const FieldBox = styled.div`
	background: white;
	padding: 1em;
	border-radius: 0.2em;
	min-height: 10%;
	display: flex;
	margin-bottom: 1em;
`;

const StyledLabel = styled.label`
	width: 100%;
	padding: 0.5em 0.5em 0.5em 0px;
	text-align: left;
	font-weight: 500;
	margin-top: 1em;
	:after {
		content: " *";
		color: red;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	background: green;
`;

const ErrorSpan = styled.span`
	padding: 3px;
	display: none;
	color: red;
	font-size: 0.7em;
`;

const renderUserName = (email) => {
	try {
		return (
			<Avatar
				size={50}
				style={{
					backgroundColor: "var(--primaryColor)",
					color: "white",
					fontWeight: 600,
				}}
			>
				{email[0].toUpperCase()}
			</Avatar>
		);
	} catch {
		return <Avatar size={40}>U</Avatar>;
	}
};

const Profile = (props) => {
	const dispatch = useDispatch();

	const [credentials, setCredentials] = useState({
		currentPwd: "",
		newPwd: "",
		confirmPwd: "",
	});

	const handlePwdChange = async () => {
		const { newPwd, confirmPwd } = credentials;
		if (newPwd !== confirmPwd) {
			document.getElementById("confirmPwd-span").style.display = "block";
			document.getElementById("confirmPwd-span").innerHTML =
				"Password confirmation does not match with the password!";
			return null;
		}
		if (newPwd.length < 6 || confirmPwd < 6) {
			document.getElementById("confirmPwd-span").style.display = "block";
			document.getElementById("confirmPwd-span").innerHTML =
				"Passwords must be a minimum of 6 characters!";
			document.getElementById("newPwd-span").style.display = "block";
			document.getElementById("newPwd-span").innerHTML =
				"Passwords must be a minimum of 6 characters!";
			return null;
		}
		const response = await dispatch(resetPassword({ ...credentials })).unwrap();
		if (response) {
			notification.success({
				message: "Your password has been reset successfully!",
			});

			setTimeout(() => {
				window.localStorage.clear();
				props.history.push("/signin");
				dispatch(isLoggedIn(false));
			}, 1000);
		}
	};

	const { email } = JSON.parse(localStorage.getItem("authInfo"));
	return (
		<Container>
			<ContextContainer>
				<Header>{"PROFILE SETTINGS"}</Header>
				<StyledParagraph>
					Manage your profile information and account settings here!
				</StyledParagraph>
			</ContextContainer>
			<ProfileContext>
				<FieldsContainer>
					<FieldBox>
						<Fragment>{renderUserName(email)}</Fragment>
						<span style={{ padding: "1em" }}>{email}</span>
						<span style={{ padding: "1em" }}>|</span>
						<span style={{ padding: "1em" }}>
							<Tag color={"blue"}>ADMIN</Tag>
						</span>
					</FieldBox>
					<FieldBox
						style={{ flexDirection: "column", alignItems: "flex-start" }}
					>
						<Header
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
							}}
						>
							<span>{"Change Password"}</span>
							<ButtonContainer>
								<Button
									onClick={handlePwdChange}
									disabled={
										!(
											credentials.currentPwd.length &&
											credentials.newPwd.length &&
											credentials.confirmPwd.length
										)
									}
								>
									{"Update Password"}
								</Button>
							</ButtonContainer>
						</Header>
						<Alert
							message="After you update your password, you will be logged out and asked to login again"
							type="warning"
							showIcon
							style={{ width: "100%", textAlign: "left", marginTop: "1em" }}
						/>
						<StyledLabel>Enter current password</StyledLabel>
						<Input.Password
							type={"password"}
							value={credentials.currentPwd}
							onChange={({ target }) => {
								setCredentials({ ...credentials, currentPwd: target.value });
							}}
						/>
						<ErrorSpan id="currentPwd-span" />
						<StyledLabel>Enter new password</StyledLabel>
						<Input.Password
							type={"password"}
							value={credentials.newPwd}
							onChange={({ target }) => {
								setCredentials({ ...credentials, newPwd: target.value });
							}}
						/>
						<ErrorSpan id="newPwd-span" />
						<StyledLabel>Confirm new password</StyledLabel>
						<Input.Password
							type={"password"}
							value={credentials.confirmPwd}
							onChange={({ target }) => {
								setCredentials({ ...credentials, confirmPwd: target.value });
							}}
						/>
						<ErrorSpan id="confirmPwd-span" />
					</FieldBox>
				</FieldsContainer>
			</ProfileContext>
		</Container>
	);
};

export default Profile;
