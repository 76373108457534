import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import DeleteModal from "./DeleteContent";
import { DeleteIcon } from "../../../../assets/index";
import { getContentDisplayIcon } from "../../../../helpers/utils";
import parse from "html-react-parser";

const CardWrapper = styled.div`
	width: 100%;
	height: 230px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	cursor: pointer;
	background-color: white;
	border: solid 1px #dee5f4;
	border-radius: 0.625em;
	:hover {
		box-shadow: none;
		transition: 0.7s;
	}
	padding: 1.375em;
`;

const ContentText = styled.div`
	font-weight: 600;
	word-break: break-word;
	font-size: ${(props) =>
		props.hovered && props.questionLength > 50 ? "14px" : "16px"};
	text-align: left;
`;

const ActionBar = styled.div`
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const QuestionWrapper = styled.div`
	background-color: white;
	position: absolute;
	left: 0px;
	top: ${(props) => (props.hovered && props.iconQuestion ? "auto" : "0px")};
	bottom: ${(props) => (props.hovered && props.iconQuestion ? "0px" : "auto")};
	padding: 1em;
	width: 100%;
	animation: 3s linear 1s slidein;
`;

const ActionIcon = styled.img`
	cursor: pointer;
	z-index: 1000;
	position: absolute;
	right: 12px;
	bottom: 12px;
`;

const CreationTime = styled.span`
	font-size: 10px;
	font-weight: 500;
	color: #7b8eaf;
`;

const ResponseTypeWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: 1em 0px;
`;

const ResponseTypeIcon = styled.img`
	max-height: 32px;
`;

const IconWrapper = styled.div`
	background-color: white;
	height: 30%;
	position: absolute;
	top: 1em;
	width: 30%
	padding: 1em 0px;
	display: ${(props) => (props.iconQuestion ? "flex" : "none")};
	z-index: ${(props) => (props.hovered ? "1000" : "0")}; 
`;

const formatCreationTime = (createdAt) => {
	let formattedDate = new Date(createdAt);
	let createdAtString = `${formattedDate.toDateString()}, ${formattedDate.getHours()}:${
		formattedDate.getMinutes() > 9
			? formattedDate.getMinutes()
			: "0" + formattedDate.getMinutes()
	}`;
	return createdAtString;
};

const ContentBox = (props) => {
	const [isCardHovered, setCardHovered] = useState(false);
	const { _id, queryType, queryValue, createdAt, responseType, iconLabel } =
		props.contentItem;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [deleteContentId, setDeleteContentId] = useState("");

	return (
		<CardWrapper
			onMouseEnter={() => setCardHovered(true)}
			onMouseLeave={() => setCardHovered(false)}
		>
			<ActionIcon
				src={DeleteIcon}
				onClick={() => {
					setIsModalVisible(true);
					setDeleteContentId(_id);
				}}
			></ActionIcon>
			<DeleteModal
				isVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				deleteContentId={deleteContentId}
			></DeleteModal>
			<IconWrapper hovered={isCardHovered} iconQuestion={queryType === "icon"}>
				<img
					src={queryValue}
					width={"100%"}
					height={"100%"}
					style={{ objectFit: "contain" }}
					alt={""}
				></img>
			</IconWrapper>
			<QuestionWrapper
				hovered={isCardHovered}
				iconQuestion={queryType === "icon"}
			>
				{queryType === "icon" ? (
					<ContentText
						hovered={isCardHovered}
						questionLength={iconLabel && iconLabel.length}
					>
						{iconLabel}
					</ContentText>
				) : (
					<ContentText>{parse(queryValue)}</ContentText>
				)}
				<ResponseTypeWrapper>
					<ResponseTypeIcon
						src={getContentDisplayIcon(responseType)}
					></ResponseTypeIcon>
				</ResponseTypeWrapper>
			</QuestionWrapper>
			<ActionBar>
				<CreationTime>{formatCreationTime(createdAt)}</CreationTime>
			</ActionBar>
		</CardWrapper>
	);
};

export default withRouter(ContentBox);
