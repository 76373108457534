export const APIToken = require("./images/APIToken.svg");
export const ContentIconSmiley = require("./images/ContentIconSmiley.svg");
export const ContentRateIcon = require("./images/ContentRateIcon.svg");
export const ContentTextIcon = require("./images/ContentTextIcon.svg");
export const DeleteIcon = require("./images/DeleteIcon.svg");
export const FormAnalysisEmptyState = require("./images/FormAnalysisEmptyState.svg");
export const FormHeaderLogo = require("./images/FormHeaderLogo.svg");
export const FormIcon = require("./images/FormIcon.png");
export const Integration = require("./images/Integration.svg");
export const LoadingIcon = require("./images/Loading.svg");
export const LoginDoodle = require("./images/LoginDoodle.svg");
export const Metamask = require("./images/Metamask.png");
export const NoFormsAvailable = require("./images/NoFormsAvailable.svg");
export const NoFormSelected = require("./images/NoFormSelected.svg");
export const NoIntegration = require("./images/NoIntegration.svg");
export const NoMoreContents = require("./images/NoMoreContents.svg");
export const People = require("./images/People.svg");
export const Profile = require("./images/Profile.svg");
export const ReviewfyDoodle = require("./images/ReviewfyDoodle.svg");
export const ReviewfyLogo = require("./images/ReviewfyLogo.svg");
export const SearchIcon = require("./images/SearchIcon.svg");
export const ThemeingIcon = require("./images/ThemingIcon.svg");
export const Wave = require("./images/Wave.svg");
export const PageNotFound = require("./images/PageNotFound.svg");
export const ForgotPasswordEmail = require("./images/ForgotPasswordEmail.svg");
export const SendFormIcon = require("./images/SendForm.svg");
export const SettingsIcon = require("./images/Settings.svg");
export const FormContentIcon = require("./images/FormContent.svg");
export const ResponseTabIcon = require("./images/ResponseTabIcon.svg");
export const ContentNavIcon = require("./images/ContentNavIcon.svg");
export const FormNavIcon = require("./images/FormNavIcon.svg");
export const EditIcon = require("./images/EditIcon.svg");
export const PreviewIcon = require("./images/Preview.svg");
export const FormIconAsset = require("./images/FormIcon.png");
export const Published = require("./images/Published.svg");
export const WarningIcon = require("./images/WarningIcon.svg");
export const DashboardIcon = require("./images/DashboardIcon.svg");
export const NoReviewData = require("./images/NoReviewData.svg");
export const NoNotifications = require("./images/NoNotifications.svg");
export const RegisterWave = require("./images/RegisterWave.svg");
export const DiscordColorLogo = require("./images/Discord-Logo-Color.svg");
export const NoDataFound = require("./images/NoNDataFound.svg");
export const WhatsappIcon = require("./images/WhatsappLogo.png");
export const MailIcon = require("./images/CorporateLogo.svg");
export const QRIcon = require("./images/QRCodeIcon.png");
export const SlackLogo = require("./images/SlackLogo.svg");
export const AddContentCardIcon = require("./images/AddContentCardIcon.svg");
export const AddFormCardIcon = require("./images/AddFormCardIcon.png");
export const DNDDemo = require("./images/DNDDemo.gif");
export const WebhookIcon = require("./images/WebhookIcon.svg");
export const Warning = require("./images/Warning.png");
export const TwillioSendGrid = require("./images/TwillioSendGrid.svg");
export const Billing = require("./images/Billing.svg");
export const WhatsAppMonochrome = require("./images/WhatsAppMonochrome.png");
export const ReviewfyTagLine = require("./images/ReviewfyTagLine.svg");
export const Minus = require("./images/minus.png");
export const EditWrite = require("./images/write.png");
export const ReviewfyCoin = require("./images/ReviewfyCoin.png");
export const LikeDisLikeIcon = require("./images/LikeDislikeIcon.png");
export const SliderIcon = require("./images/Slider.png");
export const RegisterOrg = require("./images/RegisterOrg.svg");
export const WorkspaceIcon = require("./images/Workspace.png");
export const EmptyTeams = require("./images/EmptyTeams.svg");
export const MetamaskFox = require("./images/MetamaskFox.svg");
export const ContentSlider = require("./images/ContentSlider.svg");
export const GroupEmoji = require("./images/GroupEmoji.svg");
export const ContentRate = require("./images/ContentRate.svg");
export const ContentLikeDisLike = require("./images/ContentLikeDisLike.svg");
export const CloseModal = require("./images/CloseModal.svg");
export const MagicWand = require("./images/MagicWand.svg");
export const IconUpload = require("./images/IconUpload.svg");
export const TextResponse = require("./images/TextResponse.svg");
export const UREExcellent = require("./images/Emoji/Excellent.svg");
export const UREVeryGood = require("./images/Emoji/VeryGood.svg");
export const UREGood = require("./images/Emoji/Good.svg");
export const UREFair = require("./images/Emoji/Fair.svg");
export const UREPoor = require("./images/Emoji/Poor.svg");
export const URLLike = require("./images/Like/like.svg");
export const URLDisLike = require("./images/Like/dislike.svg");
export const URRPoor = require("./images/Rate/rating=1.svg");
export const URRFair = require("./images/Rate/rating=2.svg");
export const URRGood = require("./images/Rate/rating=3.svg");
export const URRVeryGood = require("./images/Rate/rating=4.svg");
export const URRExcellent = require("./images/Rate/rating=5.svg");
export const URSPoor = require("./images/Slider/rating=poor.svg");
export const URSFair = require("./images/Slider/rating=fair.svg");
export const URSGood = require("./images/Slider/rating=good.svg");
export const URSVeryGood = require("./images/Slider/rating=very good.svg");
export const URSExcellent = require("./images/Slider/rating=excellent.svg");