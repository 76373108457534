import React from "react";
import styled from "styled-components";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const Container = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
`;

const IconContainer = styled.span`
	padding: 1em;
	width: 80px;
	text-align: center;
	font-size: 1rem;
`;

const Wrapper = styled.span`
	padding: 1em;
	border-radius: 50%;
`;

const LikeDisLike = (props) => {
	const { showSelected, selectedIndex } = props;

	console.log(selectedIndex);
	return (
		<>
			{showSelected ? (
				<Container
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<IconContainer
						style={{ display: `${selectedIndex === 0 ? "block" : "none"}` }}
					>
						<Wrapper>
							<DislikeOutlined />
						</Wrapper>
					</IconContainer>
					<IconContainer
						style={{ display: `${selectedIndex === 1 ? "block" : "none"}` }}
					>
						<Wrapper>
							<LikeOutlined />
						</Wrapper>
					</IconContainer>
				</Container>
			) : (
				<Container>
					<IconContainer>
						<Wrapper>
							<DislikeOutlined />
						</Wrapper>
					</IconContainer>
					<IconContainer>
						<Wrapper>
							<LikeOutlined />
						</Wrapper>
					</IconContainer>
				</Container>
			)}
		</>
	);
};

export default LikeDisLike;
