import React from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import styled from "styled-components";

const BarChartContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const WidgetTitle = styled.span`
	width: 100%;
	padding: 1em;
	font-size: 12px;
	font-weight: 600;
`;
const ChartContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding: 1em;
`;

const COLORS = ["#0088FE", "#fdc300", "#fd847c", "#8884d8"];

const SimpleBarChart = (props) => {
	const { title, contentAnalytics } = props;

	const color = COLORS[Math.floor(Math.random() * COLORS.length)];

	return (
		<BarChartContainer>
			<WidgetTitle>{title}</WidgetTitle>
			<ChartContainer>
				<ResponsiveContainer>
					<BarChart
						data={contentAnalytics}
						margin={{ top: 5, right: 5, left: 0, bottom: 10 }}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="rating" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar dataKey="value" fill={color} />
					</BarChart>
				</ResponsiveContainer>
			</ChartContainer>
		</BarChartContainer>
	);
};

export default SimpleBarChart;
