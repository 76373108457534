import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Input, Switch } from "antd";
import { TwillioSendGrid } from "../../../../../assets/index";
import {
	getChannelConfig,
	updateSendGridAPIKeys,
} from "../../../../../store/slices/settingSlice";
import { supportedEmailProviders } from "../../../../../helpers/utils";
import { updateDefaultDispatcher } from "../../../../../store/slices/dispatcherSlice";
import { Breadcrumb } from "antd";

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1em;
	padding: 1em;
	box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px;
`;

const Message = styled.p`
	padding: 0.5em;
	text-transform: ${(props) =>
		props.textTransform ? props.textTransform : "uppercase"};
	text-align: left;
	line-height: 1.6;
`;

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 1em;
	background: #f5f6fa;
`;

const SubscriptionCard = styled.div`
	background: #ffffff;
	width: 40%;
	margin: 2em;
	border-radius: 2px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	display: flex;
	flex-direction: column;
	padding: 1em;
`;

const InputWrapper = styled.div`
	padding: 0.5em;
	font-weight: 500;
	display: flex;
	flex-direction: ${(props) => (props.flexDir ? props.flexDir : "row")};
	justify-content: space-between;
	align-items: ${(props) => (props.alignment ? props.alignment : "center")};
`;

const IntegLogo = styled.img`
	width: 30%;
	margin-bottom: 1em;
`;

const TitleWrap = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
	justify-content: space-between;
`;

const StyledButton = styled.button`
	padding: 0.5em 1em;
	border: none;
	font-weight: 500;
	border-radius: 4px;
	background: var(--primaryColor);
	color: white;
	cursor: pointer;
`;

const BreadcrumbWrapper = styled.div`
	text-transform: capitalize;
	font-size: 10px;
	padding: 1em;
`;

const SendGridConfig = () => {
	const dispatch = useDispatch();

	const [verifiedEmail, setVerifiedEmail] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [isEditable, setEditable] = useState(true);
	const [toggled, setToggled] = useState(false);

	const editButton = useRef("add");

	const { sendGridDispatcher } = useSelector((state) => state.settings);
	const { defaultEmailDispatcher } = useSelector((state) => state.dispatcher);
	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const getSendGridConfig = useCallback(
		() =>
			dispatch(getChannelConfig({ channel: "email", integration: "twilio" })),
		[dispatch]
	);

	useEffect(() => {
		getSendGridConfig();
	}, [getSendGridConfig]);

	useEffect(() => {
		setToggled(
			supportedEmailProviders.TWILIO_SENDGRID === defaultEmailDispatcher
		);
	}, [defaultEmailDispatcher]);

	useEffect(() => {
		if (sendGridDispatcher) {
			setVerifiedEmail(sendGridDispatcher.verifiedEmail);
			setApiKey(sendGridDispatcher.sendgridApiKey);
			setEditable(false);
		}
	}, [sendGridDispatcher, defaultEmailDispatcher]);

	const handleAPIKeyChange = (toggleChanged = false) => {
		if (!isEditable) {
			editButton.current.name = "edit";
			editButton.current.innerHTML = "Update Configuration";
			setEditable(!isEditable);
		} else {
			setEditable(!isEditable);
			editButton.current.name = "add";
			editButton.current.innerHTML = "Modify Configuration";
			const { verifiedEmail: sgVerifiedEmail, sendgridApiKey } =
				sendGridDispatcher;

			if (
				sendgridApiKey !== apiKey ||
				sgVerifiedEmail !== verifiedEmail ||
				toggleChanged
			) {
				setToggled(!toggled);
				const defaultEmailDispatcher = toggled
					? supportedEmailProviders.NOT_CONFIGURED
					: supportedEmailProviders.TWILIO_SENDGRID;
				dispatch(
					updateDefaultDispatcher({ dispatcher: defaultEmailDispatcher })
				);
				dispatch(
					updateSendGridAPIKeys({
						sendgridApiKey: apiKey,
						verifiedEmail: sgVerifiedEmail,
						defaultEmailDispatcher: defaultEmailDispatcher,
					})
				);
			}
		}
	};

	return (
		<Container>
			<Header>
				<BreadcrumbWrapper>
					<Breadcrumb separator=">">
						<Breadcrumb separator=">">
							<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings`}>
								{"Settings"}
							</Breadcrumb.Item>
							<Breadcrumb.Item
								href={`/${selectedWorkspaceId}/settings/channels`}
							>
								{"Channels"}
							</Breadcrumb.Item>
						</Breadcrumb>
					</Breadcrumb>
				</BreadcrumbWrapper>
				<Message>TWILIO SENDGRID</Message>
				<Message textTransform={"none"}>
					Integrate your Twilio with Reviewfy, You can send out emails with your
					own Twilio account - Paste in your API keys and make this your default
					email dispatcher to get started!
				</Message>
			</Header>
			<FlexBox>
				<SubscriptionCard>
					<TitleWrap>
						<IntegLogo src={TwillioSendGrid}></IntegLogo>
						<StyledButton
							name={"add"}
							ref={editButton}
							onClick={() => handleAPIKeyChange()}
						>
							{"Modify Configuration"}
						</StyledButton>
					</TitleWrap>
					<Message textTransform={"none"}>
						Add your SendGrid API Keys and your verified email address with
						Twilio ! If your not sure on how to get your API keys - Follow this
						<a
							href="https://docs.sendgrid.com/ui/account-and-settings/api-keys#creating-an-api-key"
							target="_blank"
							rel="noopener noreferrer"
						>
							{" link "}
						</a>
						!
					</Message>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your SendGrid API Key</p>
						<Input
							value={apiKey}
							onChange={({ target }) => setApiKey(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your verified email with Twilio SendGrid</p>
						<Input
							value={verifiedEmail}
							onChange={({ target }) => setVerifiedEmail(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper
						style={{
							background: "#f5f6fa",
							padding: "1em",
							margin: "0.5em",
							alignItems: "center",
						}}
					>
						Make this your default email dispatcher
						<Switch
							checked={toggled}
							disabled={!isEditable}
							onChange={() => handleAPIKeyChange(true)}
							style={{
								background: `${toggled ? "var(--primaryColor)" : "grey"}`,
							}}
						/>
					</InputWrapper>
				</SubscriptionCard>
			</FlexBox>
		</Container>
	);
};

export default SendGridConfig;
