import React from "react";
import styled from "styled-components";

const EmptyStateWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const EmptyStateImage = styled.img`
	width: 50%;
	height: 50%;
	cursor: pointer;	
`;

const EmptyStateMessage = styled.p`
	width: 50%;
	height: 60px;
	padding: 20px;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	color: #001845;
`;

const AddFCButton = styled.button`
	height: 45px;
	border-radius: 6px;
	background-color: var(--primaryColor);
	cursor: pointer;
	color: white;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	color: #ffffff;
	padding: 0.5em 1em;
	border: none;
	margin-top: 0.5em;
`;

const PageEmptyState = (props) => {
	const { srcImage, message, buttonText, buttonOnClick, onClick } = props;
	return (
		<EmptyStateWrapper>
			<EmptyStateImage src={srcImage} onClick={onClick}></EmptyStateImage>
			<EmptyStateMessage>{message}</EmptyStateMessage>
			<div style={{ marginTop: "0.8em" }}>
				{buttonText && buttonText.length > 0 && buttonOnClick ? (
					<AddFCButton onClick={buttonOnClick}>{buttonText}</AddFCButton>
				) : (
					""
				)}
			</div>
		</EmptyStateWrapper>
	);
};

export default PageEmptyState;
