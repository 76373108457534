import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Drawer, Steps, Button, InputNumber, Radio } from "antd";
import { getContentDisplayIcon } from "../../../../../helpers/utils";
import html2canvas from "html2canvas";

const DrawerWrapper = styled.div`
	width: 100%;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	position: relative;
	padding: 5px;
	background-color: white;
`;

const DrawerHeaderWrapper = styled.div`
	height: 90px;
	text-transform: uppercase;
	border-bottom: solid 1px #dee5f4;
	text-align: center;
	width: 100%;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	justify-content: center;
	background-color: white;
`;
const FormTitle = styled.span`
	padding: 15px 10px 10px 10px;
	height: 100%;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`;
const FormIcon = styled.img`
	width: 100px;
	height: 100%;
	padding: 12px;
	display: ${(props) => (props.hasIcon ? "inline-block" : "none")};
	object-fit: contain;
`;

const QueriesContainer = styled.div`
	padding: 5px;
	background-color: white;
`;
const QueryWrapperBox = styled.div`
	height: 100px;
	margin: 5px;
	background-color: white;
`;

const FormHeaderContainer = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 2px;
	background-color: white;
`;

const TextQueryContainer = styled.div`
	font-size: 14px;
	font-weight: 500;
	padding: 0.6em;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
`;

const QueryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 90px;
	width: 100%;
`;

const IconContainer = styled.img`
	height: 100%;
	max-width: 80px;
	padding: 5px;
	object-fit: contain;
`;

const QueryNumber = styled.div`
	height: 100%;
	width: 30px;
	padding: 0.5em;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const IconResponseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const IconLabelWrapper = styled.div`
	font-size: 13px;
	font-weight: 500;
	text-transform: capitalize;
	padding: 0.6em;
	display: flex;
	height: 100%;
`;

const ButtonContainer = styled.div`
	padding: 0.5em;
	display: flex;
	justify-content: flex-end;
	margin-top: 1em;
`;

const FormMessageContainer = styled.div`
	padding: 1em;
	font-weight: 500;
	text-align: center;
	background-color: white;
`;

const TokenValueContainer = styled.div`
	width: 100%;
	min-height: 80px;
	padding: 1em;
	display: block;
`;

const ConfirmFormCreation = (props) => {
	const [currentStep, setCurrentStep] = useState(0);
	const [radioValue, setRadioValue] = useState(0);
	const [formDataURI, setFormDataURI] = useState("");
	const {
		previewForm,
		setPreviewForm,
		formHeader,
		handleTokenValue,
		handleCreateForm,
	} = props;

	const { builderContents } = useSelector((state) => state.forms);

	let formContents = {};
	formContents["formName"] = formHeader["formName"];
	formContents["feedbackForm"] = builderContents;

	const StepOne = () => {
		return (
			<React.Fragment>
				<DrawerWrapper id="formCard">
					<DrawerHeaderWrapper>
						<FormHeaderContainer>
							{formHeader &&
							(formHeader["formIcon"] || formHeader["formName"]) ? (
								<React.Fragment>
									<FormIcon
										src={formHeader["formIcon"]}
										alt="avatar"
										hasIcon={formHeader["formIcon"]}
									/>
									<FormTitle>
										{(formHeader && formHeader["formName"]) || ""}
									</FormTitle>
								</React.Fragment>
							) : (
								""
							)}
							{formContents && formContents["formHeader"] ? (
								<React.Fragment>
									<FormIcon
										src={formContents["formHeader"]["formIcon"]}
										alt="avatar"
										hasIcon={formContents["formHeader"]["formIcon"]}
									/>
									<FormTitle>
										{formContents && formContents["formName"]}
									</FormTitle>
								</React.Fragment>
							) : (
								""
							)}
						</FormHeaderContainer>
					</DrawerHeaderWrapper>
					<FormMessageContainer>
						{formHeader && formHeader["message"]}
					</FormMessageContainer>
					<QueriesContainer>
						{formContents &&
							formContents["feedbackForm"] &&
							formContents["feedbackForm"].map((item, index) => {
								return (
									<QueryWrapperBox>
										{item.queryType === "text" ? (
											<QueryWrapper>
												<QueryNumber>
													<span>{`${index + 1}. `}</span>
												</QueryNumber>
												<TextQueryContainer>
													{`${item.queryValue}`}
													<span style={{ height: "25px", marginTop: "0.8em" }}>
														<img
															alt={""}
															src={getContentDisplayIcon(item.responseType)}
															height={"100%"}
														/>
													</span>
												</TextQueryContainer>
											</QueryWrapper>
										) : (
											<QueryWrapper>
												<QueryNumber>
													<span>{`${index + 1}. `}</span>
												</QueryNumber>

												<IconResponseWrapper>
													<IconLabelWrapper>
														<IconContainer
															src={`${item.queryValue}?${new Date().getTime()}`}
															crossOrigin="anonymous"
														></IconContainer>
														<div
															style={{
																display: "flex",
																flexDirection: "column",

																marginLeft: "0.5em",
															}}
														>
															{`${item.iconLabel}`}
															<span
																style={{ height: "25px", marginTop: "0.8em" }}
															>
																<img
																	alt={""}
																	src={getContentDisplayIcon(item.responseType)}
																	height={"100%"}
																/>
															</span>
														</div>
													</IconLabelWrapper>
												</IconResponseWrapper>
											</QueryWrapper>
										)}
									</QueryWrapperBox>
								);
							})}
					</QueriesContainer>
				</DrawerWrapper>
				<ButtonContainer>
					<Button onClick={handleNextStep}>{"Next >>"}</Button>
				</ButtonContainer>
			</React.Fragment>
		);
	};

	const StepTwo = () => {
		return (
			<div className="step-two-container">
				<TokenValueContainer style={{ display: "block" }}>
					<span>Do you want to distribute R5 tokens ?</span>
					<div class="step-two-radio">
						<Radio.Group
							value={radioValue}
							onChange={(e) => setRadioValue(e.target.value)}
						>
							<Radio value={1}>Yes</Radio>
							<Radio value={0}>No</Radio>
						</Radio.Group>
					</div>
				</TokenValueContainer>
				<TokenValueContainer
					style={{ display: `${radioValue ? "block" : "none"}` }}
				>
					<p>
						{"Please assign the number of tokens to be rewarded for this forms"}
					</p>
					<InputNumber
						addonAfter={"R5"}
						min={radioValue ? 1 : 0}
						max={10000000}
						keyboard={true}
						defaultValue={1}
						onChange={handleTokenValue}
						disabled={!radioValue}
					/>
				</TokenValueContainer>
				<div>
					<h4>Are you sure you want to create your form ?</h4>
				</div>
				<div class="step-two-button-container">
					<Button
						style={{ width: "33%" }}
						width={"33%"}
						onClick={() => setCurrentStep(0)}
					>
						{"<< Previous"}
					</Button>
					<Button
						style={{ width: "33%" }}
						onClick={() => setPreviewForm(false)}
					>
						{"Cancel"}
					</Button>
					<Button
						style={{ width: "33%" }}
						onClick={() => handleCreateForm(formDataURI)}
						type="primary"
					>
						{"Create Form"}
					</Button>
				</div>
			</div>
		);
	};

	const generateTemplate = () => {
		const modalSelector = document.getElementById("formCard");
		html2canvas(modalSelector, {
			allowTaint: false,
			useCORS: true,
		}).then(async (canvas) => {
			const formCard = canvas.toDataURL("image/png");
			setFormDataURI(formCard);
		});
	};

	const handleNextStep = () => {
		generateTemplate();
		setCurrentStep(1);
	};

	const steps = [
		{
			title: "Preview Your Form",
			description: "",
			content: StepOne,
		},
		{
			title: "Set Form Configurations",
			description: "",
			content: StepTwo,
		},
	];

	return (
		<Drawer
			title={"Two more steps until you create a new form"}
			placement="right"
			closable={true}
			width={"40%"}
			visible={previewForm}
			onClose={() => setPreviewForm(false)}
		>
			<Steps
				current={currentStep}
				type="navigation"
				size="small"
				className="site-navigation-steps"
			>
				{steps.map((item) => {
					return (
						<Steps.Step
							key={item.title}
							title={item.title}
							description={item.description}
						/>
					);
				})}
			</Steps>
			<div style={{ height: "94%", width: "100%" }}>
				{steps[currentStep].content()}
			</div>
		</Drawer>
	);
};

export default ConfirmFormCreation;
