import React from "react";
import styled from "styled-components";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

const AreaChartWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	border-radius: 10px;
	border: 0.1 solid;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	margin: 0.5em;
	background: white;
`;

const TitleBar = styled.span`
	display: block;
	padding: 1em;
	font-weight: 500;
`;

const ChartContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	padding: 1em;
`;

const COLORS = ["#0088FE", "#fdc300", "#fd847c", "#8884d8"];

const Areachart = (props) => {
	const { title, data, width } = props;
	return (
		<AreaChartWrapper width={width}>
			<TitleBar>{title}</TitleBar>
			<ChartContainer>
				<ResponsiveContainer>
					<AreaChart data={data}>
						<XAxis
							dataKey="month"
							style={{
								fontWeight: 500,
							}}
							axisLine={false}
						/>
						<YAxis axisLine={false} />
						<Tooltip />
						<Area
							type="monotone"
							dataKey="requests"
							stroke="#8884d8"
							fill={COLORS[1]}
						/>
						<Area
							type="monotone"
							dataKey="responses"
							stroke="#82ca9d"
							fill={COLORS[2]}
						/>
					</AreaChart>
				</ResponsiveContainer>
			</ChartContainer>
		</AreaChartWrapper>
	);
};

export default Areachart;
