import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchForms } from "../../../store/slices/formSlice";
import { DatePicker, Select, Button, message, Table, Tag } from "antd";
import { setFormReviews } from "../../../store/slices/metricSlice";
import styled from "styled-components";
import moment from "moment";
import PageEmptyState from "../../common/PageEmptyState";
import { NoReviewData } from "../../../assets/index";
import { pageLoaded } from "../../../store/slices/uiSlice";
import { renderUserResponse } from "../../../helpers/utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Container = styled.div`
	height: calc(100vh - 100px);
`;
const FilterBar = styled.div`
	height: 60px;
	width: 100%;
	padding: 1em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.8em;
`;
const FormFilter = styled.div`
	text-align: left;
`;
const DatePickerWrapper = styled.div``;

const TableHeadWrapper = styled.div`
	height: 40px;
	padding: 1em;
	display: flex;
	justify-content: ${(props) => (props.flex ? props.flex : "center")};
	align-items: center;
	font-size: 1.4vmin;
	font-weight: 500;
`;

const HeaderItemText = styled.span`
	padding: 10px;
	text-transform: capitalize;
	font-size: 1em;
	font-weight: 500;
	overflow-wrap: break-word;
`;

const TableContainer = styled.div`
	padding: 20px;
`;

const CellContainer = styled.div`
	display: flex;
	justify-content: ${(props) => (props.flex ? props.flex : "center")};
	align-items: center;
	font-size: 1.3vmin;
	font-weight: 400;
	height: 30px;
`;

const EmptyStateContainer = styled.div`
	background: #f5f6fa;
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 1em;
	flex: 1;
`;

let columns = [];

const FormReviews = (props) => {
	let dataSource = [];
	const { socket } = props;
	const dispatch = new useDispatch();
	const [dateRange, setDateRange] = useState([]);
	const [selectedForm, setSelectedForm] = useState("");
	const { selectedFormReviews } = useSelector((state) => state.analytics);

	const fetchFormList = useCallback(() => dispatch(fetchForms()), [dispatch]);
	const fetchFormReviews = useCallback(() => {
		socket.on("form-reviews", (data) => {
			dispatch(pageLoaded({ loading: false }));
			dispatch(setFormReviews(data));
		});
	}, [dispatch, socket]);

	useEffect(() => {
		fetchFormList();
		fetchFormReviews();
	}, [fetchFormList, fetchFormReviews]);

	const { allForms } = useSelector((state) => state.forms);

	const renderSentimentTag = (tag) => {
		switch (tag) {
			case "POSITIVE":
				return <Tag color={"green"}>{tag}</Tag>;
			case "NEGATIVE":
				return <Tag color={"red"}>{tag}</Tag>;
			case "MIXED":
				return <Tag color={"orange"}>{tag}</Tag>;
			case "NEUTRAL":
				return <Tag color={"blue"}>{tag}</Tag>;
			default:
				return <Tag color={"pink"}>{"NO SENTIMENT"}</Tag>;
		}
	};

	if (selectedForm && selectedFormReviews && selectedFormReviews.length > 0) {
		const form = allForms.find((item) => item._id === selectedForm);
		columns = form["feedbackForm"].map((item, index) => {
			return {
				title: () => {
					if (item.queryType === "text") {
						return (
							<TableHeadWrapper>
								<HeaderItemText>{item.queryValue}</HeaderItemText>
							</TableHeadWrapper>
						);
					} else {
						return (
							<TableHeadWrapper>
								<img
									alt={"Icon Broken"}
									src={item.queryValue}
									width={40}
									height={40}
								></img>
								<HeaderItemText>{item.iconLabel}</HeaderItemText>
							</TableHeadWrapper>
						);
					}
				},
				dataIndex: item._id,
				width: () => `${100 / form["feedbackForm"].length}%`,
				render: (rowValue, record) => {
					if (rowValue && rowValue.responseType === "text") {
						return (
							<CellContainer flex={"row-reverse"}>
								<span
									style={{ height: "100%", marginRight: "1em", width: "60%" }}
								>
									{rowValue.responseValue}
								</span>
								<span style={{ height: "100%" }}>
									{renderSentimentTag(rowValue && rowValue.sentiment)}
								</span>
							</CellContainer>
						);
					} else if (rowValue && rowValue.responseType === "rate") {
						return (
							<CellContainer>
								{
									<img
										alt={"Icon Broken"}
										src={renderUserResponse("rate", rowValue.responseValue - 1)}
										height={"100%"}
										width={"100%"}
									></img>
								}
							</CellContainer>
						);
					} else if (rowValue && rowValue.responseType === "emoji") {
						return (
							<CellContainer>
								{
									<img
										alt={"Icon Broken"}
										src={renderUserResponse(
											"emoji",
											rowValue.responseValue - 1
										)}
										height={"100%"}
										width={"100%"}
									></img>
								}
							</CellContainer>
						);
					} else if (rowValue && rowValue.responseType === "like") {
						return (
							<CellContainer>
								{
									<img
										alt={"Icon Broken"}
										src={renderUserResponse("like", rowValue.responseValue)}
										height={"30px"}
										width={"100%"}
									></img>
								}
							</CellContainer>
						);
					} else if (rowValue && rowValue.responseType === "slider") {
						return (
							<CellContainer>
								{
									<img
										alt={"Icon Broken"}
										src={renderUserResponse(
											"slider",
											rowValue.responseValue - 1
										)}
										height={"30px"}
										width={"100%"}
									></img>
								}
							</CellContainer>
						);
					}
				},
			};
		});

		const recepientColumn = {
			title: () => (
				<TableHeadWrapper>
					<HeaderItemText>{"CUSTOMER EMAIL"}</HeaderItemText>
				</TableHeadWrapper>
			),
			dataIndex: "customerEmail",
			render: (rowValue) => {
				return (
					<CellContainer>
						<Tag color={`var(--primaryColor)`}>{rowValue}</Tag>
					</CellContainer>
				);
			},
		};
		columns.splice(0, 0, recepientColumn);

		selectedFormReviews &&
			selectedFormReviews.map((review) => {
				let rowData = { customerEmail: review.customerEmail };
				for (let i = 0; i < review["feedbackForm"].length; i++) {
					if (
						review["feedbackForm"][i] &&
						review["feedbackForm"][i].responseType === "text"
					) {
						rowData = {
							...rowData,
							[review["feedbackForm"][i]._id]: {
								responseValue: review["feedbackForm"][i].responseValue,
								responseType: review["feedbackForm"][i].responseType,
								sentiment:
									(review["feedbackForm"][i].sentimentAnalysis &&
										review["feedbackForm"][i].sentimentAnalysis.Sentiment) ||
									"NEUTRAL",
							},
						};
					} else {
						rowData = {
							...rowData,
							[review["feedbackForm"][i]._id]: {
								responseValue: review["feedbackForm"][i].responseValue,
								responseType: review["feedbackForm"][i].responseType,
							},
						};
					}
				}
				dataSource.push(rowData);
				return rowData;
			});
	}

	const handleFormSelection = (formId) => {
		// Set the page loading state to true
		setSelectedForm(formId);
		dispatch(pageLoaded({ loading: true }));
		if (dateRange && dateRange.length) {
			socket.emit("form-reviews", { formId, dateRange });
		} else {
			const [startDate, endDate] = [
				moment("01-01-1970").startOf("day"),
				moment().endOf("day"),
			];
			const dateRange = { startDate, endDate };
			socket.emit("form-reviews", { formId, dateRange });
		}
	};

	const handleDateChange = () => {
		// Set the page loading state to true
		// setLoading(true);

		if (!selectedForm) {
			message.error("Please select a form to apply the date range filter.");
			// setLoading(false);
			return null;
		}

		if (dateRange && dateRange.length) {
			let [startDate, endDate] = dateRange;
			startDate = startDate.startOf("day");
			endDate = endDate.endOf("day");
			let updatedDateRange = { startDate, endDate };
			socket.emit("form-reviews", {
				formId: selectedForm,
				dateRange: updatedDateRange,
			});
		} else {
			const [startDate, endDate] = [
				moment("01-01-1970").startOf("day"),
				moment().endOf("day"),
			];
			let updatedDateRange = { startDate, endDate };
			socket.emit("form-reviews", {
				formId: selectedForm,
				dateRange: updatedDateRange,
			});
		}
	};

	const downloadReviewData = () => {
		if (selectedFormReviews && selectedFormReviews.length) {
			// Generate CSV File Headers
			let headers = [["reviewerId"]];
			for (
				let secIndex = 0;
				secIndex < selectedFormReviews[0]["feedbackForm"].length;
				secIndex++
			) {
				if (
					selectedFormReviews[0]["feedbackForm"][secIndex].hasOwnProperty(
						"iconLabel"
					)
				) {
					headers[0].push(
						selectedFormReviews[0]["feedbackForm"][secIndex]["iconLabel"]
					);
				} else {
					headers[0].push(
						selectedFormReviews[0]["feedbackForm"][secIndex]["queryValue"]
					);
				}
			}

			// Generate CSV Data Rows
			let dataRows = [];
			for (let index = 0; index < selectedFormReviews.length; index++) {
				const review = selectedFormReviews[index];
				let newRow = [];
				for (
					let secIndex = 0;
					secIndex < review["feedbackForm"].length;
					secIndex++
				) {
					if (secIndex === 0) {
						newRow.push(review["customerEmail"]);
					}
					newRow.push(review["feedbackForm"][secIndex]["responseValue"]);
				}
				dataRows.push(newRow);
			}
			const downloadData = [...headers, ...dataRows];
			let csvContent =
				"data:text/csv;charset=utf-8," +
				downloadData.map((e) => e.join(",")).join("\n");
			let encodedUri = encodeURI(csvContent);
			let link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", `${new Date().toISOString()}.csv`);
			document.body.appendChild(link); // Required for FF
			link.click();
		} else {
			message.error("No Data to download!");
		}
	};

	return (
		<Container>
			<FilterBar>
				<FormFilter>
					<span style={{ padding: "1em" }}> Form </span>
					<span>
						<Select
							style={{ minWidth: "250px" }}
							placeholder="Select a form to view insights"
							onChange={(item) => handleFormSelection(item)}
						>
							{allForms &&
								allForms.map((item, index) => {
									return (
										<Option key={index} value={item._id}>
											{item.formName}
										</Option>
									);
								})}
						</Select>
					</span>
				</FormFilter>

				<DatePickerWrapper>
					<span style={{ padding: "1em" }}>Select Date Range</span>
					<RangePicker
						ranges={{
							Today: [moment(), moment()],
							"This Month": [
								moment().startOf("month"),
								moment().endOf("month"),
							],
						}}
						showTime={false}
						onChange={(values) => values && setDateRange([...values])}
					/>
					<Button
						type={"dashed"}
						onClick={() => handleDateChange()}
						disabled={dateRange.length < 1}
					>
						Apply
					</Button>
					<Button
						disabled={
							selectedFormReviews && selectedFormReviews.length ? false : true
						}
						onClick={downloadReviewData}
					>
						Download Results
					</Button>
				</DatePickerWrapper>
			</FilterBar>
			<EmptyStateContainer>
				{dataSource && dataSource.length === 0 ? (
					<PageEmptyState
						srcImage={NoReviewData}
						message={
							"No Data Found! Only Token approved responses are shown here"
						}
					/>
				) : (
					<TableContainer>
						<Table columns={columns} dataSource={dataSource}></Table>
					</TableContainer>
				)}
			</EmptyStateContainer>
		</Container>
	);
};

export default FormReviews;
