import React, { useEffect, useCallback } from "react";
import LeftNav from "./LeftNav";
import styled from "styled-components";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReviewfyTagLine, NoDataFound } from "../../assets/index";
import { Popover, Avatar, List } from "antd";
import { BellOutlined, HomeOutlined, HomeFilled } from "@ant-design/icons";
import PageEmptyState from "./PageEmptyState";
import { isLoggedIn } from "../../store/slices/authSlice";
import { getOrgIcon } from "../../store/slices/uiSlice";
import { setSelectedWorkspaceId } from "../../store/slices/environmentSlice";

const Header = styled.div`
	width: 100%;
	height: 60px;
	border-bottom: 1px solid rgba(9, 30, 66, 0.12);
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	text-transform: capitalize;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const AppChildrenContainer = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex: 1;
`;

const ContainerWrapper = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const PageContainerWrapper = styled.div`
	overflow: hidden;
`;

const LogoContainer = styled.img`
	height: 100%;
	padding: 0.8rem;
	display: inline-block;
`;

const IconContainer = styled.div`
	height: 100%;
	display: flex;
	padding: 0.5em;
	align-items: center;
`;

const NotificationBar = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: ${(props) =>
		props.justifyContent ? props.justifyContent : "flex-end"};
	align-items: center;
	flex: 1;
`;

const OrgInformation = styled.div`
	height: 100%;
	padding: 0px 0.5em;
	display: flex;
	align-items: center;
`;

const OrgName = styled.span`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: capitalize;
	align-items: center;
	padding: 0.5em;
	font-size: 0.9em;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const IconStyle = {
	padding: "2px 1em",
	fontSize: "1.5rem",
	borderRight: "1px solid #091e421f",
	cursor: "pointer",
};

const NoNotification = styled.div`
	min-width: 200px;
	min-height: 400px;
	height: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em;
`;

const ProfileSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0px 0.5em 0px 0.5em;
	align-items: center;
	cursor: pointer;
	border-left: 1px solid #091e421f;
`;

const PopItemContainer = styled.div`
	min-width: 150px;
	text-align: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	padding: 1em;
	background-color: white;
	// :hover {
	// 	background-color: #ebeef0;
	// }
`;

const UserNameHolder = styled.div`
	background: #f5f7f9;
	color: #183247;
	padding: 1em;
	line-height: 2;
	font-weight: 500;
`;

const renderPopover = (iconItem) => {
	return (
		<Popover
			key={iconItem.name}
			placement="bottom"
			title={null}
			content={
				<NoNotification>
					<PageEmptyState srcImage={NoDataFound} message={iconItem.message} />
				</NoNotification>
			}
			trigger="click"
			style={{ borderRadius: "10px" }}
		>
			{iconItem.icon()}
		</Popover>
	);
};

const Main = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { workspaces, selectedWorkspaceId } = useSelector(
		(state) => state.environment
	);

	const { orgLogo, orgName } = useSelector((state) => state.ui);

	const currentWorkspace = workspaces.find(
		(item) => item._id === selectedWorkspaceId
	);

	const renderWorkspaces = (iconItem, workspaces) => {
		const filteredWorkspaces = workspaces.filter(
			(item) => item._id !== selectedWorkspaceId
		);

		return (
			<Popover
				key={iconItem.name}
				placement="bottom"
				title={null}
				content={
					<div style={{ minWidth: "250px", padding: "0.8em" }}>
						<div
							onClick={() => {
								history.push("/workspaces");
								dispatch(setSelectedWorkspaceId({ workspaceId: null }));
							}}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "50px",
								backgroundColor: "#ebeff3",
								fontWeight: 600,
								cursor: "pointer",
								marginBottom: "1em",
							}}
						>
							<HomeFilled
								style={{
									cursor: "pointer",
									padding: "0.5em",
								}}
							/>
							Go to All Workspaces
						</div>
						<div>
							<span
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									fontWeight: 600,
								}}
							>
								{"Current Workspace"}
							</span>
							<List
								itemLayout="horizontal"
								dataSource={[{ ...currentWorkspace }]}
								renderItem={(item) => (
									<List.Item>
										<List.Item.Meta
											avatar={
												<Avatar src={currentWorkspace.workspaceGravatar} />
											}
											title={currentWorkspace.workspaceName}
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										/>
									</List.Item>
								)}
							></List>
						</div>

						<div>
							<span
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									fontWeight: 600,
								}}
							>
								{"Other Workspaces"}
							</span>
							<List
								itemLayout="horizontal"
								dataSource={filteredWorkspaces}
								renderItem={(item) => (
									<List.Item>
										<List.Item.Meta
											avatar={<Avatar src={item.workspaceGravatar} />}
											title={<a href={"#"}>{item.workspaceName}</a>}
											style={{
												display: "flex",
												alignItems: "center",
											}}
											onClick={() => {
												history.push(`/${item._id}/home`);
												dispatch(
													setSelectedWorkspaceId({ workspaceId: item._id })
												);
											}}
										/>
									</List.Item>
								)}
							></List>
						</div>
					</div>
				}
				trigger="click"
				style={{ borderRadius: "10px" }}
			>
				{iconItem.icon()}
			</Popover>
		);
	};
	const IconItems = [
		{
			name: "workspaces",
			message: "Switch Workspace",
			icon: () => (
				<HomeOutlined
					style={{
						...IconStyle,
						display: `${selectedWorkspaceId ? "block" : "none"}`,
					}}
				/>
			),
			renderPopComponent: (iconItem, workspaces) =>
				renderWorkspaces(iconItem, workspaces),
		},
		{
			name: "notifications",
			message: "No New Notifications!",
			icon: () => <BellOutlined style={IconStyle} />,
			renderPopComponent: (iconItem) => renderPopover(iconItem),
		},
	];

	const handleLogout = () => {
		window.localStorage.clear();
		props.history.push("/signin");
		dispatch(isLoggedIn(false));
	};

	const displayUserInfo = (fieldType) => {
		try {
			const { email, username, ssoAuth } = JSON.parse(
				localStorage.getItem("authInfo")
			);
			if (fieldType === "email") {
				return email;
			} else if (fieldType === "username") {
				return username;
			} else if (fieldType === "ssoAuth") {
				return ssoAuth;
			}
		} catch (err) {
			window.localStorage.clear();
			props.history.push("/signin");
			dispatch(isLoggedIn(false));
		}
	};

	const renderUserName = () => {
		try {
			const { email } = JSON.parse(localStorage.getItem("authInfo"));
			return (
				<Avatar
					size={40}
					style={{
						backgroundColor: "var(--primaryColor)",
						color: "white",
						fontWeight: 600,
					}}
				>
					{email[0].toUpperCase()}
				</Avatar>
			);
		} catch {
			window.localStorage.clear();
			props.history.push("/signin");
			dispatch(isLoggedIn(false));
		}
	};

	const fetchOrgLogo = useCallback(() => dispatch(getOrgIcon()), [dispatch]);
	useEffect(() => {
		fetchOrgLogo();
	}, [fetchOrgLogo]);

	return (
		<PageContainerWrapper>
			<Header>
				<NotificationBar justifyContent={"flex-start"}>
					<LogoContainer src={ReviewfyTagLine} />
				</NotificationBar>
				<NotificationBar>
					{history.location.pathname !== "/workspaces" &&
					history.location.pathname !== "/teams" &&
					history.location.pathname !== "/teams/users" &&
					history.location.pathname !== "/teams/groups" ? (
						<div
							style={{
								padding: "12px 0px",
							}}
						>
							<OrgInformation
								style={{
									borderRight: "1px solid #091e421f",
								}}
							>
								{currentWorkspace && currentWorkspace.workspaceGravatar ? (
									<img
										width={"25px"}
										src={currentWorkspace.workspaceGravatar}
										height={"100%"}
										style={{ borderRadius: "50%" }}
										alt={"brokenIcon"}
									/>
								) : (
									""
								)}
								<OrgName>
									{currentWorkspace && currentWorkspace.workspaceName}
								</OrgName>
							</OrgInformation>
						</div>
					) : (
						""
					)}

					<IconContainer>
						{IconItems.map((iconItem, index) => {
							if (
								(history.location.pathname === "/workspaces" && index === 0) ||
								(history.location.pathname === "/teams" && index === 0) ||
								(history.location.pathname === "/teams/users" && index === 0) ||
								(history.location.pathname === "/teams/groups" && index === 0)
							) {
								return null;
							}

							if (iconItem.name === "workspaces") {
								return iconItem.renderPopComponent(iconItem, workspaces);
							}
							return iconItem.renderPopComponent(iconItem);
						})}
					</IconContainer>
					<OrgInformation>
						<OrgName>{orgName}</OrgName>
						{orgLogo ? (
							<img src={orgLogo} height={"100%"} alt={"brokenIcon"} />
						) : (
							""
						)}
					</OrgInformation>
					<ProfileSection>
						<Popover
							placement="topLeft"
							content={
								<>
									<UserNameHolder>
										{displayUserInfo("username")}
										<span
											style={{
												display: "block",
												color: "#6f7c87",
												fontSize: "12px",
											}}
										>
											{displayUserInfo("email")}
										</span>
									</UserNameHolder>
									<PopItemContainer
										style={{
											display: `${
												!displayUserInfo("ssoAuth") && selectedWorkspaceId
													? "block"
													: "none"
											}`,
										}}
										onClick={() => history.push("/profile")}
									>
										Profile Settings
									</PopItemContainer>
									<PopItemContainer onClick={() => handleLogout()}>
										Logout
									</PopItemContainer>
								</>
							}
							trigger="click"
							style={{ borderRadius: "0px !important" }}
						>
							{renderUserName()}
						</Popover>
					</ProfileSection>
				</NotificationBar>
			</Header>
			<ContainerWrapper>
				<LeftNav></LeftNav>
				<AppChildrenContainer>{props.children}</AppChildrenContainer>
			</ContainerWrapper>
		</PageContainerWrapper>
	);
};

export default withRouter(Main);
