import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Tooltip } from "antd";
import {
	DashboardIcon,
	SendFormIcon,
	SettingsIcon,
	FormContentIcon,
	ResponseTabIcon,
} from "../../assets/index";
import { setRootNavTab } from "../../store/slices/uiSlice";

import { useDispatch, useSelector } from "react-redux";

const LeftNavContainer = styled.div`
	width: 65px;
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--primaryColor);
`;

const IconsContainer = styled.div`
	height: 50%;
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const IconWrapper = styled.div`
	margin: 1em 0px;
	padding: 1em;
	border-radius: 8px;
	background-color: ${(props) =>
		props.selected ? "rgba(255, 255, 255, 0.3)" : "transparent"};
	&:hover {
		background-color: rgba(255, 255, 255, 0.3);
	}
`;

const LeftNavBar = (props) => {
	const dispatch = useDispatch();

	const { hideNavBar } = useSelector((state) => state.ui);

	const navOptions = [
		{
			tab: "Dashboard",
			path: "/home",
			icon: DashboardIcon,
		},
		{
			tab: "Form Management",
			path: "/form-management",
			icon: FormContentIcon,
		},
		{
			tab: "Form Dispatcher",
			path: "/form-dispatcher",
			icon: SendFormIcon,
		},
		{
			tab: "Review Responses",
			path: "/review-responses",
			icon: ResponseTabIcon,
		},
		{
			tab: "Settings",
			path: "/settings",
			icon: SettingsIcon,
		},
	];

	const { selectedRootNavTab } = useSelector((state) => state.ui);
	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	return (
		<LeftNavContainer style={{ display: `${hideNavBar ? "none" : "block"}` }}>
			<IconsContainer>
				{navOptions &&
					navOptions.map((navItem, index) => {
						return (
							<Tooltip key={navItem.tab} placement="right" title={navItem.tab}>
								<Link to={`/${selectedWorkspaceId}${navItem.path}`} key={index}>
									<IconWrapper
										selected={navItem.tab === selectedRootNavTab}
										onClick={() => {
											dispatch(setRootNavTab(navItem.tab));
										}}
									>
										<img alt={"broken-icon"} src={navItem.icon} />
									</IconWrapper>
								</Link>
							</Tooltip>
						);
					})}
			</IconsContainer>
		</LeftNavContainer>
	);
};

export default withRouter(LeftNavBar);
