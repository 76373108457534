import React from "react";
import styled from "styled-components";
import socketIOClient from "socket.io-client";
import OverviewAnalytics from "./analytics/OverviewAnalytics";
import FormAnalytics from "./analytics/FormAnalytics";
import FormReviews from "./analytics/FormReviews";
import { Tabs } from "antd";
import { getSessionToken } from "../../helpers/utils";

let ENDPOINT = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	ENDPOINT = "http://localhost:3001";
}

const socket = socketIOClient(ENDPOINT, { auth: { token: getSessionToken() } });

const { TabPane } = Tabs;

const Container = styled.div`
	height: calc(100vh - 60px);
	width: calc(100vw - 65px);
`;

const TabTitle = styled.span`
	font-weight: 600;
	text-transform: uppercase;
`;

const Dashboard = () => {
	return (
		<Container>
			<Tabs
				defaultActiveKey="1"
				size={{ size: "large" }}
				centered
				tabBarStyle={{ marginBottom: "0px" }}
			>
				<TabPane tab={<TabTitle>{"overview"}</TabTitle>} key="1">
					<OverviewAnalytics socket={socket}></OverviewAnalytics>
				</TabPane>
				<TabPane tab={<TabTitle>{"Form Analytics"}</TabTitle>} key="2">
					<FormAnalytics socket={socket}></FormAnalytics>
				</TabPane>
				<TabPane tab={<TabTitle>{"Form Reviews"}</TabTitle>} key="3">
					<FormReviews socket={socket}></FormReviews>
				</TabPane>
			</Tabs>
		</Container>
	);
};

export default Dashboard;
