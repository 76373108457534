import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
	Breadcrumb,
	Table,
	Tag,
	Space,
	Form,
	Input,
	Select,
	Button,
	Avatar,
	Alert,
	Drawer,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOrgUsers,
	deleteUser,
	saveUser,
} from "../../../store/slices/settingSlice";
import { formatDate } from "../../../helpers/utils";
import { DeleteIcon } from "../../../assets/index";

const { Search } = Input;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #ebeff3;
`;

const TopSecondaryNav = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
	background-color: #f5f7f9;
	border-bottom: 1px #cfd7df solid;
`;

const TransparentBar = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	padding: 1em;
`;

const TableContainer = styled.div`
	display: flex;
	flex: 1;
	padding: 0.8em;
	overflow-y: auto;
`;

const onSearch = (value) => console.log(value);

const ManageUsers = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const [errorAlert, seterrorAlert] = useState({
		isVisible: false,
		message: "",
	});

	const { users } = useSelector((state) => state.settings);

	const handleUserDelete = ({ _id }) => {
		dispatch(deleteUser({ userId: _id }));
	};

	const getUserEmail = () => {
		try {
			const { email } = JSON.parse(localStorage.getItem("authInfo"));
			return email;
		} catch (err) {
			return null;
		}
	};

	const getUsers = useCallback(() => dispatch(fetchOrgUsers()), [dispatch]);
	useEffect(() => {
		getUsers();
	}, [getUsers]);

	const onFinish = async (values) => {
		try {
			await dispatch(saveUser(values)).unwrap();
			setModalVisible(false);
		} catch (err) {
			seterrorAlert({ isVisible: true, message: err.message });
		}
	};
	const columns = [
		{
			title: "S.No",
			dataIndex: "name",
			key: "name",
			width: "80px",
			render: (item, record, index) => <>{index + 1}</>,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: "25%",
			render: (text) => (
				<>
					<Space>
						<Avatar>{text[0].toUpperCase()}</Avatar>
						<a href="/settings/people">{text}</a>
					</Space>
				</>
			),
		},
		{
			title: "Email Address",
			dataIndex: "email",
			key: "email",
			width: "25%",
		},
		{
			title: "Email Verified",
			dataIndex: "emailVerified",
			key: "emailVerified",
			render: (emailVerified) => (
				<>
					{emailVerified ? (
						<Tag color={"green"}>{"VERIFIED"}</Tag>
					) : (
						<Tag color={"red"}>{"NOT VERIFIED"}</Tag>
					)}
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (createdAt) => <p>{formatDate(createdAt)}</p>,
		},
		{
			title: "Role",
			key: "roles",
			dataIndex: "roles",
			render: (tags) => (
				<>
					{tags &&
						tags.map((tag) => {
							let color = tag.length > 5 ? "blue" : "blue";
							if (tag === "loser") {
								color = "volcano";
							}
							return (
								<Tag color={color} key={tag}>
									{tag.toUpperCase()}
								</Tag>
							);
						})}
				</>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Space
					size="middle"
					style={{
						display: `${record.email !== getUserEmail() ? "block" : "none"}`,
						cursor: "pointer",
					}}
				>
					<img
						src={DeleteIcon}
						onClick={() => handleUserDelete(record)}
						alt={"brokenImg"}
					/>
				</Space>
			),
		},
	];

	console.log(
		"errorAlert errorAlert errorAlert",
		`${errorAlert.isVisible ? "block" : "none"}`
	);

	return (
		<Container>
			<TopSecondaryNav>
				<Breadcrumb separator=">">
					<Breadcrumb.Item
						href=""
						onClick={() => {
							history.push("/teams");
						}}
					>
						Teams
					</Breadcrumb.Item>
					<Breadcrumb.Item>People</Breadcrumb.Item>
				</Breadcrumb>
			</TopSecondaryNav>
			<TransparentBar>
				<Button onClick={() => setModalVisible(true)}>Add User</Button>
				<Search
					placeholder="Search Users"
					onSearch={onSearch}
					style={{ width: 200, marginRight: "1em" }}
				/>
			</TransparentBar>
			<TableContainer>
				<Drawer
					width={"25%"}
					title={"Add user to your organisation!"}
					visible={modalVisible}
					closable={true}
					onClose={() => setModalVisible(false)}
					// onOk={() => onFinish()}
					footer={null}
					getContainer={true}
				>
					<Form layout="vertical" onFinish={onFinish}>
						<Form.Item
							name={"name"}
							label="Full Name"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={"email"}
							label="Email Address"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={"role"}
							label="Select Role"
							rules={[
								{
									required: true,
								},
							]}
						>
							<Select showArrow>
								<Select.Option value="admin">Administrator</Select.Option>
								<Select.Option value="maintainer">Maintainer</Select.Option>
							</Select>
						</Form.Item>
						<Alert
							closable={false}
							type="error"
							message={errorAlert.message}
							onClose={() => seterrorAlert({ isVisible: false, message: "" })}
							style={{ display: `${errorAlert.isVisible ? "block" : "none"}` }}
						></Alert>
						<Button
							htmlType="submit"
							type="dashed"
							block
							style={{ marginTop: "1em" }}
						>
							Add User
						</Button>
					</Form>
				</Drawer>
				<Table
					bordered
					columns={columns}
					dataSource={users}
					pagination={false}
					style={{ width: "100%" }}
				/>
			</TableContainer>
		</Container>
	);
};

export default ManageUsers;
