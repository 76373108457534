import React from "react";
import styled from "styled-components";

const FormNavItemWrapper = styled.div`
	width: 100%;
	height: 40px;
	margin-top: 1em;
	background-color: ${(props) => (props.selected ? "#8b8b8b26" : "#ffffff")};
	font-size: 1em;
	font-weight: 500;
	border-radius: 5px;
	text-transform: capitalize;
	cursor: pointer;
	color: ${(props) => (props.selected ? "black" : "black")};
	display: flex;
	padding: 0.5em;
	align-items: center;
`;

const NavItemIcon = styled.img`
	width: 25px;
	height: 25px;
	padding: 2px;
	margin-right: 1em;
`;

const NavItemName = styled.span``;

const FormNavItem = (props) => {
	const { itemName, srcIcon } = props;
	return (
		<FormNavItemWrapper
			onClick={props.handleSelectedTab}
			selected={props.isSelectedTab}
		>
			<NavItemIcon src={srcIcon}></NavItemIcon>
			<NavItemName>{itemName.replace("_", " ")}</NavItemName>
		</FormNavItemWrapper>
	);
};

export default FormNavItem;
