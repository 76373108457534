import React, { useState } from "react";
import { Input, message, Image, Select } from "antd";
import styled from "styled-components";
import openNotificationWithIcon from "../../../common/Notification";
import FormSelector from "../../common/FormSelector";
import { NoFormSelected } from "../../../../assets/index";
import { useDispatch } from "react-redux";
import { sendFormViaWhatsapp } from "../../../../store/slices/dispatcherSlice.js"

const { Option } = Select;

const defaultCode = {
	emoji: "🇺🇸",
	phoneCode: 1,
	short: "US",
};

const codes = [
	{
		emoji: "🇮🇳",
		phoneCode: 91,
		short: "IN",
	},
	{
		emoji: "🇺🇸",
		phoneCode: 1,
		short: "US",
	},
	{
		emoji: "🇨🇦",
		phoneCode: 1,
		short: "CA",
	},
	{
		emoji: "🇸🇬",
		phoneCode: 65,
		short: "SG",
	},
	{
		emoji: "🇦🇺",
		phoneCode: 61,
		short: "AU",
	},
];

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 20px;
	padding: 10px;
	height: calc(100vh - 125px);
`;

const MailerContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border-radius: 12px;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
`;

const HeadingText = styled.div`
	width: 100%;
	padding: 12px;
	font-weight: 600;
	text-align: left;
	position: relative;
	color: var(--primaryColor);
	border-radius: 12px 12px 0px 0px;
	text-transform: uppercase;
	background-color: rgb(29, 189, 255, 0.1);
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
`;

const ReceipientWrapper = styled.div`
	width: 100%;
	height: 80px;
	padding: 10px;
`;

const StyledLabel = styled.label`
	display: block;
	padding: 6px;
	text-align: left;
	font-weight: 500;
`;

const FormPreviewer = styled.div`
	width: 100%;
	padding: 8px;
	max-height: 60%;
`;

const PreviewImage = styled.div`
	margin: 0px auto;
	height: 80%;
	width: 50%;
	border-radius: 10px;
	cursor: pointer;
	border: solid 1px #c4d2eb;
	padding: 2px;
`;

const StyledButton = styled.div`
	width: 15%;
	height: 45px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 8px 12px 0 #1db6ff5c;
	border: solid 1px var(--primaryColor);
	background-color: ${(props) =>
		props.color ? props.color : `var(--primaryColor);`}
	color: white;
	cursor: pointer;
	margin-left: 20px;
	font-weight: 500;
`;

const defaultMsgOptions = {
	mobileNumber: "",
	message: "",
};

const SingleDispatcher = () => {

	const dispatch = useDispatch();
	const [msgOptions, setMsgOptions] = useState({ ...defaultMsgOptions });
	const [countryCode, setCountryCode] = useState({ ...defaultCode });
	const [selectedForm, setSelectedForm] = useState("");

	const { orgId } = JSON.parse(localStorage.getItem("authInfo"));

	const handleCountryCode = (shortId) => {
		let countryCode = codes.find((item) => item.short === shortId);
		setCountryCode({ ...countryCode });
	};

	const selectBefore = (
		<Select
			defaultValue={`${defaultCode.emoji} +${defaultCode.phoneCode}`}
			style={{ width: "120px" }}
			value={`${countryCode.emoji} +${countryCode.phoneCode}`}
			onChange={(shortCode) => handleCountryCode(shortCode)}
		>
			{codes.map((item) => {
				return (
					<Option
						value={item.short}
					>{`${item.emoji}    +${item.phoneCode}`}</Option>
				);
			})}
		</Select>
	);

	const handleSendForm = async () => {
		let dispatchConfig = {
			whatsapp: {
				id: `+${countryCode.phoneCode}${msgOptions["mobileNumber"]}`,
				subject: msgOptions["message"],
			},
			formId: selectedForm,
			channel: "whatsapp",
			orgId: orgId,
		};
		try {
			await dispatch(sendFormViaWhatsapp(dispatchConfig));
			openNotificationWithIcon(
				"success",
				"Whatsapp Message Sent",
				"The selected form has been sent to the user"
			);
			setMsgOptions({ ...defaultMsgOptions });
			setCountryCode({ ...defaultCode });
		} catch (error) {
			message.error(error.response.data);
		}
	};

	const renderFormPreview = () => {
		if (selectedForm) {
			return `https://kuber-dev.s3.amazonaws.com/${orgId}/resources/templates/${selectedForm}.png`;
		}
		return NoFormSelected;
	};

	const handleMailOptions = ({ name, value }) => {
		if (name === "mobilenumber") {
			setMsgOptions({ ...msgOptions, mobileNumber: value });
		} else if (name === "message") {
			setMsgOptions({ ...msgOptions, message: value });
		}
	};
	return (
		<Container>
			<MailerContainer>
				<HeadingText>Send Form to individuals</HeadingText>
				<ReceipientWrapper>
					<StyledLabel for="mobile-number">To</StyledLabel>
					<Input
						id="mobile-number"
						type="number"
						name={"mobilenumber"}
						addonBefore={selectBefore}
						maxLength={10}
						onWheel={(event) => event.currentTarget.blur()}
						onChange={(e) => handleMailOptions(e.target)}
						value={msgOptions.mobileNumber}
					/>
				</ReceipientWrapper>
				<ReceipientWrapper>
					<StyledLabel for="message">Message</StyledLabel>
					<Input
						id="message"
						type={"text"}
						name={"message"}
						value={msgOptions.message}
						onChange={(e) => handleMailOptions(e.target)}
					></Input>
				</ReceipientWrapper>
				<FormPreviewer>
					<StyledLabel>Form Preview</StyledLabel>
					<PreviewImage>
						{selectedForm ? (
							<Image
								src={renderFormPreview()}
								preview={true}
								height={"100%"}
								style={{
									borderRadius: "30px",
									objectFit: "contain",
									boxShadow: "0 3px 16px 0 rgba(149, 171, 211, 0.22)",
								}}
							/>
						) : (
							<div
								style={{
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>
								<img
									src={NoFormSelected}
									height={"60%"}
									alt={"brokenImg"}
								></img>
								<span
									style={{
										color: "#6c80a3",
										fontWeight: 500,
										padding: "10px",
									}}
								>
									{"Please select a form to be dispatched"}
								</span>
							</div>
						)}
					</PreviewImage>
				</FormPreviewer>
				<ButtonContainer>
					<StyledButton
						color={"white"}
						onClick={() => {
							setMsgOptions({ ...defaultMsgOptions });
							setSelectedForm("");
						}}
					>
						Discard
					</StyledButton>
					<StyledButton onClick={() => handleSendForm()}>Send</StyledButton>
				</ButtonContainer>
			</MailerContainer>
			<FormSelector
				title={"Select Form to Dispatch"}
				setSelectedForm={setSelectedForm}
				selectedForm={selectedForm}
			></FormSelector>
		</Container>
	);
};

export default SingleDispatcher;
