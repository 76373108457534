import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
	background: rgba(255, 255, 255, 0.7);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 5;
	display: ${(props) => (props.visible ? "flex" : "none")};
	justify-content: center;
	align-items: center;
`;

const IconWrapper = styled.div`
	width: 50%;
	height: 50%;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// background: yellow;
`;

const Loading = (props) => {
	const { isLoading } = useSelector((state) => state.ui);

	return (
		<Container visible={isLoading}>
			<IconWrapper>
				<LoadingOutlined style={{ fontSize: "3rem" }} />
			</IconWrapper>
		</Container>
	);
};

export default Loading;
