import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Image, Input } from "antd";
import FormSelector from "../common/FormSelector";
import { NoFormSelected } from "../../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import { sendDiscordMsg } from "../../../store/slices/dispatcherSlice";
import QRCode from "qrcode.react";

const Container = styled.div`
	width: 100%;
	padding: 20px;
	height: calc(100vh - 65px);
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 20px;
	place-items: center;
`;

const DispatchContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border-radius: 12px;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
`;
const HeadingText = styled.div`
	width: 100%;
	padding: 12px;
	font-weight: 600;
	text-align: left;
	color: var(--primaryColor);
	border-radius: 12px 12px 0px 0px;
	text-transform: uppercase;
	background-color: rgb(29, 189, 255, 0.1);
`;

const FormPreviewer = styled.div`
	width: 100%;
	min-height: 40%;
	max-height: 50%;
	display: flex;
	flex-direction: ${(props) => (props.direction ? "column" : "row")};
	justify-content: space-evenly;
	align-items: center;
`;

const PreviewImage = styled.div`
	height: 80%;
	width: 50%;
	border-radius: 10px;
	cursor: pointer;
	border: solid 1px #c4d2eb;
	padding: 2px;
	display: flex;
	justify-content: center;
`;

const StyledLabel = styled.label`
	display: block;
	text-align: left;
	padding: 0.5em;
	font-weight: 500;
	color: ${(props) => (props.color ? props.color : "black")};
`;

const QRContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 25px;
	min-height: 200px;
`;

const Instruction = styled.p`
	padding: 1em 0.5em 0 0.5em;
`;

const StyledButton = styled.div`
	width: 15%;
	height: 45px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 8px 12px 0 #1db6ff5c;
	border: solid 1px var(--primaryColor);
	background-color: ${(props) =>
		props.color ? props.color : `var(--primaryColor);`}
	color: white;
	cursor: pointer;
	margin-left: 20px;
	font-weight: 500;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
`;

const Discord = () => {
	const dispatch = useDispatch();

	const [selectedForm, setSelectedForm] = useState("");
	const [webhookUrl, setWebhookUrl] = useState("");
	const [message, setMessage] = useState("");
	const { allForms } = useSelector((state) => state.forms);

	const { orgId } = JSON.parse(localStorage.getItem("authInfo"));
	const selectedItem = allForms.find((form) => form._id === selectedForm);
	const publicURI = selectedItem && selectedItem.publicURI;

	const handleSendForm = () => {
		if (webhookUrl) {
			let discordMessage = {
				formId: selectedForm,
				webhookUrl: webhookUrl,
				message: message,
			};
			dispatch(sendDiscordMsg(discordMessage));
			setMessage("");
		}
	};

	const renderFormPreview = () => {
		if (selectedForm) {
			return `https://kuber-dev.s3.amazonaws.com/${orgId}/resources/templates/${selectedForm}.png`;
		}
		return NoFormSelected;
	};

	return (
		<Container>
			<DispatchContainer>
				<HeadingText>{"Post your form to discord channel"}</HeadingText>
				<Instruction>
					{
						"Paste the WebHook URL copied from your discord channel integration. If you are not sure where to get your Webhook URL. Please follow this "
					}
					<a
						href={
							"https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						link
					</a>
				</Instruction>
				<div style={{ padding: "0 1em" }}>
					<StyledLabel>WebHook URL</StyledLabel>
					<Input
						value={webhookUrl}
						onChange={(e) => setWebhookUrl(e.target.value)}
						placeholder="Paste the discord channel WebHook URL here.. "
					/>
					<StyledLabel>Message</StyledLabel>
					<Input
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder="Add message along with the form (Optional)..."
					/>
				</div>
				<FormPreviewer direction={selectedForm ? false : true}>
					{selectedForm ? (
						<Fragment>
							<PreviewImage>
								<Image
									src={renderFormPreview()}
									preview={true}
									height={"100%"}
									style={{
										borderRadius: "30px",
										objectFit: "contain",
										boxShadow: "0 3px 16px 0 rgba(149, 171, 211, 0.22)",
									}}
								/>
							</PreviewImage>
							<QRContainer>
								<StyledLabel color={"#6c80a3"}>QRCode</StyledLabel>
								<QRCode
									id={"form-qr-code"}
									size={200}
									level={"H"}
									value={`${publicURI}`}
								/>
							</QRContainer>
						</Fragment>
					) : (
						<Fragment>
							<PreviewImage>
								<div
									style={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<img
										src={NoFormSelected}
										height={"60%"}
										alt="form broken"
									></img>
									<span
										style={{
											color: "#6c80a3",
											fontWeight: 500,
											padding: "10px",
										}}
									>
										{"Please select a form to be dispatched"}
									</span>
								</div>
							</PreviewImage>
						</Fragment>
					)}
				</FormPreviewer>
				<ButtonContainer>
					<StyledButton
						color={"white"}
						onClick={() => {
							setMessage("");
							setWebhookUrl("");
						}}
					>
						Discard
					</StyledButton>
					<StyledButton onClick={() => handleSendForm()}>Send</StyledButton>
				</ButtonContainer>
			</DispatchContainer>
			<FormSelector
				title={"Select Form to post globally"}
				selectedForm={selectedForm}
				setSelectedForm={setSelectedForm}
			></FormSelector>
		</Container>
	);
};

export default Discord;
