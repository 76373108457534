export const contractConfig = {
	abi: [
		{ inputs: [], stateMutability: "nonpayable", type: "constructor" },
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: "address",
					name: "owner",
					type: "address",
				},
				{
					indexed: true,
					internalType: "address",
					name: "spender",
					type: "address",
				},
				{
					indexed: false,
					internalType: "uint256",
					name: "value",
					type: "uint256",
				},
			],
			name: "Approval",
			type: "event",
		},
		{
			anonymous: false,
			inputs: [
				{
					indexed: true,
					internalType: "address",
					name: "from",
					type: "address",
				},
				{ indexed: true, internalType: "address", name: "to", type: "address" },
				{
					indexed: false,
					internalType: "uint256",
					name: "value",
					type: "uint256",
				},
			],
			name: "Transfer",
			type: "event",
		},
		{
			inputs: [
				{ internalType: "address", name: "owner", type: "address" },
				{ internalType: "address", name: "spender", type: "address" },
			],
			name: "allowance",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "spender", type: "address" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "approve",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "address", name: "account", type: "address" }],
			name: "balanceOf",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "decimals",
			outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "spender", type: "address" },
				{ internalType: "uint256", name: "subtractedValue", type: "uint256" },
			],
			name: "decreaseAllowance",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{
					components: [
						{ internalType: "address", name: "rewardAddress", type: "address" },
						{ internalType: "uint256", name: "rewardAmount", type: "uint256" },
					],
					internalType: "struct ERC20.Reviewer[]",
					name: "reviewers",
					type: "tuple[]",
				},
				{
					internalType: "uint256",
					name: "rewardToDistribute",
					type: "uint256",
				},
				{ internalType: "uint256", name: "index", type: "uint256" },
				{ internalType: "string", name: "hash", type: "string" },
			],
			name: "distributeRewards",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{
					components: [
						{ internalType: "address", name: "rewardAddress", type: "address" },
						{ internalType: "uint256", name: "rewardAmount", type: "uint256" },
					],
					internalType: "struct ERC20.Reviewer[]",
					name: "reviewers",
					type: "tuple[]",
				},
				{
					internalType: "uint256",
					name: "rewardToDistribute",
					type: "uint256",
				},
				{ internalType: "uint256", name: "index", type: "uint256" },
				{ internalType: "string", name: "hash", type: "string" },
				{ internalType: "contract IERC20", name: "token", type: "address" },
				{ internalType: "uint256", name: "decimal", type: "uint256" },
			],
			name: "distributeRewardsAsAnyERC20",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [],
			name: "getRewardAmount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
			name: "getSurveyHash",
			outputs: [{ internalType: "string", name: "", type: "string" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "spender", type: "address" },
				{ internalType: "uint256", name: "addedValue", type: "uint256" },
			],
			name: "increaseAllowance",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [],
			name: "name",
			outputs: [{ internalType: "string", name: "", type: "string" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
			name: "setInitialRewardAmount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
			name: "setRewardAmount",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [],
			name: "symbol",
			outputs: [{ internalType: "string", name: "", type: "string" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [],
			name: "totalSupply",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "to", type: "address" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "transfer",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "nonpayable",
			type: "function",
		},
		{
			inputs: [
				{ internalType: "address", name: "from", type: "address" },
				{ internalType: "address", name: "to", type: "address" },
				{ internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "transferFrom",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "nonpayable",
			type: "function",
		},
	],
	contractAddress: "0x8F223a7c6BE064072C0C872CCa2095bbe4230b01",
};
