import React from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import SingleDispatcher from "./components/SingleDispatcher.jsx";
import BulkDispatcher from "./components/BulkDispatcher.jsx";

const { TabPane } = Tabs;

const Container = styled.div``;

const EmailDispatcher = () => {
	return (
		<Container>
			<Tabs
				defaultActiveKey="1"
				centered={true}
				tabBarStyle={{ marginBottom: "0px" }}
			>
				<TabPane tab={"SINGLE EMAIL DISPATCHER"} key="1">
					<SingleDispatcher />
				</TabPane>
				<TabPane tab={"BULK EMAIL DISPATCHER"} key="2">
					<BulkDispatcher />
				</TabPane>
			</Tabs>
		</Container>
	);
};

export default EmailDispatcher;
