import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormListCard from "./components/DisplayForms/FormBox";
import FormPreview from "./components/DisplayForms/FormPreview";
import TopNavBar from "../common/TopNavBar";
import PageEmptyState from "../../common/PageEmptyState";
import { fetchForms } from "../../../store/slices/formSlice";
import FormEmptyState from "../../../assets/images/FormEmptyState.svg";
import AddNewItem from "../common/AddNewItem";
import { AddFormCardIcon } from "../../../assets/index";
import { FormManagementContext } from "../index";

const FormWrapperContainer = styled.div`
	width: 100%;
	height: calc(100vh - 60px);
	background-color: #f5f6fa;
`;

const FormBoxContainer = styled.div`
	grid-gap: 40px;
	width: 100%;
	height: calc(100vh - 60px - 70px);
	padding: 10px;
	position: relative;
	overflow-y: auto;
`;

const FormOverflowWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 250px));
	grid-gap: 30px;
	padding: 10px;
`;

const FormsMain = () => {
	const dispatch = useDispatch();
	const { setSelectedSubNav } = useContext(FormManagementContext);
	const { allForms } = useSelector((state) => state.forms);
	const fetchFormList = useCallback(() => dispatch(fetchForms()), [dispatch]);

	useEffect(() => {
		fetchFormList();
	}, [fetchFormList]);

	const handleNavigation = (navPath) => {
		setSelectedSubNav(navPath);
	};

	const [previewForm, setPreviewForm] = useState(false);
	const [previewFormId, setPreviewFormId] = useState("");
	const [searchText, setSearchText] = useState("");

	return (
		<FormWrapperContainer>
			<TopNavBar
				tabName={"forms"}
				setSearchText={setSearchText}
				navPath={"displayForms"}
			/>
			<FormBoxContainer>
				<FormPreview
					previewForm={previewForm}
					setPreviewForm={setPreviewForm}
					previewFormId={previewFormId}
					headerVisible={false}
				></FormPreview>
				{allForms && allForms.length === 0 ? (
					<PageEmptyState
						srcImage={FormEmptyState}
						message={"Create Your First Form"}
						// buttonText={"Create Form"}
						// buttonOnClick={() => handleNavigation("addForms")}
						onClick={() => handleNavigation("addForms")}
					></PageEmptyState>
				) : (
					<FormOverflowWrapper>
						<AddNewItem
							tab={"forms"}
							cardIcon={AddFormCardIcon}
							cardMsg={"Add New Form"}
							navPath={"addForms"}
							navType={"URLPath"}
							handleNavigation={() => handleNavigation("addForms")}
						/>
						{allForms &&
							allForms
								.filter((value) => {
									return (
										value &&
										value.formName
											.toLowerCase()
											.includes(searchText.toLowerCase())
									);
								})
								.map((item, index) => {
									return (
										<FormListCard
											key={index}
											contentItem={item}
											setPreviewForm={setPreviewForm}
											setPreviewFormId={setPreviewFormId}
										></FormListCard>
									);
								})}
					</FormOverflowWrapper>
				)}
			</FormBoxContainer>
		</FormWrapperContainer>
	);
};

export default FormsMain;
