import React, { useState } from "react";
import { Rate } from "antd";
import styled from "styled-components";

const RaterWrapper = styled.div`
	width: 100%;
	display: inline-block;
`;

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const Rater = (props) => {
	const { defaultRate, isDisabled, handleChange, contentId } = props;

	const [rateValue, setRateValue] = useState(defaultRate);

	return (
		<RaterWrapper>
			<Rate
				tooltips={desc}
				onChange={(value) => {
					if (!isDisabled) {
						setRateValue(value);
						handleChange(contentId, value);
					}
				}}
				value={rateValue}
				disabled={isDisabled}
				style={{ float: "left" }}
			/>
		</RaterWrapper>
	);
};

export default Rater;
