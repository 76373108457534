import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { pageLoaded, setOrgLogo } from "./uiSlice";
import { updateDefaultDispatcher } from "./dispatcherSlice";
import { getBearerToken, dataURLtoFile } from "../../helpers/utils";

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

const initialState = {
	webhooks: [],
	theme: {
		primaryColor: "#1db6ff",
		secondaryColor: "#e97a43",
		secondaryLighterColor: "#ffe5d9",
		buttonBGColor: "#1d7aff",
		buttonFGColor: "#fff",
	},
	subscription: {},
	users: [],
	groups: [],
	activateUser: {},
};

export const addNewWebHook = createAsyncThunk(
	"settings/addWebhooks",
	async (params = {}, ThunkAPI) => {
		const response = await axios.post(
			`${apiEndPoint}/api/settings/webhooks`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		return response.data;
	}
);

export const getWebhooks = createAsyncThunk(
	"settings/getWebhooks",
	async (params = {}, ThunkAPI) => {
		const response = await axios.get(`${apiEndPoint}/api/settings/webhooks`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		return response.data;
	}
);

export const deleteWebhook = createAsyncThunk(
	"settings/deleteWebhook",
	async (params = {}, ThunkAPI) => {
		const response = await axios.delete(
			`${apiEndPoint}/api/settings/webhooks/${params.webHookId}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		return response.data;
	}
);

export const getBillingStatus = createAsyncThunk(
	"settings/getBillingStatus",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(
			`${apiEndPoint}/api/subscription/billing-status`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const getChannelConfig = createAsyncThunk(
	"settings/channelConfig",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { channel, integration } = params;
		const response = await axios.get(
			`${apiEndPoint}/api/settings/channels/email?channelName=${channel}&integration=${integration}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		if (channel === "email")
			ThunkAPI.dispatch(
				updateDefaultDispatcher({
					dispatcher: response.data.integrationInfo.defaultEmailDispatcher,
				})
			);
		return { ...response.data, channel, integration };
	}
);

export const updateAPIKeys = createAsyncThunk(
	"settings/updateAPIKeys",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/settings/channels/update-api-keys`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const updateSendGridAPIKeys = createAsyncThunk(
	"dispatcher/sendgrid-email-update",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/settings/channels/sendgrid`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const updateCorportateConfig = createAsyncThunk(
	"dispatcher/corportateConfig",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/settings/channels/corporate`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const fetchOrgUsers = createAsyncThunk(
	"users/fetchUsers",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(`${apiEndPoint}/api/users`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		console.log("dispatchdispatchdispatchdispatch", response.data);
		return response.data;
	}
);

export const fetchGroups = createAsyncThunk(
	"users/fetchGroups",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(`${apiEndPoint}/api/users/groups`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const deleteUser = createAsyncThunk(
	"users/deleteUser",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { userId } = params;
		const response = await axios.delete(`${apiEndPoint}/api/users/${userId}`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});

		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return { ...response.data, userId };
	}
);

export const saveUser = createAsyncThunk(
	"users/saveUser",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(`${apiEndPoint}/api/users`, params, {
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			});
			console.log("err.response", response);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			if (response && response.data) return { ...response.data };
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const addNewGroup = createAsyncThunk(
	"groups/newGroup",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(
				`${apiEndPoint}/api/users/groups`,
				params,
				{
					headers: {
						Authorization: `Bearer ${getBearerToken()}`,
					},
				}
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			if (response && response.data) return { ...response.data };
		} catch (err) {
			return ThunkAPI.rejectWithValue(err);
		}
	}
);

export const updateGroup = createAsyncThunk(
	"groups/updateGroup",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.put(
				`${apiEndPoint}/api/users/groups`,
				params,
				{
					headers: {
						Authorization: `Bearer ${getBearerToken()}`,
					},
				}
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			if (response && response.data) return { ...response.data };
		} catch (err) {
			return ThunkAPI.rejectWithValue(err);
		}
	}
);

export const updateOrgLogo = createAsyncThunk(
	"org/updateLogo",
	async ({ bodyFormData, filename }, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const file = dataURLtoFile(bodyFormData, filename);
			const data = new FormData();
			data.append("template", file, file.name);
			const response = await axios.post(
				`${apiEndPoint}/api/settings/profile/update-logo`,
				data,
				{
					headers: {
						Authorization: `Bearer ${getBearerToken()}`,
						"Content-Type": "multipart/form-data",
					},
				}
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			ThunkAPI.dispatch(setOrgLogo(response.data.payload.orgLogo));
			return response.data;
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
		}
	}
);

export const resetPassword = createAsyncThunk(
	"users/resetPassword",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(
				`${apiEndPoint}/api/settings/profile/update-password`,
				params,
				{
					headers: {
						Authorization: `Bearer ${getBearerToken()}`,
					},
				}
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return { ...response.data };
		} catch (err) {
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const getInviteStatus = createAsyncThunk(
	"user/invitestate",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const { orgId, userId } = params;
			const response = await axios.get(
				`${apiEndPoint}/api/users/activation-status?orgId=${orgId}&userId=${userId}`
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			console.log("user/invitestate", { ...response.data });
			return { ...response.data };
		} catch (err) {
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const activateUserByForm = createAsyncThunk(
	"user/activateByForm",
	async (params = {}, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(
				`${apiEndPoint}/api/users/activation-status`,
				params
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return { ...response.data };
		} catch (err) {
			return ThunkAPI.rejectWithValue(err.response.data);
		}
	}
);

const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {},
	extraReducers: {
		[addNewWebHook.fullfilled]: (state, action) => {
			const updatedWebhooks = [...state.webhooks, { ...action.payload }];
			console.log(updatedWebhooks);
			state.webhooks = [...updatedWebhooks];
		},
		[getWebhooks.fulfilled]: (state, action) => {
			state.webhooks = action.payload.webhooks;
		},
		[deleteWebhook.fulfilled]: (state, action) => {
			const updatedWebhooks = state.webhooks.filter(
				(item) => item._id !== action.payload.deletedId
			);
			state.webhooks = updatedWebhooks;
		},
		[getBillingStatus.fulfilled]: (state, action) => {
			state.subscription = action.payload.message;
		},
		[getChannelConfig.fulfilled]: (state, action) => {
			const { integrationInfo, channel, integration } = action.payload;
			switch (channel) {
				case "whatsapp":
					state.whatsAppDispatcher = { ...integrationInfo };
					break;

				case "email":
					if (integration === "twilio") {
						state.sendGridDispatcher = { ...integrationInfo };
					} else if (integration === "corporate") {
						state.corporateDispatcher = { ...integrationInfo };
					}
					break;

				default:
					return state;
			}
		},
		[updateSendGridAPIKeys.fulfilled]: (state, action) => {
			state.sendGridDispatcher = { ...action.payload };
		},

		[saveUser.fulfilled]: (state, action) => {
			const { userdetails } = action.payload;
			state.users.push(userdetails);
		},
		[saveUser.rejected]: (state, action) => {
			console.log("action.error", action);
		},
		[deleteUser.fulfilled]: (state, action) => {
			const { userId } = action.payload;
			const updatedUsers = state.users.filter((item) => item._id !== userId);
			state.users = updatedUsers;
		},
		[fetchOrgUsers.fulfilled]: (state, action) => {
			const { users } = action.payload;
			state.users = [...users];
		},
		[resetPassword.fulfilled]: (state, action) => {},
		[fetchGroups.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			state.groups = [...payload.groups];
		},
		[addNewGroup.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			state.groups.push(payload.group);
		},
		[updateGroup.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			state.groups = [...payload.groups];
		},
		[getInviteStatus.fulfilled]: (state, action) => {
			const { code, payload } = action.payload;
			if (code === "USER_ACTIVATED") {
				state.activateUser = { activated: true };
			} else {
				state.activateUser = { ...payload, activated: false };
			}
		},
		[updateOrgLogo.fulfilled]: (state, action) => {},
	},
});

export default settingsSlice.reducer;
