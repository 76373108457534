import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { authenticateUser } from "../../store/slices/authSlice";
import openNotificationWithIcon from "../common/Notification";

const LoginContainer = styled.div`
	padding: 20px;
	min-height: 80%;
	background-color: white;
	border-radius: 12px;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) and (max-width: 2900px) {
		width: 25%;
	}
`;

const HeaderText = styled.div`
	width: 100%;
	min-height: 50px;
	padding: 10px;
	text-align: left;
	font-weight: 600;
	font-size: 18px;
`;

const LabelWrapper = styled.div`
	width: 100%;
	text-align: left;
	padding: 8px;
	font-weight: 300;
	font-size: 15px;
	text-tranform: uppercase;
	//  font-family: proxima-nova, sans-serif;
`;

const StyledInputWrapper = styled.div`
	background-color: #408eff;
	border-radius: 6px;
	padding-left: 6px;
	width: 100%;
	display: flex; /*added*/
	flex-direction: column; /*added*/
`;

const StyledInput = styled.input`
	height: 35px;
	border-radius: 4px;
	padding-left: 14px;
	border: solid 1px #dee5f4;
	background-color: #f7f7f7;
	text-align: left;
	outline: none;
	::-webkit-input-placeholder {
		color: #d5d5d5;
		font-size: 12px;
	}
`;

const ForgotPassword = styled.div`
	height: 25px;
	width: 100%;
	font-size: 10px;
	font-weight: 500;
	text-align: right;
	margin-top: 12px;
	color: #505f7b;
	cursor: pointer;
`;

// const SignUpLabel = styled.div`
// 	height: 35px;
// 	width: 100%;
// 	padding: 20px;
// `;

const EyeIconWrapper = styled.span`
	position: absolute;
	top: 15%;
	right: 10px;
	padding: 3px;
	font-size: 15px;
`;

const buttonStyle = {
	width: "98%",
	height: "35px",
	backgroundColor: "#408eff",
	marginTop: "20px",
	fontWeight: "600",
	textTransform: "upperCase",
	borderRadius: "4px",
	button: "focus {outline:0;}",
	outline: "none",
	color: "white",
};

const SSOGoogle = styled.button`
	width: 100%;
`;

const LoginForm = ({ props }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const dispatch = useDispatch();

	const { isLoggedIn } = useSelector((state) => state.auth);

	if (isLoggedIn) props.history.push("/workspaces");

	const handleSubmit = async (values) => {
		try {
			dispatch(authenticateUser(values));
		} catch (error) {
			console.log(error);
			openNotificationWithIcon("error", "Login Failed");
		}
	};

	const handleGoogleSSO = () => {
		let host = window.location.host;

		let endPoint = "";
		if (host === "localhost:3000") {
			endPoint = "http://localhost:3001";
		} else {
			endPoint = `https://${host}`;
		}
		window.location = `${endPoint}/api/auth/sso/google?auth=login`;
	};

	return (
		<LoginContainer>
			<HeaderText>Login</HeaderText>
			<Form onFinish={handleSubmit}>
				<LabelWrapper>Email</LabelWrapper>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: "Please enter a valid email address!",
							type: "email",
						},
					]}
				>
					<StyledInputWrapper>
						<StyledInput
							value={username}
							placeholder={"Your email address goes here."}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</StyledInputWrapper>
				</Form.Item>
				<LabelWrapper>Password</LabelWrapper>
				<Form.Item
					name="password"
					rules={[
						{ required: true, message: "Please enter valid password!", min: 5 },
					]}
				>
					<StyledInputWrapper>
						<StyledInput
							type={passwordVisible ? "text" : "password"}
							value={password}
							placeholder={"Must have atleast 6 characters"}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<EyeIconWrapper>
							{passwordVisible ? (
								<EyeOutlined
									onClick={() => setPasswordVisible(false)}
								></EyeOutlined>
							) : (
								<EyeInvisibleOutlined
									onClick={() => setPasswordVisible(true)}
								></EyeInvisibleOutlined>
							)}
						</EyeIconWrapper>
					</StyledInputWrapper>
				</Form.Item>
				<Link to="/forgot-password">
					<ForgotPassword>Forgot Password ?</ForgotPassword>
				</Link>
				<Form.Item>
					<Button htmlType="submit" style={buttonStyle}>
						SIGN IN
					</Button>
				</Form.Item>
			</Form>
			<div className="line-wrapper">
				<span className="span-wrapper">or</span>
			</div>
			<SSOGoogle
				type="button"
				className="login-with-google-btn"
				onClick={handleGoogleSSO}
			>
				Sign in with Google
			</SSOGoogle>
			{/* <SignUpLabel>
				<span style={{ color: "#1673ff" }}>Don't have an account?</span>
				<Link to="/signup">
					<span style={{ color: "#f76e5d" }}> Signup</span>
				</Link>
			</SignUpLabel> */}
		</LoginContainer>
	);
};

// ClientId - 390343432354-mvri3u6lqa697oa81c6jqbk2jj0hd644.apps.googleusercontent.com
// ClientSecret - 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
export default LoginForm;
