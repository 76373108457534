import React from "react";
import { PieChart, Pie, Cell, Legend, LabelList, Tooltip } from "recharts";
import styled from "styled-components";
import { PieChartFilled } from "@ant-design/icons";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

const PieContainer = styled.div`
	border-radius: 10px;
	border: 0.1 solid;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 35%;
	margin: 0.5em;
	background: white;
`;

const PieChartWrapper = styled.div`
	padding: 20px;
	display: flex;
	justify-content: center;
`;

const NoDataWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	font-size: 36px;
	color: #95abd3;
`;

const NoDataText = styled.span`
	display: block;
	text-align: center;
	font-weight: 800;
	padding: 8px;
	font-size: 12px;
	color: #95abd3;
`;

const WidgetTitle = styled.span`
	width: 100%;
	padding: 15px;
	font-size: 12px;
	font-weight: 600;
`;

const COLORS = ["#0088FE", "#fdc300", "#fd847c", "#8884d8"];

const FullPieChart = (props) => {
	const { data } = props;
	const CustomTooltip = ({ active, payload, label }) => {
		if (active) {
			return (
				<div
					className="custom-tooltip"
					style={{
						backgroundColor: "#ffff",
						padding: "5px",
						border: "1px solid #cccc",
						fontWeight: 800,
					}}
				>
					<label>{`${
						payload && payload[0].name && payload[0].name.toUpperCase()
					} : ${payload[0].value}`}</label>
				</div>
			);
		}

		return null;
	};

	const { title } = props;

	return (
		<PieContainer>
			<WidgetTitle>{title}</WidgetTitle>
			{data && data.length === 0 ? (
				<NoDataWrapper>
					<PieChartFilled></PieChartFilled>
					<NoDataText>No Data</NoDataText>
				</NoDataWrapper>
			) : (
				<PieChartWrapper>
					<ResponsiveContainer width="100%" height={180}>
						<PieChart>
							<Pie
								data={data}
								cx={"40%"}
								cy={"50%"}
								innerRadius={"60%"}
								outerRadius={"80%"}
								fill="#8884d8"
								paddingAngle={5}
								labelLine={false}
								label={() => <LabelList dataKey="name" position="outside" />}
							>
								{data.map((entry, index) => (
									<Cell fill={COLORS[index % COLORS.length]} />
								))}
							</Pie>
							<Tooltip content={<CustomTooltip />} />
							<Legend
								iconType="circle"
								layout="vetical"
								verticalAlign="middle"
								align="right"
							/>
						</PieChart>
					</ResponsiveContainer>
				</PieChartWrapper>
			)}
		</PieContainer>
	);
};

export default FullPieChart;
