import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Radio, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { setUiTheme } from "../../../../store/slices/uiSlice";
import { updateOrgLogo } from "../../../../store/slices/settingSlice";
import { beforeImgUpload } from "../../../../helpers/utils";
import { useEffect } from "react";

const { Panel } = Collapse;

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const PageHeader = styled.div`
	text-align: left;
	height: 60px;
	padding: 15px;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
`;

const PanelContainer = styled.div`
	height: calc(100vh - 280px);
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-template-rows: minmax(150px, 170px);
	grid-gap: 15px;
`;

const PalleteContainer = styled.div`
	height: 150px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	border: 1px solid #80808073;
	cursor: pointer;
`;

const ColorsWrapper = styled.div`
	width: auto;
	height: 120px;
	display: flex;
	flex: 1;
	flex-direction: row;
	border-radius: 10px 10px 0px 0px;
`;

const PalleteColor = styled.div`
	width: 60px;
	height: 100%;
	flex: 1;
	background-color: ${(props) => `#${props.color}`};
	border-radius: ${(props) =>
		props.index === 0
			? "10px 0px 0px 0px"
			: props.index === 4
			? "0px 10px 0px 0px"
			: ""};
`;

const PalletteName = styled.span`
	padding: 10px;
	text-transform: uppercase;
	font-weight: 500;
	background-color: white;
	border-radius: 0px 0px 10px 10px;
	border-top: 1px solid #80808073;
`;

const StyledButton = styled.div`
	padding: 10px;
	max-width: 200px;
	height: 40px;
	text-align: center;
	border-radius: 5px;
	box-shadow: 0 8px 12px 0 rgba(29, 122, 255, 0.25);
	background-color: var(--primaryColor);
	color: #fff;
	cursor: pointer;
`;
const PanelHeader = styled.span`
	padding: 5px;
	text-align: left;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	float: left;
`;
const FilterBar = styled.span`
	height: 60px;
	display: flex;
	justify-content: flex-end;
`;

const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 260px);
`;

const FormIcon = styled.img`
	width: 90%;
	height: 90%;
	text-align: center;
`;

const palettes = [
	{
		name: "Brick Red",
		palette: ["fe9c8f", "feb2a8", "fec8c1", "fad9c1", "f9caa7"],
	},
	{
		name: "Ocean Blue",
		palette: ["6080f5", "add6ff", "d6eaff", "eaf4ff", "f8fbff"],
	},
	{
		name: "Cream Green",
		palette: ["28df99", "99f3bd", "d2f6c5", "d8f8b7", "f6f7d4"],
	},
	{
		name: "DayShades",
		palette: ["F4D35E", "0D3B66", "FAF0CA", "EE964B", "F95738"],
	},
	{
		name: "Pink Splash",
		palette: ["F56476", "DFBBB1", "E43F6F", "BE3E82", "5E4352"],
	},
];

const DisplaySettings = () => {
	const dispatch = useDispatch();
	const [chosenPalette, setchosenPalette] = useState(-1);
	const [orgIcon, setOrgIcon] = useState("");

	const { orgLogo, orgName } = useSelector((state) => state.ui);

	const handleThemeChange = async () => {
		if (chosenPalette >= 0) {
			let palette = {
				primaryColor: `#${palettes[chosenPalette]["palette"][0]}`,
				secondaryColor: `#${palettes[chosenPalette]["palette"][1]}`,
				secondaryLighterColor: `#${palettes[chosenPalette]["palette"][2]}`,
				buttonBGColor: `#${palettes[chosenPalette]["palette"][3]}`,
				buttonFGColor: `#${palettes[chosenPalette]["palette"][4]}`,
			};

			console.log("Clicked", JSON.stringify(palette));
			dispatch(setUiTheme(palette));
		}
	};

	const handleChange = async ({ file, fileList }) => {
		const uploadedFile = fileList.slice(-1).pop();
		let reader = new FileReader();

		console.log(file, fileList);

		reader.onload = async (e) => {
			try {
				console.log(
					"e.target.resulte.target.resulte.target.result",
					e.target.result
				);
				setOrgIcon(e.target.result);
				dispatch(
					updateOrgLogo({
						bodyFormData: e.target.result,
						filename: uploadedFile.name.replace(" ", ""),
					})
				);
				// dispatch(updateIconList(data, "add"));
			} catch (err) {
				console.log(err);
				message.info("Unable to upload org logo  , Please try again later!");
			}
		};
		reader.readAsDataURL(uploadedFile.originFileObj);
	};

	useEffect(() => {
		if (orgLogo) setOrgIcon(orgLogo);
	}, [orgLogo]);

	return (
		<Container>
			<PageHeader>Display settings</PageHeader>
			<Collapse defaultActiveKey={["2"]} accordion={true}>
				<Panel header={<PanelHeader>LOGO</PanelHeader>} key="1">
					<LogoContainer>
						<div>
							<ImgCrop rotate>
								<Upload
									name="avatar"
									listType="picture-card"
									className="avatar-uploader"
									beforeUpload={beforeImgUpload}
									showUploadList={false}
									customRequest={({ file, onSuccess }) => {
										setTimeout(() => {
											onSuccess("ok");
										}, 0);
									}}
									accept={"image/png, image/jpeg, image/svg+xml, image/jpg"}
									onChange={handleChange}
									style={{ height: "80%" }}
								>
									<div>
										{orgIcon ? (
											<img
												width={"150px"}
												height={"150px"}
												style={{ objectFit: "contain" }}
												src={orgIcon}
												alt="avatar"
											/>
										) : (
											<FormIcon src={orgIcon} />
										)}
									</div>
								</Upload>
							</ImgCrop>
							<span style={{ padding: "1em", fontSize: "1.5em" }}>
								<h3>{orgName}</h3>
							</span>
						</div>
					</LogoContainer>
				</Panel>
				<Panel header={<PanelHeader>THEME OPTIONS</PanelHeader>} key="2">
					<Fragment>
						<FilterBar>
							<StyledButton onClick={() => handleThemeChange()}>
								APPLY THEME CHANGES
							</StyledButton>
						</FilterBar>
						<PanelContainer>
							{palettes.map((item, index) => (
								<PalleteContainer
									onClick={() => setchosenPalette(index)}
									key={index}
									selected={chosenPalette === index}
								>
									<ColorsWrapper>
										{item.palette.map((color, _index) => {
											return (
												<PalleteColor
													color={color}
													index={_index}
												></PalleteColor>
											);
										})}
									</ColorsWrapper>
									<PalletteName>
										<Radio
											checked={index === chosenPalette}
											style={{ display: "inline-block" }}
										></Radio>
										{item.name}
									</PalletteName>
								</PalleteContainer>
							))}
						</PanelContainer>
					</Fragment>
				</Panel>
			</Collapse>
		</Container>
	);
};

export default DisplaySettings;

/*
<PalleteWrapper>


</PalleteWrapper>
*/
