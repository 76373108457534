import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Drawer, Switch } from "antd";
import { setFormAcceptance } from "../../../../../store/slices/formSlice";
import { getContentDisplayIcon } from "./../../../../../helpers/utils";

const DrawerWrapper = styled.div`
	width: 100%;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	position: relative;
	padding: 5px;
	background-color: white;
`;

const DrawerHeaderWrapper = styled.div`
	height: 90px;
	text-transform: uppercase;
	border-bottom: solid 1px #dee5f4;
	text-align: center;
	width: 100%;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	justify-content: center;
	background-color: white;
`;

const QueriesContainer = styled.div`
	padding: 5px;
	background-color: white;
`;

const QueryWrapperBox = styled.div`
	height: 100px;
	margin: 5px;
	display: flex;
	justify-content: flex-start;
`;

const TextQueryWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const TextQueryContainer = styled.div`
	font-size: 14px;
	font-weight: 500;
	padding: 8px;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const IconQueryWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: row;
`;

const IconContainer = styled.img`
	height: 100%;
	max-width: 80px;
	padding: 5px;
	object-fit: contain;
`;

const IconResponseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
`;

const IconLabelWrapper = styled.div`
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	padding: 5px;
`;

const SubmitButton = styled.div`
	height: 40px;
	margin: 0px auto;
	width: 50%;
	background-color: var(--primaryColor);
	color: white;
	text-align: center;
	vertical-align: middle;
	font-weight: 500;
	border-radius: 5px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 10px;
`;

const QueryNumber = styled.div`
	height: 100%;
	width: 30px;
	padding: 8px;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ResponseToggle = styled.div`
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	margin: 1em 0px 1em 0px;
	font-weight: 500;
	justify-content: space-between;
`;

const FormHeaderContainer = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 2px;
	background-color: white;
`;

const FormIcon = styled.img`
	width: 100px;
	height: 100%;
	padding: 12px;
	display: ${(props) => (props.hasIcon ? "inline-block" : "none")};
	object-fit: contain;
`;

const FormTitle = styled.span`
	padding: 15px 10px 10px 10px;
	height: 100%;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`;

const FormMessage = styled(FormTitle)`
	padding: 20px 10px 10px 10px;
	font-size: 14px;
	font-weight: 500;
	color: black;
	width: 100%;
	background-color: white;
	text-transform: capitalise;
	text-align: center;
`;

const FormPreview = (props) => {
	const {
		previewForm,
		setPreviewForm,
		previewFormId,
		useBuilderContents,
		formHeader,
	} = props;

	const dispatch = useDispatch();
	const { allForms } = useSelector((state) => state.forms);
	const { builderContents } = useSelector((state) => state.forms);

	let formContents = {};

	if (useBuilderContents) {
		formContents["formName"] = formHeader["formName"];
		formContents["feedbackForm"] = builderContents;
	} else {
		formContents =
			allForms && allForms.filter((item) => item._id === previewFormId);
		formContents = formContents && formContents[0];
	}
	const [acceptResponse, setAcceptResponse] = useState(false);
	useEffect(() => {
		setAcceptResponse(formContents && formContents["acceptResponses"]);
	}, [formContents]);

	const handleToggle = () => {
		setAcceptResponse(!acceptResponse);
		dispatch(setFormAcceptance({ formId: previewFormId }));
	};

	return (
		<Drawer
			title={"Form Preview"}
			placement="right"
			closable={true}
			width={"40%"}
			visible={previewForm}
			onClose={() => setPreviewForm(false)}
		>
			<DrawerWrapper id="formCard">
				<DrawerHeaderWrapper>
					<FormHeaderContainer>
						{formHeader &&
						(formHeader["formIcon"] || formHeader["formName"]) ? (
							<React.Fragment>
								<FormIcon
									src={formHeader["formIcon"]}
									alt="avatar"
									hasIcon={formHeader["formIcon"]}
								/>
								<FormTitle>
									{(formHeader && formHeader["formName"]) || ""}
								</FormTitle>
							</React.Fragment>
						) : (
							""
						)}
						{formContents && formContents["formHeader"] ? (
							<React.Fragment>
								<FormIcon
									src={formContents["formHeader"]["formIcon"]}
									alt="avatar"
									hasIcon={formContents["formHeader"]["formIcon"]}
								/>
								<FormTitle>
									{formContents && formContents["formName"]}
								</FormTitle>
							</React.Fragment>
						) : (
							""
						)}
					</FormHeaderContainer>
				</DrawerHeaderWrapper>
				<FormMessage>
					{formContents && formContents["formHeader"]
						? formContents["formHeader"]["message"]
						: formHeader && formHeader["message"]}
				</FormMessage>
				<QueriesContainer>
					{formContents &&
						formContents["feedbackForm"] &&
						formContents["feedbackForm"].map((item, index) => {
							return (
								<QueryWrapperBox>
									{item.queryType === "text" ? (
										<TextQueryWrapper>
											<QueryNumber>
												<span>{`${index + 1}. `}</span>
											</QueryNumber>
											<TextQueryContainer>
												<span
													style={{ padding: "5px" }}
												>{`${item.queryValue}`}</span>
												<span
													style={{
														padding: "0.5em",
														height: "40px",
													}}
												>
													<img
														alt={""}
														src={getContentDisplayIcon(item.responseType)}
														height={"100%"}
													></img>
												</span>
											</TextQueryContainer>
										</TextQueryWrapper>
									) : (
										<IconQueryWrapper>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
												}}
											>
												<QueryNumber>
													<span>{`${index + 1}. `}</span>
												</QueryNumber>

												<IconContainer
													src={`${item.queryValue}?${new Date().getTime()}`}
													crossOrigin="anonymous"
												></IconContainer>
												<IconResponseWrapper>
													<IconLabelWrapper>{`${item.iconLabel}`}</IconLabelWrapper>
													<span
														style={{
															padding: "0.5em",
															height: "40px",
														}}
													>
														<img
															alt={""}
															src={getContentDisplayIcon(item.responseType)}
															height={"100%"}
														></img>
													</span>
												</IconResponseWrapper>
											</div>
										</IconQueryWrapper>
									)}
								</QueryWrapperBox>
							);
						})}
					<SubmitButton>SUBMIT</SubmitButton>
				</QueriesContainer>
			</DrawerWrapper>
			<ResponseToggle>
				<span style={{ width: "70%" }}>
					You can use the switch to accept or stop accepting responses for this
					form !
				</span>
				<div
					style={{
						width: "30%",
						display: "flex",
						flexDirection: "row-reverse",
						padding: "1em",
					}}
				>
					<Switch checked={acceptResponse} onChange={handleToggle} />
					<Tag color={acceptResponse ? "green" : "red"}>
						{acceptResponse ? "ACTIVE" : "IN-ACTIVE"}
					</Tag>
				</div>
			</ResponseToggle>
		</Drawer>
	);
};

export default FormPreview;
