import React from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
	width: 100%;
	height: ${(props) => (props.tab ? "260px" : "230px")};
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	cursor: pointer;
	border: solid 1px #dee5f4;
	float: right;
	border-radius: 5px;
	position: relative;
	background-color: #ffffff;
	:hover {
		box-shadow: none;
		transition: 0.7s;
	}
`;

const IconWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	padding: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const AddItem = styled.img`
	width: 60%;
	height: 60%;
`;

const CardText = styled.p`
	padding: 10px;
	color: #dddddd;
	font-weight: 600;
	text-transform: uppercase;
`;

const AddNewItem = (props) => {
	const { cardIcon, cardMsg, tab, handleNavigation } = props;
	return (
		<CardWrapper onClick={handleNavigation} tab={tab === "forms"}>
			<IconWrapper>
				<AddItem src={cardIcon} />
				<CardText>{cardMsg}</CardText>
			</IconWrapper>
		</CardWrapper>
	);
};

export default AddNewItem;
