import React, { useContext } from "react";
import styled from "styled-components";
import FormNavItems from "../form-management/common/FormNavItems";
import {
	DiscordColorLogo,
	WhatsappIcon,
	MailIcon,
	QRIcon,
	SlackLogo,
} from "../../assets/index";

import { FormDispatcherContext } from ".";

const FormContentsContainer = styled.div`
	display: flex;
	height: calc(100vh - 60px);
	flex: 1;
`;

const DispatcherLeftNavBar = styled.div`
	width: 15%;
	min-width: 200px;
	padding: 15px;
	border-right: solid 1px #dee5f4;
`;

const DispatchContentWrapper = styled.div`
	width: 85%;
`;

const FormDispatcher = (props) => {
	const navItems = [
		{ _id: 0, tabName: "email", srcIcon: MailIcon },
		{ _id: 1, tabName: "discord", srcIcon: DiscordColorLogo },
		{ _id: 2, tabName: "QR code-link", srcIcon: QRIcon },
		{ _id: 3, tabName: "whatsapp", srcIcon: WhatsappIcon },
		{ _id: 4, tabName: "slack", srcIcon: SlackLogo },
	];
	// { tabName: "SMS", srcIcon: SMSIcon },

	const { dispatcherNavTab, setDispatcherNavTab, setDispatcherSubNav } =
		useContext(FormDispatcherContext);

	const handleSelectedTab = (itemName) => {
		setDispatcherNavTab(itemName);
		if (itemName === "singleUser") {
			setDispatcherSubNav("displayHistory");
		}
	};

	return (
		<FormContentsContainer>
			<DispatcherLeftNavBar>
				{navItems.map((item) => {
					return (
						<FormNavItems
							key={item.tabName}
							itemName={item.tabName}
							handleSelectedTab={() => handleSelectedTab(item.tabName)}
							isSelectedTab={dispatcherNavTab === item.tabName}
							srcIcon={item.srcIcon}
						></FormNavItems>
					);
				})}
			</DispatcherLeftNavBar>
			<DispatchContentWrapper>{props.children}</DispatchContentWrapper>
		</FormContentsContainer>
	);
};

export default FormDispatcher;
