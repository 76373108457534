import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
// import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["ui", "environment"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== "production",
});

// const store = createStore(rootReducer, composeWithDevTools());

const persistor = persistStore(store);

export { store, persistor };
