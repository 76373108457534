import React, { useEffect } from "react";
import styled from "styled-components";
import LoginForm from "./Login";
import { ReviewfyTagLine } from "../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import { isSessionExpired } from "../../store/slices/uiSlice";
import { Alert } from "antd";
import WaveSvg from "../../assets/templates/Wave";

const Container = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
`;

const NavBar = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	padding: 0.5em;
`;

const SignInContainer = styled.div`
	width: 100%;
	height: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const HeadingLogo = styled.img`
	object-fit: contain;
	padding: 0.5em;
	max-width: 300px;
`;

const LogoWrapper = styled.div`
	display: flex;
	cursor: pointer;
`;

const SignIn = (props) => {
	const dispatch = useDispatch();
	const { msgAlert } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(isSessionExpired(false));
	}, [dispatch]);

	return (
		<Container>
			<NavBar>
				<LogoWrapper>
					<a
						href="https://reviewfy.io"
						target={"_blank"}
						rel="noopener noreferrer"
					>
						<HeadingLogo src={ReviewfyTagLine} />
					</a>
				</LogoWrapper>
			</NavBar>
			<Alert
				message="Error"
				type="error"
				showIcon
				closable
				style={{ display: `${msgAlert.length > 0 ? "block" : "none"}` }}
			/>
			<SignInContainer>
				<LoginForm props={props}></LoginForm>
			</SignInContainer>
			<WaveSvg></WaveSvg>
		</Container>
	);
};

export default SignIn;
