import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { message, Button, Timeline, Upload, Tooltip, Modal } from "antd";
import FormSelector from "../../common/FormSelector";
import { NoFormSelected } from "../../../../assets/index";
import { downloadTemplate } from "../../../../store/slices/dispatcherSlice";
import { csv2JSON } from "../../../../helpers/utils";
import {
	InboxOutlined,
	EyeTwoTone,
	FileTextTwoTone,
	CloseCircleTwoTone,
} from "@ant-design/icons";

const { Dragger } = Upload;

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 20px;
	padding: 10px;
	height: calc(100vh - 125px);
`;

const StepActionWrapper = styled.div`
	padding: 1em;
	transform: translate(0, -10%);
	border-radius: 10px;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 6px 0 #ebf3ff;
	margin-bottom: 1em;
	background: #ffffff;
	:after {
		content: " ";
		position: absolute;
		left: -15px;
		top: 2%;
		transform: translate(0, 50%);
		border-top: 15px solid transparent;
		border-right: 15px solid #dee5f4;
		border-left: none;
		border-bottom: 15px solid transparent;
	}
`;

const ActionHeader = styled.div`
	width: 100%;
	text-align: left;
	color: #7b8eaf;
	font-size: 1em;
	padding: 1em;
	display: flex;
`;

const TimelineWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

const SelectedForm = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
`;

const NoFormSelectedWrapper = styled.div`
	width: 100%;
	min-height: 100px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	border: dashed 1px #c4d2eb;
	justify-content: center;
	align-items: center;
`;

const PreviewForm = styled.div`
	width: 100%;
	min-height: 100px;
	padding: 1rem;
	display: flex;
	border-radius: 10px;
	border: dashed 1px #c4d2eb;
	justify-content: space-between;
	align-items: center;
`;

const ButtonContainer = styled.div`
	height: 60px;
	width: 100%;
	padding: 1rem;
	display: ${(props) => (props.display ? "flex" : "none")};
	justify-content: center;
	align-items: center;
`;

const BulkDispatcher = () => {
	const dispatch = useDispatch();

	const [uploadData, setUploadData] = useState({});
	const [previewOn, setPreviewOn] = useState(false);
	const [fileName, setFileName] = useState("");
	const { allForms } = useSelector((state) => state.forms);
	const [selectedForm, setSelectedForm] = useState(
		(allForms && allForms.length > 0 && allForms[0]._id) || ""
	);
	const formItem =
		(allForms && allForms.find((item) => item._id === selectedForm)) || "";

	const { orgId } = JSON.parse(localStorage.getItem("authInfo"));

	const sendBulkMsgs = async () => {
		try {
			// await uploadBulkTemplate({
			// 	formId: selectedForm,
			// 	recepients: uploadData,
			// });
			message.info("Bulk Whatsapp Messages Dispatched");
		} catch (err) {
			console.log("Error", err);
		}
	};

	const downloadEmailTemplate = async () => {
		dispatch(downloadTemplate({ channel: "whatsapp" }));
	};

	const Dragprops = {
		name: "file",
		multiple: false,
		accept: ".csv",
		customRequest: ({ file, onSuccess }) => {
			setTimeout(() => {
				onSuccess("ok");
			}, 0);
		},
		onChange: async (info) => {
			const { status } = info.file;
			if (status === "uploading") {
				let reader = new FileReader();
				reader.onload = (e) => {
					const readerResult = e.target.result;
					const jsonConverted = csv2JSON(readerResult);
					setUploadData([...jsonConverted]);
				};
				reader.readAsText(info.file.originFileObj);
			}
			if (status === "done") {
				console.log("uploadDatauploadData", uploadData);

				message.success(`${info.file.name} file uploaded successfully.`);
				setFileName(info.file.name);
			} else if (status === "error") {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	return (
		<Container>
			<Modal
				visible={previewOn}
				closable={true}
				footer={null}
				centered={true}
				onCancel={() => setPreviewOn(false)}
			>
				<div
					style={{
						width: "100%",
						height: "100%",
						padding: "1rem",
					}}
				>
					<img
						src={`https://kuber-dev.s3.amazonaws.com/${orgId}/resources/templates/${selectedForm}.png`}
						alt={"brokenformIcon"}
						style={{ width: "100%", objectFit: "contain" }}
					/>
				</div>
			</Modal>
			<TimelineWrapper>
				<Timeline
					style={{
						width: "80%",
						display: "flex",
						flexDirection: "column",
						padding: "2rem",
					}}
				>
					<Timeline.Item>
						<StepActionWrapper minHeight={"100px"}>
							<ActionHeader style={{ justifyContent: "space-between" }}>
								Click here to download the CSV template
								<Button type="dashed" onClick={downloadEmailTemplate}>
									{"Download WhatsApp Template"}
								</Button>
							</ActionHeader>
						</StepActionWrapper>
					</Timeline.Item>
					<Timeline.Item>
						<StepActionWrapper minHeight={"25vh"}>
							<ActionHeader>Upload the CSV document</ActionHeader>
							<Dragger
								disabled={uploadData.length > 0 ? true : false}
								{...Dragprops}
								accept=".csv"
								multiple={false}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag file to this area to upload
								</p>
								<p className="ant-upload-hint">
									Upload file should not be more than 2MB, only the .csv file
									types are allowed.
								</p>
							</Dragger>
							<span
								style={{
									display: `${fileName ? "block" : "none"}`,
									padding: "0.5em",
									fontWeight: 500,
									fontSize: "0.8em",
									textAlign: "left",
									textTransform: "capitalize",
								}}
							>
								<FileTextTwoTone style={{ marginRight: "0.5em" }} />
								{`${fileName}`}
								<CloseCircleTwoTone
									style={{ marginLeft: "1em", cursor: "pointer" }}
								></CloseCircleTwoTone>
							</span>
						</StepActionWrapper>
					</Timeline.Item>
					<Timeline.Item>
						<StepActionWrapper minHeight={"100px"}>
							<ActionHeader>Select The Form to send out</ActionHeader>
							<SelectedForm>
								{selectedForm ? (
									<PreviewForm>
										<img
											src={formItem && formItem.formHeader.formIcon}
											alt={"brokenformIcon"}
											style={{
												maxWidth: "120px",
												maxHeight: "120px",
												objectFit: "contain",
											}}
										/>
										<p
											style={{
												padding: "15px",
												fontWeight: 500,
												fontSize: "1rem",
											}}
										>
											{formItem && formItem.formName}
										</p>
										<Tooltip title={"Preview the Form"}>
											<p
												style={{
													padding: "10px",
													fontWeight: 600,
													fontSize: "1.5rem",
													cursor: "pointer",
												}}
											>
												<EyeTwoTone onClick={() => setPreviewOn(true)} />
											</p>
										</Tooltip>
									</PreviewForm>
								) : (
									<NoFormSelectedWrapper>
										<img src={NoFormSelected} alt={"brokenIcon"} />
										<p>Please select a form to be dispatched</p>
									</NoFormSelectedWrapper>
								)}
							</SelectedForm>
							<ButtonContainer display={selectedForm}>
								<Button type="dashed" style={{ width: "30%" }}>
									Cancel
								</Button>
								<Button
									type="primary"
									style={{ width: "30%", marginLeft: "1rem" }}
									onClick={sendBulkMsgs}
								>
									Send
								</Button>
							</ButtonContainer>
						</StepActionWrapper>
					</Timeline.Item>
				</Timeline>
			</TimelineWrapper>
			<FormSelector
				title={"Select Form to Dispatch"}
				setSelectedForm={setSelectedForm}
				selectedForm={selectedForm}
			></FormSelector>
		</Container>
	);
};

export default BulkDispatcher;
