import React from "react";
import styled from "styled-components";
import Rate from "../../form-management/common/ResponseTypes/Rate";
import Emoji from "../../form-management/common/ResponseTypes/Emoji";

const Container = styled.div`
	display: flex;
	flex: 1;
`;

const ContentBox = styled.div`
	display: flex;
	flex-direction: column;
	margin: 30px;
	flex: 1;
	border-radius: 5px;
	border: solid 1px #d1d9e5;
`;

const Card = styled.div`
	min-height: 100px;
	margin: 3px;
	display: flex;
	align-items: center;
`;

const QIndex = styled.span`
	padding: 25px;
	font-weight: 500;
`;

const ContentWrapper = styled.div`
	height: 100%;
	min-width: 100px;
	display: flex;
	flex-direction: column;
	padding: 0px 0px 0px 10px;
`;

const DisabledInput = styled.input`
	margin: 5px;
	width: 100%;
`;

const Query = styled.span`
	padding: 10px;
	text-align: left;
	font-weight: 500;
	:first-letter {
		text-transform: capitalize;
	}
`;

const ResponseWrap = styled.span`
	padding: 10px;
`;

const ContentsContainer = (props) => {
	const { form: contents } = props;

	const renderResponseType = (responseType, responseValue) => {
		switch (responseType) {
			case "text":
				return (
					<DisabledInput disabled={true} value={responseValue}></DisabledInput>
				);
			case "rate":
				return <Rate isDisabled={true} defaultRate={responseValue}></Rate>;

			case "emoji":
				return <Emoji disabled={true} selectedIndex={responseValue}></Emoji>;

			default:
				return null;
		}
	};

	return (
		<Container>
			<ContentBox>
				{contents &&
					contents.length > 0 &&
					contents.map((item, index) => {
						console.log(item);
						return (
							<Card>
								<QIndex>{`${index + 1}.`}</QIndex>
								{item.queryType === "icon" ? (
									<React.Fragment>
										<div>
											<img
												src={item.queryValue}
												width={80}
												height={80}
												alt={"brokenIcon"}
											/>
										</div>
										<ContentWrapper>
											<Query>{item.iconLabel}</Query>
											<ResponseWrap>
												{renderResponseType(
													item.responseType,
													item.responseValue
												)}
											</ResponseWrap>
										</ContentWrapper>
									</React.Fragment>
								) : (
									<ContentWrapper>
										<Query>{item.queryValue}</Query>
										<ResponseWrap>
											{renderResponseType(
												item.responseType,
												item.responseValue
											)}
										</ResponseWrap>
									</ContentWrapper>
								)}
							</Card>
						);
					})}
			</ContentBox>
		</Container>
	);
};

export default ContentsContainer;
