import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import {
	Breadcrumb,
	Form,
	Input,
	Select,
	Button,
	Avatar,
	Alert,
	Drawer,
	Table,
	Tooltip,
	Badge,
} from "antd";

import TextArea from "antd/lib/input/TextArea";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchOrgUsers,
	addNewGroup,
	fetchGroups,
	updateGroup,
} from "../../../store/slices/settingSlice";
import { getWorkspaces } from "../../../store/slices/environmentSlice";
import { EditFilled } from "@ant-design/icons";

const { Search } = Input;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #ebeff3;
`;
const TopSecondaryNav = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
	background-color: #f5f7f9;
	border-bottom: 1px #cfd7df solid;
`;

const TransparentBar = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	padding: 1em;
`;

const TableContainer = styled.div`
	display: flex;
	flex: 1;
	padding: 0.8em;
`;

const LabelWrapper = styled.label`
	padding: 1em 0px;
	display: flex;
`;

const onSearch = (value) => console.log(value);

const ManageGroups = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const defaultFormState = {
		groupName: "",
		description: "",
		userIds: [],
		workspaceIds: [],
		formState: "",
	};

	// const [usersIds, setNewUserIds] = useState();
	const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
	const [modalVisible, setModalVisible] = useState(false);
	const [groupInfo, setGroupInfo] = useState({ ...defaultFormState });
	const [errorAlert, seterrorAlert] = useState({
		isVisible: false,
		message: "",
	});

	const getUsers = useCallback(() => dispatch(fetchOrgUsers()), [dispatch]);
	useEffect(() => {
		getUsers();
	}, [getUsers]);

	useEffect(() => {
		dispatch(getWorkspaces());
		dispatch(fetchGroups());
	}, []);

	const { users, groups } = useSelector((state) => state.settings);
	const { workspaces } = useSelector((state) => state.environment);

	const columns = [
		{
			title: "S.No",
			dataIndex: "groupName",
			key: "groupName",
			width: "80px",
			render: (item, record, index) => <>{index + 1}</>,
		},
		{
			title: "Groups",
			dataIndex: "groupName",
			key: "groupName",
			render: (text) => <p style={{ fontSize: "12px" }}>{text}</p>,
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text) => <p style={{ fontSize: "12px" }}>{text}</p>,
		},
		{
			title: "No of Users",
			dataIndex: "userIds",
			key: "userIds",
			width: "15%",
			render: (tableUser) => {
				return (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Avatar.Group style={{ cursor: "pointer" }}>
							{tableUser &&
								tableUser.map((item) => {
									const singleUser =
										users && users.find((obj) => obj._id === item);
									let color =
										ColorList[Math.floor(Math.random() * ColorList.length)];
									return (
										<Tooltip title={singleUser && singleUser.name}>
											<Avatar style={{ backgroundColor: color }}>
												{singleUser && singleUser.email[0].toUpperCase()}
											</Avatar>
										</Tooltip>
									);
								})}
						</Avatar.Group>
						<Badge
							count={tableUser && tableUser.length}
							style={{ marginLeft: "0.5em", backgroundColor: "#2db7f5" }}
						></Badge>
					</div>
				);
			},
		},
		{
			title: "Assigned Workspaces",
			dataIndex: "assignedWorkspaces",
			key: "assignedWorkspaces",
			width: "15%",
			render: (assignedWorkspaces, record, index) => {
				return (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Avatar.Group style={{ cursor: "pointer" }}>
							{assignedWorkspaces &&
								assignedWorkspaces.map((item) => {
									const wkspace =
										workspaces && workspaces.find((obj) => obj._id === item);
									return (
										<Tooltip title={wkspace && wkspace.workspaceName}>
											<Avatar
												src={wkspace && wkspace.workspaceGravatar}
											></Avatar>
										</Tooltip>
									);
								})}
						</Avatar.Group>
						<Badge
							count={assignedWorkspaces && assignedWorkspaces.length}
							style={{ marginLeft: "0.5em", backgroundColor: "#2db7f5" }}
						></Badge>
					</div>
				);
			},
		},
		{
			title: "Action",
			dataIndex: "",
			key: "",
			width: "15%",
			align: "center",
			render: (record, index) => (
				<EditFilled
					style={{ fontSize: "1.5rem", cursor: "pointer" }}
					onClick={() => {
						setModalVisible(true);
						const groupInfomation = { ...record };
						console.log(
							"groupInfomationgroupInfomationgroupInfomation",
							JSON.stringify(record)
						);
						const { userIds, assignedWorkspaces } = groupInfomation;
						let updatedUserIds = [];
						for (let id = 0; id < userIds.length; id++) {
							for (let user = 0; user < users.length; user++) {
								if (userIds[id] === users[user]._id) {
									let mappedUser = {
										key: users[user]._id,
										label: users[user].email,
										value: users[user]._id,
									};
									updatedUserIds.push(mappedUser);
								}
							}
						}
						groupInfomation["userIds"] = updatedUserIds;

						if (assignedWorkspaces.length > 0) {
							let updatedWorkspaces = [];
							for (let id = 0; id < assignedWorkspaces.length; id++) {
								for (
									let workspace = 0;
									workspace < workspaces.length;
									workspace++
								) {
									if (assignedWorkspaces[id] === workspaces[workspace]._id) {
										let mappedWorkspace = {
											key: workspaces[workspace]._id,
											label: workspaces[workspace].workspaceName,
											value: workspaces[workspace]._id,
										};
										updatedWorkspaces.push(mappedWorkspace);
									}
								}
							}

							groupInfomation["workspaceIds"] = updatedWorkspaces;
						}

						// users

						setGroupInfo({
							...groupInfomation,
							formState: "Update",
							_id: record["_id"],
						});
					}}
				/>
			),
		},
	];

	const handleFormFieldChange = async (fieldKey, value) => {
		setGroupInfo({
			...groupInfo,
			[fieldKey]: value,
		});
	};

	const resetGroupState = async () => {
		setGroupInfo({ ...defaultFormState });
	};

	const onFinish = async (values) => {
		const { formState } = groupInfo;

		console.log("FormState", groupInfo);

		let workspaces =
			(groupInfo.workspaceIds &&
				groupInfo.workspaceIds.length &&
				groupInfo.workspaceIds.map((item) => item.value)) ||
			[];
		let users =
			(groupInfo.userIds &&
				groupInfo.userIds.length &&
				groupInfo.userIds.map((item) => item.value)) ||
			[];

		if (formState === "Create") {
			let updatedValues = {
				groupName: groupInfo.groupName,
				description: groupInfo.description,
				workspaceIds: workspaces,
				userIds: users,
			};

			dispatch(addNewGroup(updatedValues))
				.unwrap()
				.then((originalPromiseResult) => {
					setModalVisible(false);
					setGroupInfo({ ...defaultFormState });
					form.resetFields();
				})
				.catch((error) => {
					seterrorAlert({
						message: error.message,
						isVisible: true,
					});
				});
		} else if (formState === "Update") {
			let updatedValues = {
				_id: groupInfo._id,
				groupName: groupInfo.groupName,
				description: groupInfo.description,
				workspaceIds: workspaces,
				userIds: users,
			};
			console.log("Final Values", updatedValues);
			dispatch(updateGroup(updatedValues))
				.unwrap()
				.then((originalPromiseResult) => {
					setModalVisible(false);
					setGroupInfo({ ...defaultFormState });
					form.resetFields();
				})
				.catch((error) => {
					seterrorAlert({
						message: error.message,
						isVisible: true,
					});
				});
		}
	};

	console.log("groupInfogroupInfogroupInfo", groupInfo);

	return (
		<Container>
			<TopSecondaryNav>
				<Breadcrumb separator=">">
					<Breadcrumb.Item
						href=""
						onClick={() => {
							history.push("/teams");
						}}
					>
						Teams
					</Breadcrumb.Item>
					<Breadcrumb.Item>Groups</Breadcrumb.Item>
				</Breadcrumb>
			</TopSecondaryNav>
			<TransparentBar>
				<Button
					onClick={() => {
						setModalVisible(true);
						setGroupInfo({ ...defaultFormState, formState: "Create" });
					}}
				>
					New Group
				</Button>
				<Search
					placeholder="Search Groups"
					onSearch={onSearch}
					style={{ width: 200, marginRight: "1em" }}
				/>
			</TransparentBar>
			<TableContainer>
				<Drawer
					width={"30%"}
					title={`${
						groupInfo.formState === "Create"
							? "Create New Group"
							: "Update Existing Group"
					}`}
					visible={modalVisible}
					closable={true}
					onClose={() => {
						setModalVisible(false);
						resetGroupState();
					}}
					footer={null}
					getContainer={true}
				>
					<Form
						id="antd-form-resetter"
						layout="vertical"
						onFinish={onFinish}
						initialValues={{ ...groupInfo }}
					>
						<LabelWrapper> Group Name</LabelWrapper>
						<Input
							required={true}
							readOnly={groupInfo.formState === "Update" ? true : false}
							value={groupInfo.groupName}
							onChange={({ target }) => {
								handleFormFieldChange("groupName", target.value);
							}}
						/>
						<LabelWrapper> Description</LabelWrapper>
						<TextArea
							required={true}
							value={groupInfo.description}
							onChange={({ target }) => {
								handleFormFieldChange("description", target.value);
							}}
						></TextArea>
						<LabelWrapper>Add users to this group</LabelWrapper>
						<Select
							mode="multiple"
							allowClear
							placeholder="Add users to this group"
							labelInValue
							filterOption={false}
							optionLabelProp={"label"}
							onChange={(newValue) => {
								handleFormFieldChange("userIds", newValue);
							}}
							style={{ width: "100%" }}
							value={groupInfo.userIds}
						>
							{users &&
								users.map((item) => {
									let color =
										ColorList[Math.floor(Math.random() * ColorList.length)];
									return (
										<Select.Option label={item.email} value={item._id}>
											<div
												style={{
													display: "flex",
													padding: "0.5em",
													alignItems: "center",
												}}
											>
												<Avatar gap={4} style={{ backgroundColor: color }}>
													{item.email[0]}
												</Avatar>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														padding: "0.5em",
													}}
												>
													<span>{item.name}</span>
													<span>{item.email}</span>
												</div>
											</div>
										</Select.Option>
									);
								})}
						</Select>
						<LabelWrapper>Add Workspaces to this group</LabelWrapper>
						<Select
							mode="multiple"
							allowClear
							placeholder="Assign Workspaces"
							labelInValue
							optionLabelProp={"label"}
							filterOption={false}
							style={{ width: "100%" }}
							onChange={(newValue) => {
								handleFormFieldChange("workspaceIds", newValue);
							}}
							value={groupInfo.workspaceIds}
						>
							{workspaces &&
								workspaces.map((item) => {
									return (
										<Select.Option label={item.workspaceName} value={item._id}>
											<div
												style={{
													display: "flex",
													padding: "0.2em",
													alignItems: "center",
												}}
											>
												<Avatar src={item.workspaceGravatar}></Avatar>
												<span style={{ marginLeft: "0.8em" }}>
													{item.workspaceName}
												</span>
											</div>
										</Select.Option>
									);
								})}
						</Select>
						{/* </Form.Item> */}
						<Alert
							closable
							type="error"
							message={errorAlert.message}
							style={{ display: `${errorAlert.isVisible ? "block" : "none"}` }}
						></Alert>
						<Button
							htmlType="submit"
							type="dashed"
							block
							style={{ marginTop: "1em" }}
						>
							{groupInfo.formState === "Create"
								? "Create Group"
								: "Update Group"}
						</Button>
					</Form>
				</Drawer>
				<Table
					bordered
					columns={columns}
					dataSource={groups}
					pagination={false}
					scroll={{ y: "calc(100vh - 260px)" }}
					style={{ width: "100%" }}
				/>
			</TableContainer>
		</Container>
	);
};

export default ManageGroups;
