import { Alert, Button, Form } from "antd";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { ReviewfyTagLine } from "../../assets";
import { Input } from "antd";
import { updatePassword } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

const Container = styled.div`
	background-color: #8ec5fc;
	background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100vh;
`;

const LogoHolder = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1em;
	margin-bottom: 1em;
`;

const ActivateContainer = styled.div`
	width: 29%;
	height: auto;
	background: white;
	border-radius: 4px;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) and (max-width: 2900px) {
		width: 25%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Salutation = styled.h2`
	padding: 0.5em;
	text-align: left;
	::after {
		content: "${(props) => props.emailAddress}";
		display: block;
		font-size: 12px;
		color: #6c6c6d;
		font-weight: 500;
	}
`;

const LabelWrapper = styled.div`
	width: 100%;
	padding: 5px 5px 5px 0px;
	text-align: left;
	font-weight: 500;
	text-tranform: uppercase;
`;

const ResetPassword = () => {
	const [passwordState, setPasswordState] = useState(false);
	const [errMsg, setErrorMsg] = useState("");
	const searchResponse = useLocation().search;
	const userEmail = new URLSearchParams(searchResponse).get("email");
	const username = new URLSearchParams(searchResponse).get("username");

	const { orgId, userId } = useParams();

	const dispatch = useDispatch();

	const onFinish = async (values) => {
		try {
			const { password } = values;

			const response = await dispatch(
				updatePassword({ password, userId, orgId })
			).unwrap();
			if (response && response.code) {
				if (response.code === "PASSWORD_RESET_SUCCESS") {
					setPasswordState(true);
					setErrorMsg("");
				}
			}
		} catch (err) {
			setErrorMsg(err.message);
		}
	};

	return (
		<Fragment>
			<Alert
				type={"error"}
				message={errMsg}
				closable={true}
				showIcon={true}
				style={{ display: `${errMsg ? "block" : "none"}` }}
			></Alert>
			<Container>
				<LogoHolder>
					<img
						alt={""}
						src={ReviewfyTagLine}
						style={{ width: "15%", height: "100%" }}
					></img>
				</LogoHolder>

				{!passwordState ? (
					<ActivateContainer>
						<Salutation
							emailAddress={`${userEmail}`}
						>{`${username}`}</Salutation>

						<Wrapper>
							<Form onFinish={onFinish}>
								<LabelWrapper>Password</LabelWrapper>

								<Form.Item
									name="password"
									rules={[
										{
											required: true,
											message: "Please enter password!",
										},
										{
											min: 5,
											message: "Password must be atleast 5 characters",
										},
									]}
									hasFeedback
								>
									<Input.Password />
								</Form.Item>

								<LabelWrapper name="password">Confirm Password</LabelWrapper>
								<Form.Item
									name="confirm"
									dependencies={["password"]}
									hasFeedback
									rules={[
										{
											required: true,
											message: "Please confirm your password!",
										},
										{
											min: 5,
											message: "Password must be atleast 5 characters",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue("password") === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														"The two passwords that you entered do not match!"
													)
												);
											},
										}),
									]}
								>
									<Input.Password />
								</Form.Item>
								<Form.Item>
									<Button
										block
										htmlType={"submit"}
										style={{
											marginTop: "1em",
											backgroundColor: "#12344d",
											color: "white",
										}}
									>
										Update Your Password!
									</Button>
								</Form.Item>
							</Form>
						</Wrapper>
					</ActivateContainer>
				) : (
					<ActivateContainer>
						<h3> Your Password has been reset successfully!</h3>
						<Button
							block
							style={{
								marginTop: "1em",
								backgroundColor: "#12344d",
								color: "white",
							}}
							onClick={() => {
								window.location = "https://console.reviewfy.io/signin";
							}}
						>
							Login to your account now!
						</Button>
					</ActivateContainer>
				)}
			</Container>
		</Fragment>
	);
};

export default ResetPassword;
