import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import {
	DeleteIcon,
	PreviewIcon,
	FormIconAsset,
	Published,
	Minus,
	EditWrite,
	ReviewfyCoin,
} from "../../../../../assets/index";
import DeleteModal from "./DeleteForm";

const CardWrapper = styled.div`
	width: 100%;
	height: 260px;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	border: solid 1px #dee5f4;
	background-color: #ffffff;
	float: right;
	border-radius: 5px;
	position: relative;
	cursor: pointer;
	:hover {
		box-shadow: none;
		transition: 0.7s;
	}
`;

const FormIconWrapper = styled.div`
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const FormIcon = styled.img`
	width: 50%;
	height: 50%;
	padding: 5px;
	margin: 0px auto;
`;

const FormName = styled.div`
	width: 100%;
	padding: 10px;
	margin: 0px auto;
	font-weight: 600;
	font-size: 14px;
	font-weight: 600;
`;

const ActionBar = styled.div`
	height: 20%;
	position: absolute;
	right: 5px;
	left: 5px;
	bottom: 0px;
	border-top: solid 1px #dee5f4;
	padding: 5px;
`;

const ActionHolder = styled.div`
	width: 33%;
	padding: 3px;
	display: inline-block;
	border-right: ${(props) => (props.published ? "solid 1px #dee5f4" : "")};
	cursor: pointer;
	display: ${(props) => (props.published ? "none" : "inline-block")};
`;

const CreationTimeWrapper = styled.div`
	width: 100%;
	height: 20px;
	padding: 12px;
`;

const FormStatus = styled.span`
	float: left;
	font-size: 8px;
	color: #00be2c;
	height: 20px;
	display: inline-block;
`;

const CreationTime = styled.span`
	float: right;
	font-size: 8px;
	font-weight: 500;
	padding: 3px;
	color: #7b8eaf;
`;

const ActionIcon = styled.img`
	width: 25px;
	height: 25px;
`;

const TokenCount = styled.p`
	display: inline;
	padding: 0.5em;
	font-weight: 600;
`;

const FormListCard = (props) => {
	const formatCreationTime = (createdAt) => {
		let formattedDate = new Date(createdAt);
		let createdAtString = `${formattedDate.toDateString()}, ${formattedDate.getHours()}:${
			formattedDate.getMinutes() > 9
				? formattedDate.getMinutes()
				: "0" + formattedDate.getMinutes()
		}`;
		return createdAtString;
	};

	const {
		formName,
		createdAt,
		_id,
		published,
		formHeader,
		tokenValue,
		acceptResponses,
	} = props.contentItem;
	const { formIcon } = formHeader;
	const [isModalVisible, setIsModalVisible] = useState(false);

	const renderFormStatus = (published, acceptResponses) => {
		if (!acceptResponses) {
			return (
				<FormStatus published={published}>
					<img
						src={Minus}
						alt={""}
						width={"12px"}
						height={"12px"}
						style={{ marginRight: "2px" }}
					></img>
					<span style={{ color: "red" }}>{"Inactive"}</span>
				</FormStatus>
			);
		} else if (published) {
			return (
				<FormStatus published={published}>
					<img
						src={Published}
						alt={""}
						width={"12px"}
						height={"12px"}
						style={{ marginRight: "2px" }}
					></img>
					<span style={{ color: "green" }}>{"Published"}</span>
				</FormStatus>
			);
		} else {
			return (
				<FormStatus published={published}>
					<img
						src={EditWrite}
						alt={""}
						width={"12px"}
						height={"12px"}
						style={{ marginRight: "2px" }}
					></img>
					<span style={{ color: "orange" }}>{"Draft"}</span>
				</FormStatus>
			);
		}
	};

	return (
		<CardWrapper>
			<DeleteModal
				isVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				formId={_id}
			></DeleteModal>
			<CreationTimeWrapper>
				{renderFormStatus(published, acceptResponses)}
				<CreationTime>{formatCreationTime(createdAt)}</CreationTime>
			</CreationTimeWrapper>
			<FormIconWrapper>
				<FormIcon src={formIcon || FormIconAsset}></FormIcon>
				<FormName>{formName}</FormName>
			</FormIconWrapper>
			<ActionBar>
				<ActionHolder tokenValue={tokenValue}>
					<TokenCount>{tokenValue ? tokenValue : 0}</TokenCount>
					<ActionIcon src={ReviewfyCoin}></ActionIcon>
				</ActionHolder>
				<ActionHolder published={false}>
					<ActionIcon
						src={PreviewIcon}
						onClick={() => {
							props.setPreviewForm(true);
							props.setPreviewFormId(props.contentItem._id);
						}}
					></ActionIcon>
				</ActionHolder>
				<ActionHolder published={published} style={{ border: "0px" }}>
					<ActionIcon
						src={DeleteIcon}
						onClick={() => setIsModalVisible(true)}
						published={published}
					></ActionIcon>
				</ActionHolder>
			</ActionBar>
		</CardWrapper>
	);
};

export default withRouter(FormListCard);
