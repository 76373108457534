import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DatePicker, Select, Button, Spin, message } from "antd";
import moment from "moment";
import { fetchForms } from "../../../store/slices/formSlice";
import PageEmptyState from "../../common/PageEmptyState";
import { setFormMetrics } from "../../../store/slices/metricSlice";
import { pageLoaded } from "../../../store/slices/uiSlice";
import {
	DataCard,
	KPIAreaChart,
	FullPieChart,
	ContentsCard,
	SimpleBarChart,
} from "./common/index";
import {
	setContentAnalytics,
	clearContentAnalytics,
} from "../../../store/slices/metricSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { FormAnalysisEmptyState, NoDataFound } from "../../../assets/index";

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const { RangePicker } = DatePicker;
const { Option } = Select;

const Container = styled.div`
	height: calc(100vh - 100px);
	overflow-y: hidden;
`;

const FilterBar = styled.div`
	height: 60px;
	width: 100%;
	display: flex;
	padding: 1em;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.8em;
`;

const FilterComponent = styled.div``;

const AnalyticsContainer = styled.div`
	display: grid;
	height: calc(100vh - 160px);
	grid-template-rows: 1fr 2fr;
	padding: 1em;
	overflow-y: auto;
	background: #f5f6fa;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`;

const EmptyStateWrap = styled.div`
	width: 100%;
	height: calc(100vh - 120px);
`;

const ChartsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 1em;
	flex-grow: 1;
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgb(29 122 255 / 15%);
	background-color: #ffffff;
	flex: 1;
`;

const AIContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0 3px 16px 0 rgb(29 122 255 / 15%);
	background-color: #ffffff;
`;

const ContentAnalysis = styled.div`
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgb(29 122 255 / 15%);
	flex: 1;
	background: #ffffff;
`;

const ContentAnalysisContainer = styled.div`
	padding: 1em;
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-gap: 1em;
`;

const FormAnalytics = (props) => {
	const { socket } = props;
	const dispatch = useDispatch();
	const [dateRange, setDateRange] = useState([]);
	const [selectedForm, setSelectedForm] = useState("");
	const [selectedContent, setSelectedContent] = useState("");
	const { loading } = useSelector((state) => state.ui);

	const fetchFormList = useCallback(() => dispatch(fetchForms()), [dispatch]);
	const fetchFormAnalytics = useCallback(() => {
		socket.on("form-analytics", (data) => {
			dispatch(pageLoaded({ loading: false }));
			dispatch(setFormMetrics(data));
		});
	}, [dispatch, socket]);

	const fetchContentAnalytics = useCallback(() => {
		socket.on("content-analytics", (data) => {
			console.log("Data for content analytics;");
			dispatch(setContentAnalytics(data));
		});
	}, [dispatch, socket]);

	useEffect(() => {
		fetchFormList();
		fetchFormAnalytics();
		fetchContentAnalytics();
	}, [fetchFormList, fetchFormAnalytics, fetchContentAnalytics]);

	const { allForms } = useSelector((state) => state.forms);

	const handleFormSelection = (formId) => {
		// Set the page loading state to true
		dispatch(pageLoaded({ loading: true }));
		dispatch(clearContentAnalytics());
		setSelectedForm(formId);

		if (dateRange && dateRange.length) {
			socket.emit("form-analytics", { formId, dateRange });
		} else {
			const [startDate, endDate] = [
				moment("01-01-1970").startOf("day"),
				moment().endOf("day"),
			];
			const dateRange = { startDate, endDate };
			socket.emit("form-analytics", { formId, dateRange });
		}
	};

	const handleDateChange = () => {
		// Set the page loading state to true
		dispatch(pageLoaded({ loading: true }));
		if (!selectedForm) {
			message.error("Please select a form to apply the date range filter.");
			dispatch(pageLoaded({ loading: false }));
			return null;
		}
		// Set the daterange
		if (dateRange && dateRange.length) {
			let [startDate, endDate] = dateRange;
			startDate = startDate.startOf("day");
			endDate = endDate.endOf("day");
			let updatedDateRange = { startDate, endDate };
			socket.emit("form-analytics", {
				formId: selectedForm,
				dateRange: updatedDateRange,
			});
		} else {
			const [startDate, endDate] = [
				moment("01-01-1970").startOf("day"),
				moment().endOf("day"),
			];
			let updatedDateRange = { startDate, endDate };
			socket.emit("form-analytics", {
				formId: selectedForm,
				dateRange: updatedDateRange,
			});
		}
	};

	const handleContentSelect = (contentId, contentType) => {
		socket.emit("content-analytics", {
			formId: selectedForm,
			contentId: contentId,
			contentType: contentType,
		});
		setSelectedContent(contentId);
	};

	const { selectedFormPerformance, contentAnalytics } = useSelector(
		(state) => state.analytics
	);

	return (
		<Container>
			<FilterBar>
				<FilterComponent>
					<span style={{ padding: "1em" }}>Form</span>
					<Select
						style={{ minWidth: "250px" }}
						placeholder="Select a form to view insights"
						onChange={handleFormSelection}
					>
						{allForms &&
							allForms.map((item) => {
								return (
									<Option key={item._id} value={item._id}>
										{item.formName}
									</Option>
								);
							})}
					</Select>
				</FilterComponent>
				<FilterComponent>
					<span style={{ padding: "1em" }}>Select Date Range</span>
					<RangePicker
						ranges={{
							Today: [moment(), moment()],
							"This Month": [
								moment().startOf("month"),
								moment().endOf("month"),
							],
						}}
						showTime={false}
						onChange={(values) => values && setDateRange([...values])}
					/>
					<Button
						type={"dashed"}
						onClick={handleDateChange}
						disabled={dateRange.length < 1}
					>
						Apply
					</Button>
				</FilterComponent>
			</FilterBar>
			{loading ? (
				<Spin
					indicator={antIcon}
					style={{ position: "absolute", top: "50%", left: "50%" }}
				/>
			) : (
				<AnalyticsContainer>
					{selectedFormPerformance ? (
						<Fragment>
							<ChartsContainer>
								<DataCard
									formName={selectedFormPerformance.formName}
									formIcon={selectedFormPerformance.formIcon}
									tokenValue={selectedFormPerformance.tokenValue}
									createdAt={selectedFormPerformance.createdAt}
								/>
								<KPIAreaChart
									title={"TOTAL REQUESTS"}
									KPINumber={
										(selectedFormPerformance &&
											selectedFormPerformance["numberOfRequests"]) ||
										0
									}
									data={
										selectedFormPerformance &&
										selectedFormPerformance["numberOfRequests"]
									}
									showTrends={false}
								/>
								<KPIAreaChart
									title={"TOTAL RESPONSES"}
									KPINumber={
										(selectedFormPerformance &&
											selectedFormPerformance["numberOfResponses"]) ||
										0
									}
									data={
										selectedFormPerformance &&
										selectedFormPerformance["numberOfResponses"]
									}
									showTrends={false}
								></KPIAreaChart>
								<FullPieChart
									title={"FORMS SENT VIA DIFFERENT CHANNELS"}
									data={
										(selectedFormPerformance &&
											selectedFormPerformance.requestsByChannel) ||
										[]
									}
								></FullPieChart>
							</ChartsContainer>
							<ContentAnalysisContainer>
								<ContentContainer>
									{selectedFormPerformance &&
										selectedFormPerformance["contents"] &&
										selectedFormPerformance["contents"].map((item) => (
											<ContentsCard
												{...item}
												key={item._id}
												selectedId={selectedContent}
												handleContentSelect={handleContentSelect}
											></ContentsCard>
										))}
								</ContentContainer>
								<ContentAnalysis>
									{contentAnalytics ? (
										<SimpleBarChart
											title={"Response Share"}
											contentAnalytics={contentAnalytics || []}
										></SimpleBarChart>
									) : (
										<PageEmptyState
											srcImage={NoDataFound}
											message={"No data !"}
										/>
									)}
								</ContentAnalysis>
								<AIContainer>
									<PageEmptyState
										srcImage={NoDataFound}
										message={"Insufficient data points to generate trends."}
									></PageEmptyState>
								</AIContainer>
							</ContentAnalysisContainer>
						</Fragment>
					) : (
						<EmptyStateWrap>
							<PageEmptyState
								srcImage={FormAnalysisEmptyState}
								message={"Select a form to see insights"}
							></PageEmptyState>
						</EmptyStateWrap>
					)}
				</AnalyticsContainer>
			)}
		</Container>
	);
};

export default FormAnalytics;
