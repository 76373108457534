import React, { useState } from "react";
import FormDispatcher from "./FormDispatcher";
import EmailDispatcher from "./email/index";
import WhatsappDispatcher from "./whatsapp/index";
import GlobalLink from "./global/index";
import Discord from "./discord/index";
import Slack from "./slack/index";

export const FormDispatcherContext = React.createContext({
	dispatcherNavTab: "",
	dispatcherSubNav: "",
	setDispatcherNavTab: (arg) => {},
	setDispatcherSubNav: () => {},
});

const renderSelectedNavComponent = (dispatcherNavTab) => {
	if (dispatcherNavTab === "email") {
		return <EmailDispatcher></EmailDispatcher>;
	} else if (dispatcherNavTab === "whatsapp") {
		return <WhatsappDispatcher></WhatsappDispatcher>;
	} else if (dispatcherNavTab === "QR code-link") {
		return <GlobalLink></GlobalLink>;
	} else if (dispatcherNavTab === "discord") {
		return <Discord></Discord>;
	} else if (dispatcherNavTab === "slack") {
		return <Slack></Slack>;
	}
};

export default function FormDispatcherWrapper() {
	const [dispatcherNavTab, setDispatcherNavTab] = useState("email");

	return (
		<FormDispatcherContext.Provider
			value={{
				dispatcherNavTab,
				setDispatcherNavTab,
			}}
		>
			<FormDispatcher>
				{renderSelectedNavComponent(dispatcherNavTab)}
			</FormDispatcher>
		</FormDispatcherContext.Provider>
	);
}
