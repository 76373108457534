import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { notification, Tooltip } from "antd";
import ApprovedResponses from "./components/ApprovedList";
import UnApprovedResponses from "./components/UnApprovedList";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { Metamask } from "../../assets/index";
import { HomeOutlined } from "@ant-design/icons";

import { Tabs, Modal } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

const ReviewContainer = styled.div`
	height: 100vh;
`;

const ModalWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ModalSpan = styled.span`
	padding: 10px;
	text-align: center;
	font-size: ${(props) => props.fontSize}px;
	font-weight: 500;
`;

const ModalImage = styled.img`
	width: 50%;
	height: 50%;
	margin: 0px auto;
	object-fit: contain;
`;

const ReviewResponses = (props) => {
	const [metamaskPresent, setMetamaskPresent] = useState({
		isPresent: false,
		message: "",
	});
	const history = useHistory();

	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const navigateHome = () => {
		history.push(`/${selectedWorkspaceId}/home`);
	};

	const metaMaskNotConnected = () => {
		setMetamaskPresent({
			isPresent: false,
			message: (
				<>
					<p>{"Unable to connect to your metamask."}</p>
					<p>
						{
							"Install MetaMask or If you already have metamask check if your signed-in to metamask to distribute R5 tokens to your customers."
						}
					</p>
				</>
			),
		});
	};

	useEffect(() => {
		(async () => {
			try {
				const provider = await detectEthereumProvider();

				let web3 = new Web3(provider);
				web3.eth.getAccounts(function (err, accounts) {
					if (err != null) {
						console.error("An error occurred: " + err);
					} else if (accounts.length === 0) {
						console.log("User is not logged in to MetaMask");
						metaMaskNotConnected();
					} else {
						console.log("User is logged in to MetaMask");
						setMetamaskPresent({ isPresent: true });
					}
				});
				window.ethereum.on("accountsChanged", function (accounts) {
					// Time to reload your interface with accounts[0]!
					if (accounts && accounts.length && accounts[0]) {
						setMetamaskPresent({ isPresent: true, message: "" });
						notification.success({
							message: "Metamask Connected Successfully!",
							duration: 10,
							placement: "bottomRight",
						});
					} else {
						metaMaskNotConnected();
					}
				});
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (metamaskPresent.isPresent) {
				const provider = await detectEthereumProvider();
				let web3 = new Web3(provider);

				const networkMap = {
					POLYGON_MAINNET: {
						chainId: web3.utils.toHex(137), // '0x89'
						chainName: "Matic(Polygon) Mainnet",
						nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
						rpcUrls: ["https://polygon-rpc.com"],
						blockExplorerUrls: ["https://www.polygonscan.com/"],
					},
					MUMBAI_TESTNET: {
						chainId: web3.utils.toHex(80001), // '0x13881'
						chainName: "Matic(Polygon) Mumbai Testnet",
						nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
						rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
						blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
					},
				};

				await window.ethereum.request({
					method: "wallet_addEthereumChain",
					params: [networkMap.MUMBAI_TESTNET],
				});

				await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [
						{
							chainId: networkMap.MUMBAI_TESTNET.chainId,
						},
					],
				});
			}
		})();
	}, [metamaskPresent.isPresent]);

	return (
		<ReviewContainer>
			<Modal
				visible={!metamaskPresent.isPresent}
				footer={null}
				closeIcon={
					<Tooltip title={"Go to Home"}>
						<HomeOutlined />
					</Tooltip>
				}
				onCancel={navigateHome}
				centered
			>
				<ModalWrapper>
					<ModalImage src={Metamask}></ModalImage>
					<ModalSpan fontSize={24}>{"Metamask Not Found!"}</ModalSpan>
					<ModalSpan fontSize={"1.5vmin"}>{metamaskPresent.message}</ModalSpan>
				</ModalWrapper>
			</Modal>
			<Tabs defaultActiveKey="1" centered tabBarStyle={{ marginBottom: "0px" }}>
				<TabPane tab={"UN-APPROVED RESPONSES"} key="1">
					<UnApprovedResponses {...props}></UnApprovedResponses>
				</TabPane>
				<TabPane tab="APPROVED RESPONSES" key="2">
					<ApprovedResponses></ApprovedResponses>
				</TabPane>
			</Tabs>
		</ReviewContainer>
	);
};

export default ReviewResponses;
