import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FormIcon, NoFormsAvailable } from "../../../assets/index";
import { fetchForms } from "../../../store/slices/formSlice";

const Container = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border-radius: 12px;
	border: solid 1px #dee5f4;
	position: relative;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
	background: #ffffff;
`;

const HeadingText = styled.div`
	width: 100%;
	padding: 12px;
	font-weight: 600;
	text-align: left;
	color: var(--primaryColor);
	border-radius: 12px 12px 0px 0px;
	text-transform: uppercase;
	background-color: rgb(29, 189, 255, 0.1);
`;

const TemplateListBox = styled.div`
	width: 100%;
	height: calc(100vh - 200px);
	overflow-y: auto;
	display: grid;
	padding: 10px;
	grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
	grid-auto-rows: 180px;
	grid-gap: 20px;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		cursor: pointer;
	}
`;

const FormBox = styled.div`
	height: 180px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: solid 1px
		${(props) => (props.selected ? "var(--primaryColor)" : "#c4d2eb")};
	border-radius: 10px;
	cursor: ${(props) => (props.isActive ? "pointer" : "not-allowed")};
	position: relative;
`;

const FormBoxTint = styled.div`
	z-index: 1;
	cursor: ${(props) => (props.isActive ? "pointer" : "not-allowed")};
	display: ${(props) => (props.isActive ? "none" : "block")};
	background-color: ${(props) =>
		props.isActive ? "white" : "rgba(122, 122, 122, 0.5);"};
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 10px;
`;

const FormIconWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
`;

const FormIconImage = styled.img`
	object-fit: contain;
	max-height: 100px;
	max-width: 100px;
`;

const FormNameLabel = styled.div`
	width: 100%;
	background-color: #f8fbff;
	padding: 3px;
	border-radius: 0px 0px 10px 10px;
	font-size: 14px;
	font-weight: 500;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	word-wrap: break-word;
`;

const FormsWrapper = styled.div`
	width: 100%;
	height: calc(100vh - 180px);
`;

const NoFormsAvailableWrap = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const MessageText = styled.span`
	color: #6c80a3;
	font-weight: 500;
	padding: 15px;
	fontsize: 14px;
`;

const FormSelector = (props) => {
	const { title, setSelectedForm, selectedForm } = props;
	const { allForms } = useSelector((state) => state.forms);

	const dispatch = useDispatch();
	const fetchFormList = useCallback(() => dispatch(fetchForms()), [dispatch]);

	useEffect(() => {
		fetchFormList();
	}, [fetchFormList]);

	return (
		<Container>
			<HeadingText>{title}</HeadingText>
			<FormsWrapper>
				{allForms && allForms.length > 0 ? (
					<TemplateListBox>
						{allForms.map((item) => {
							const { formHeader } = item;
							return (
								<FormBox
									key={item._id}
									selected={item._id === selectedForm}
									onClick={() => {
										return item.acceptResponses
											? setSelectedForm(item._id)
											: "";
									}}
									isActive={item.acceptResponses}
								>
									<FormBoxTint isActive={item.acceptResponses} />
									<FormIconWrap>
										<FormIconImage
											src={formHeader.formIcon || FormIcon}
										></FormIconImage>
									</FormIconWrap>
									<FormNameLabel>{item.formName}</FormNameLabel>
								</FormBox>
							);
						})}
					</TemplateListBox>
				) : (
					<NoFormsAvailableWrap>
						<img
							alt={"No Forms Available"}
							src={NoFormsAvailable}
							height={"60%"}
						></img>
						<MessageText>
							{
								"You do not have any forms available. Please create forms to dispatch them"
							}
						</MessageText>
					</NoFormsAvailableWrap>
				)}
			</FormsWrapper>
		</Container>
	);
};

export default FormSelector;
