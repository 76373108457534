import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FullPieChart, KPIAreaChart, Areachart } from "./common/index";
import { DatePicker, Button, Spin, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setOVMetrics } from "../../../store/slices/metricSlice";
import moment from "moment";
import styled from "styled-components";
import { pageLoaded } from "../../../store/slices/uiSlice";

const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const Container = styled.div`
	height: calc(100vh - 100px);
	overflow-y: hidden;
`;

const FilterBar = styled.div`
	height: 60px;
	width: 100%;
	display: flex;
	padding: 1em;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.8em;
`;

const WidgetContainer = styled.div`
	display: grid;
	height: calc(100vh - 160px);
	grid-template-rows: 1fr 2fr;
	padding: 1em;
	overflow-y: auto;
	background: #f5f6fa;
`;

const DatePickerWrapper = styled.div``;

const RowContainer = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
`;

const ChartRows = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-wrap: wrap;
`;

// Component that show cases all the Analytics Overview.

const OverviewAnalytics = (props) => {
	const dispatch = useDispatch();
	const { socket } = props;
	const [dateRange, setDateRange] = useState([]);
	const { overallPerformance } = useSelector((state) => state.analytics);
	const { loading } = useSelector((state) => state.ui);
	const { selectedWorkspaceId, workspaces } = useSelector(
		(state) => state.environment
	);
	const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

	const fetchOverviewAnalytics = useCallback(() => {
		socket.on("overview-analytics", (data) => {
			dispatch(pageLoaded({ loading: false }));
			dispatch(setOVMetrics(data));
		});
	}, [dispatch, socket]);

	useEffect(() => {
		const [startDate, endDate] = [
			moment("01-01-1970").startOf("day"),
			moment().endOf("day"),
		];
		dispatch(pageLoaded({ loading: true }));
		socket.emit("overview-analytics", {
			startDate,
			endDate,
			workspaceIds: [selectedWorkspaceId],
		});
		fetchOverviewAnalytics();
		if (selectedWorkspaceId) {
			setSelectedWorkspaces([selectedWorkspaceId]);
		}
	}, [dispatch, socket, fetchOverviewAnalytics, selectedWorkspaceId]);

	const handleDateChange = () => {
		dispatch(pageLoaded({ loading: true }));
		if (dateRange && dateRange.length) {
			console.log("I am inside");
			let [startDate, endDate] = dateRange;
			startDate = startDate.startOf("day");
			endDate = endDate.endOf("day");
			socket.emit("overview-analytics", { startDate, endDate });
		} else {
			const [startDate, endDate] = [
				moment("01-01-1970").startOf("day"),
				moment().endOf("day"),
			];

			socket.emit("overview-analytics", { startDate, endDate });
		}
	};

	const fetchMetricsForWorkspaces = () => {
		dispatch(pageLoaded({ loading: true }));
		const [startDate, endDate] = [
			moment("01-01-1970").startOf("day"),
			moment().endOf("day"),
		];
		socket.emit("overview-analytics", {
			startDate,
			endDate,
			workspaceIds: [...selectedWorkspaces],
		});
	};

	return (
		<Container>
			<FilterBar>
				<div
					style={{
						minWidth: "20%",
						maxWidth: "70%",
						display: "flex",
						alignItems: "center",
					}}
				>
					<div style={{ width: "200px" }}>{"Overview of :"}</div>
					<Select
						mode={"multiple"}
						allowClear
						filterOption={false}
						style={{ width: "100%", height: "100%" }}
						value={[...selectedWorkspaces]}
						defaultValue={[selectedWorkspaceId]}
						onChange={(values) => {
							if (values.length === 0) {
								setSelectedWorkspaces([selectedWorkspaceId]);
							} else {
								setSelectedWorkspaces(values);
							}
						}}
					>
						{workspaces &&
							workspaces.map((item) => {
								return (
									<Select.Option value={item._id} key={item._id}>
										{item.workspaceName}
									</Select.Option>
								);
							})}
					</Select>
					<Button onClick={fetchMetricsForWorkspaces} type="dashed">
						Apply
					</Button>
				</div>
				<DatePickerWrapper>
					<span style={{ padding: "1em" }}>Select Date Range</span>
					<RangePicker
						ranges={{
							Today: [moment().startOf("day"), moment().endOf("day")],
							"This Month": [
								moment().startOf("month"),
								moment().endOf("month"),
							],
							"All Time": [
								moment("01-01-1970").startOf("day"),
								moment().endOf("day"),
							],
						}}
						showTime={false}
						onChange={(values) => values && setDateRange([...values])}
						allowClear={false}
					/>
					<Button
						type={"dashed"}
						onClick={handleDateChange}
						disabled={dateRange && dateRange.length < 1}
					>
						Apply
					</Button>
				</DatePickerWrapper>
			</FilterBar>
			<WidgetContainer>
				{loading ? (
					<Spin
						indicator={antIcon}
						style={{ position: "absolute", top: "50%", left: "50%" }}
					/>
				) : (
					<React.Fragment>
						<RowContainer color={"white"}>
							<KPIAreaChart
								title={"NUMBER OF TOKENS ISSUED"}
								width={"16%"}
								KPINumber={
									(overallPerformance &&
										overallPerformance["tokensIssued"]) ||
									0
								}
								data={
									overallPerformance && overallPerformance["responseTrends"]
								}
								showTrends={false}
							/>
							<KPIAreaChart
								title={"NUMBER OF REQUESTS"}
								width={"16%"}
								KPINumber={
									(overallPerformance &&
										overallPerformance["numberOfRequests"]) ||
									0
								}
								data={overallPerformance && overallPerformance["requestTrends"]}
								showTrends={true}
							/>
							<KPIAreaChart
								title={"NUMBER OF RESPONSES"}
								width={"16%"}
								KPINumber={
									(overallPerformance &&
										overallPerformance["numberOfResponses"]) ||
									0
								}
								data={
									overallPerformance && overallPerformance["responseTrends"]
								}
								showTrends={true}
							/>

							<FullPieChart
								title={"RESPONSES RECEIVED VIA DIFFERENT CHANNELS"}
								data={
									(overallPerformance &&
										overallPerformance.responsesByChannel) ||
									[]
								}
							></FullPieChart>
						</RowContainer>

						<ChartRows color={"pink"}>
							<Areachart
								title={"REQUEST AND RESPONSE TRENDS"}
								data={
									(overallPerformance && overallPerformance.monthlyTrends) || []
								}
							></Areachart>
						</ChartRows>
					</React.Fragment>
				)}
			</WidgetContainer>
		</Container>
	);
};

export default OverviewAnalytics;
