import React from "react";
import styled from "styled-components";
import moment from "moment";
import { ReviewfyLogo } from "../../../../assets/index";

const DataCardContainer = styled.div`
	min-width: 30%;
	display: flex;
	justify-content: flex-start;
	border: 0.1 solid;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	border-radius: 10px;
	margin: 0.5em;
	background: #ffffff;
`;

const SpanLabel = styled.div`
	width: 100%;
	font-size: 10px;
	padding: 0.5em;
	text-transform: uppercase;
`;

const SpanValue = styled.span`
	width: 100%;
	font-size: 1em;
	padding: 0.5em;
	font-weight: 600;
	color: var(--primaryColor);
	text-transform: uppercase;
`;

const SpanWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const CardItem = styled.div`
	width: 100%;
	height: 50%;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Datacard = ({ formName, createdAt, formIcon, tokenValue }) => {
	return (
		<DataCardContainer>
			<SpanWrapper>
				<CardItem>
					<SpanLabel>FormName</SpanLabel>
					<img src={formIcon} alt={"No Form Icon"} width={30} height={30} />
					<SpanValue>{formName}</SpanValue>
				</CardItem>
				<CardItem>
					<SpanLabel>Language</SpanLabel>
					<SpanValue>{"English"}</SpanValue>
				</CardItem>
			</SpanWrapper>
			<SpanWrapper>
				<CardItem>
					<SpanLabel>Token Value</SpanLabel>
					<img src={ReviewfyLogo} alt={"No Form Icon"} width={30} height={30} />
					<SpanValue>{tokenValue}</SpanValue>
				</CardItem>
				<CardItem>
					<SpanLabel>Created At</SpanLabel>
					<SpanValue>
						{moment(createdAt).format("dddd, MMMM Do YYYY")}
					</SpanValue>
				</CardItem>
			</SpanWrapper>
		</DataCardContainer>
	);
};

export default Datacard;
