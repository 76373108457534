import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { fetchContents } from "../../../../../store/slices/contentSlice";
import QuestionCard from "../DragAndDrop/QuestionCard";

const ContentListContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	position: relative;
	border-radius: 5px;
	background-color: #f8fbff;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	diplay: flex;
	flex-direction: column;
	overflow: hidden;
`;

const ContentListHeaderBar = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	box-shadow: 0 3px 6px 0 rgba(222, 229, 244, 0.45);
	background-color: #ffffff;
`;

const ContentListHeader = styled.span`
	height: 100%;
	text-transform: uppercase;
	font-weight: 600;
	text-align: left;
	color: var(--primaryColor);
	display: flex;
	align-items: center;
	padding: 1rem;
`;

const ContentListGrid = styled.div`
	height: 90%;
	display: grid;
	grid-template-columns: repeat(2, minmax(50%, 50%));
	grid-template-rows: repeat(auto-fill, minmax(100px, 100px));
	grid-gap: 0.5em;
	justify-content: space-evenly;
	padding: 0.5em;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 0px;
	}
`;

const ContentList = () => {
	const dispatch = useDispatch();

	const { contentList } = useSelector((state) => state.contents);

	const fetchContentList = useCallback(
		() => dispatch(fetchContents()),
		[dispatch]
	);

	useEffect(() => {
		fetchContentList();
	}, [fetchContentList]);

	return (
		<ContentListContainer>
			<ContentListHeaderBar>
				<ContentListHeader>Questions</ContentListHeader>
			</ContentListHeaderBar>
			<ContentListGrid>
				{contentList &&
					contentList.map((item) => {
						if (item.queryType === "icon") {
							console.log(item);
							return (
								<QuestionCard
									contentItem={item}
									queryValue={item.iconLabel}
								></QuestionCard>
							);
						} else {
							return (
								<QuestionCard
									contentItem={item}
									queryValue={item.queryValue}
								></QuestionCard>
							);
						}
					})}
			</ContentListGrid>
		</ContentListContainer>
	);
};

export default ContentList;
