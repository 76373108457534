import FormManagement from "./FormManagement";
import React, { useState } from "react";
import ContentsMain from "./contents/Main";
import FormsMain from "./forms/Main";
import AddNewForm from "./forms/components/FormCreation/CreateForm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const FormManagementContext = React.createContext({
	selectedFormNavTab: "",
	selectedSubNav: "",
	setSelectedFormNavTab: (arg) => {},
	setSelectedSubNav: () => {},
});

const renderSelectedNavComponent = (selectedFormNavTab, selectedSubNav) => {
	if (
		selectedFormNavTab === "contents" &&
		selectedSubNav === "displayContents"
	) {
		return <ContentsMain></ContentsMain>;
	} else if (
		selectedFormNavTab === "forms" &&
		selectedSubNav === "displayForms"
	) {
		return <FormsMain></FormsMain>;
	} else if (selectedFormNavTab === "forms" && selectedSubNav === "addForms") {
		return <AddNewForm></AddNewForm>;
	}
};

export default function FormManagementWrapper() {
	const [selectedFormNavTab, setSelectedFormNavTab] = useState("contents");
	const [selectedSubNav, setSelectedSubNav] = useState("displayContents");

	return (
		<FormManagementContext.Provider
			value={{
				selectedFormNavTab,
				setSelectedFormNavTab,
				selectedSubNav,
				setSelectedSubNav,
			}}
		>
			<DndProvider backend={HTML5Backend}>
				<FormManagement>
					{renderSelectedNavComponent(selectedFormNavTab, selectedSubNav)}
				</FormManagement>
			</DndProvider>
		</FormManagementContext.Provider>
	);
}
