import React from "react";
import styled from "styled-components";
import { TeamOutlined, UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Container = styled.div`
	height: calc(100vh -60px);
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const TopSecondaryNav = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
	background-color: #f5f7f9;
	border-bottom: 1px #cfd7df solid;
`;

const SectionContainer = styled.div`
	display: flex;
	flex: 1;
	background-color: #ebeff3;
`;

const CardContainer = styled.div`
	width: 20%;
	height: 90px;
	display: flex;
	background-color: white;
	margin: 1em;
	border-radius: 4px;
	cursor: pointer;
	:hover {
		box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
		transition: 0.2s;
	}
`;

const Description = styled.p`
	width: 100%;
	font-size: 0.8em;
	color: #475867;
	padding: 0px 0.5em 0px 0.5em;
`;

const Teams = () => {
	const history = useHistory();

	const teamItems = [
		{
			iconElement: (
				<UserAddOutlined
					style={{ fontSize: "1.5em", padding: "0.5em" }}
				></UserAddOutlined>
			),
			itemId: "users",
			itemName: "People",
			description: " Invite users to collaborate on workspaces.",
			navigateTo: "/teams/users",
		},
		{
			iconElement: (
				<TeamOutlined
					style={{ fontSize: "1.5em", padding: "0.5em" }}
				></TeamOutlined>
			),
			itemId: "groups",
			itemName: "Groups",
			description: " Organise users into groups to manage levels of access",
			navigateTo: "/teams/groups",
		},
	];

	return (
		<Container>
			<TopSecondaryNav>{"Teams"}</TopSecondaryNav>
			<SectionContainer>
				{teamItems.map((item) => {
					return (
						<CardContainer onClick={() => history.push(item.navigateTo)}>
							<span
								style={{
									padding: "0px 0.5em 0px 0.5em",
									display: "flex",
									alignItems: "center",
								}}
							>
								{item.iconElement}
							</span>

							<div
								style={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									textAlign: "left",
									justifyContent: "center",
									padding: "6px",
								}}
							>
								<span
									style={{
										width: "100%",
										fontWeight: 500,
										padding: "0px 0.5em",
									}}
								>
									{item.itemName}
								</span>
								<Description>{item.description}</Description>
							</div>
						</CardContainer>
					);
				})}
			</SectionContainer>
		</Container>
	);
};

export default Teams;
