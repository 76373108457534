import React from "react";
import styled from "styled-components";
import { Space, Tag } from "antd";

const Container = styled.div`
	height: calc(100vh - 60px);
	width: calc(100vw - 65px);
	display: flex;
	flex-direction: column;
`;

const ContextContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 4px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
`;

const Header = styled.div`
	text-align: left;
	padding: 0.5em;
	font-weight: 500;
`;

const StyledParagraph = styled.p`
	font-weight: 500;
	font-size: 1em;
	text-align: left;
	padding: 0.5em;
	line-height: 1.6;
`;

const BillingContent = styled.div`
	flex: 1;
	background: #f5f6fa;
	display: flex;
	justify-content: center;
	padding: 1em;
`;

const SubscriptionCard = styled.div`
	background: #ffffff;
	min-width: 50%;
	height: 35%;
	margin: 2em;
	border-radius: 2px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
`;

const HeaderItem = styled.div`
	padding: 0.5em;
	min-height: 40%;
	display: flex;
`;

const ChildItem = styled.div`
	background: #f5f6fa;
	padding: 1em;
	margin-bottom: 0.5em;
	font-weight: 500;
	height: 20%;
	display: flex;
	align-items: center;
`;

const Billing = () => {
	return (
		<Container>
			<ContextContainer>
				<Header>{"SUBSCRIPTION SETTINGS"}</Header>{" "}
				<StyledParagraph>
					We’re so glad that you’ve decided to explore our product and believe
					us. As a gratitude to your trust in us, We offer you Reviewfy.io
					absolutely free for the first 6 months of you signing up with us.
				</StyledParagraph>
			</ContextContainer>
			<BillingContent>
				<SubscriptionCard>
					<HeaderItem>
						<div
							style={{
								width: "90%",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "flex-start",
								fontSize: "1.5em",
								fontWeight: 600,
								padding: "0.5em",
							}}
						>
							<p>{"Premium 	\t"}</p>
						</div>
						<div>
							<Space>
								<Tag color={"green"}>{"SUBSCRIBED"}</Tag>
							</Space>
						</div>
					</HeaderItem>
					<ChildItem>{`Your subscription is free for the next 6 months`}</ChildItem>
				</SubscriptionCard>
			</BillingContent>
		</Container>
	);
};

export default Billing;
