import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
	Modal,
	Table,
	Tag,
	Space,
	Form,
	Input,
	Select,
	Button,
	Avatar,
	Alert,
} from "antd";
import { SearchIcon } from "../../../../assets/index";
import {
	fetchOrgUsers,
	deleteUser,
	saveUser,
} from "../../../../store/slices/settingSlice";
import { formatDate } from "../../../../helpers/utils";
import { DeleteIcon } from "../../../../assets/index";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
	height: calc(100vh - 60px);
	background: #f5f6fa;
`;

const FilterBar = styled.div`
	width: calc(100vw - 80px);
	height: 60px;
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	font-weight: 600;
`;

const AddUserButton = styled.div`
	width: 200px;
	height: 45px;
	padding: 10px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 600;
	box-shadow: 0 10px 20px 0 rgb(168 171 189 / 50%);
	border: solid 1px var(--primaryColor);
	background-color: var(--primaryColor);
`;

const SearchBox = styled.div`
	background-color: #f8fbff;
	float: right;
	display: inline;
	height: 45px;
	width: auto;
	border-radius: 5px;
	padding: 10px;
	margin-right: 10px;
`;

const SearchInput = styled.input`
	background-color: #f8fbff;
	border: none;
	:focus {
		outline: none;
	}
`;

const TableContainer = styled.div`
	height: calc(100vh - 120px);
	padding: 2rem;
	display: flex;
`;

const UserManagement = () => {
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const [errorAlert, seterrorAlert] = useState({
		isVisible: false,
		message: "",
	});
	const { users } = useSelector((state) => state.settings);

	const handleUserDelete = ({ _id }) => {
		dispatch(deleteUser({ userId: _id }));
	};

	const getUserEmail = () => {
		try {
			const { email } = JSON.parse(localStorage.getItem("authInfo"));
			return email;
		} catch (err) {
			return null;
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text) => (
				<>
					<Space>
						<Avatar>{text[0].toUpperCase()}</Avatar>
						<a href="/settings/people">{text}</a>
					</Space>
				</>
			),
		},
		{
			title: "Email Address",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Email Verified",
			dataIndex: "emailVerified",
			key: "emailVerified",
			render: (emailVerified) => (
				<>
					{emailVerified ? (
						<Tag color={"green"}>{"VERIFIED"}</Tag>
					) : (
						<Tag color={"red"}>{"NOT VERIFIED"}</Tag>
					)}
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (createdAt) => <p>{formatDate(createdAt)}</p>,
		},
		{
			title: "Role",
			key: "roles",
			dataIndex: "roles",
			render: (tags) => (
				<>
					{tags &&
						tags.map((tag) => {
							let color = tag.length > 5 ? "blue" : "blue";
							if (tag === "loser") {
								color = "volcano";
							}
							return (
								<Tag color={color} key={tag}>
									{tag.toUpperCase()}
								</Tag>
							);
						})}
				</>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Space
					size="middle"
					style={{
						display: `${record.email !== getUserEmail() ? "block" : "none"}`,
						cursor: "pointer",
					}}
				>
					<img
						src={DeleteIcon}
						onClick={() => handleUserDelete(record)}
						alt={"brokenImg"}
					/>
				</Space>
			),
		},
	];

	const onFinish = async (values) => {
		dispatch(saveUser(values))
			.unwrap()
			.then((originalPromiseResult) => {
				setModalVisible(false);
			})
			.catch((error) => {
				seterrorAlert({
					message: error.message,
					isVisible: true,
				});
			});
	};

	const getUsers = useCallback(() => dispatch(fetchOrgUsers()), [dispatch]);
	useEffect(() => {
		getUsers();
	}, [getUsers]);

	return (
		<Container>
			<Modal
				title={"Add User"}
				centered
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				onOk={() => onFinish()}
				footer={null}
			>
				<Form layout="vertical" onFinish={onFinish}>
					<Form.Item
						name={"name"}
						label="Enter Name"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={"email"}
						label="Enter Email Address"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={"password"}
						label="Enter Password"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input.Password />
					</Form.Item>
					<Form.Item
						name={"role"}
						label="Select Role"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select showArrow>
							<Select.Option value="admin">Admin</Select.Option>
							<Select.Option value="user">User</Select.Option>
						</Select>
					</Form.Item>
					<Alert
						closable
						type="error"
						message={errorAlert.message}
						style={{ display: `${errorAlert.isVisible ? "block" : "none"}` }}
					></Alert>
					<Button
						htmlType="submit"
						type="dashed"
						block
						style={{ marginTop: "1em" }}
					>
						Create User
					</Button>
				</Form>
			</Modal>
			<FilterBar>
				<p style={{ padding: "1em" }}>Console User Management</p>
				<div style={{ display: "flex" }}>
					<SearchBox>
						<SearchInput placeholder="Search Users"></SearchInput>
						<img src={SearchIcon} alt={""}></img>
					</SearchBox>
					<AddUserButton onClick={() => setModalVisible(true)}>
						Add User
					</AddUserButton>
				</div>
			</FilterBar>
			<TableContainer>
				<Table
					bordered
					columns={columns}
					dataSource={users}
					pagination={false}
					style={{ width: "100%" }}
				/>
			</TableContainer>
		</Container>
	);
};

export default UserManagement;
