import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import ContentEmptyState from "../../../assets/images/ContentEmptyState.svg";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../contents/components/ContentBox";
import PageEmptyState from "../../common/PageEmptyState";
import TopNavBar from "../common/TopNavBar";
import { fetchContents } from "../../../store/slices/contentSlice";
import CreateContentPane from "./components/CreateContentPane";
import { MagicWand } from "../../../assets/index";

const ContentsContainer = styled.div`
	background-color: #f5f6fa;
	height: calc(100vh - 60px);
	display: flex;
	flex-basis: 0;
	flex: 1;
	flex-flow: row wrap;
`;

const ContentItemContainer = styled.div`
	width: 100%;
	height: calc(100vh - 60px - 70px);
	padding: 10px;
	position: relative;
	overflow-y: auto;
`;

const ContentOverflowWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 250px));
	grid-gap: 30px;
	padding: 10px;
`;

const AddNewContent = styled.div`
	width: 100%;
	height: 230px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	cursor: pointer;
	background-color: white;
	border: solid 2px var(--primaryColor);
	border-radius: 0.625em;

	:hover {
		box-shadow: none;
		transition: 0.7s;
	}
	padding: 1.375em;
`;

const StyledButton = styled.div`
	background: green;
	padding: 1em;
	width: 100%;
	height: 45px;
	font-weight: 500;
	justify-content: space-evenly;
	margin: 1em 0px;
	border-radius: 0.5em;
	border: solid 1px var(--primaryColor);
	background-color: ${(props) =>
		props.primary ? "var(--primaryColor)" : "white"};
	color: ${(props) => (props.primary ? "white" : "black")};
`;

const ContentsMain = () => {
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState(1);
	const [showContentPane, setShowContentPane] = useState(false);

	const fetchContentList = useCallback(
		() => dispatch(fetchContents()),
		[dispatch]
	);

	useEffect(() => {
		fetchContentList();
	}, [fetchContentList]);

	const { contentList } = useSelector((state) => state.contents);

	const filterContentList = () => {
		return contentList.filter((value) => {
			if (value.queryType === "icon") {
				if (value.iconLabel.toLowerCase().includes(searchText.toLowerCase()))
					return value;
			} else if (value.queryType === "text") {
				if (value.queryValue.toLowerCase().includes(searchText.toLowerCase()))
					return value;
			}
		});
	};

	return (
		<ContentsContainer>
			<TopNavBar
				tabName={"Question Library"}
				setSearchText={setSearchText}
				navPath={"addContents"}
				secondaryNavPath={"displayAIContents"}
			/>
			<CreateContentPane
				isOpen={showContentPane}
				changePane={setShowContentPane}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			/>
			<ContentItemContainer>
				{contentList && contentList.length === 0 ? (
					<PageEmptyState
						srcImage={ContentEmptyState}
						message={"Create Your First Question"}
						onClick={() => {
							setShowContentPane(true);
							setActiveTab(0);
						}}
					></PageEmptyState>
				) : (
					<ContentOverflowWrapper>
						<AddNewContent>
							<p>Create A Question</p>
							<StyledButton
								onClick={() => {
									setShowContentPane(true);
									setActiveTab(0);
								}}
							>
								<span>CREATE MANUALLY</span>
							</StyledButton>
							<StyledButton
								primary={true}
								onClick={() => {
									setShowContentPane(true);
									setActiveTab(1);
								}}
							>
								<img
									alt={""}
									src={MagicWand}
									style={{ marginRight: "1em" }}
								></img>
								<span>ASK AI</span>
							</StyledButton>
						</AddNewContent>
						{filterContentList().map((item, index) => {
							return <ContentBox key={index} contentItem={item}></ContentBox>;
						})}
					</ContentOverflowWrapper>
				)}
			</ContentItemContainer>
		</ContentsContainer>
	);
};

export default ContentsMain;
