import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataURLtoFile } from "../../helpers/utils";
import { pageLoaded } from "./uiSlice";
import axios from "axios";
import { notification } from "antd";

const initialState = {
	contentList: [],
	iconList: [],
	aiIcons: [],
};

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

function getBearerToken() {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
}

export const fetchContents = createAsyncThunk(
	"contents/fetchContents",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;

		const response = await axios.get(
			`${apiEndPoint}/api/contents?workspaceId=${selectedWorkspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const fetchContentIcons = createAsyncThunk(
	"content/fetchIcons",
	async () => {
		const response = await axios.get(`${apiEndPoint}/api/contents/icons/`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		return response.data;
	}
);

export const createContent = createAsyncThunk(
	"content/createContent",
	async (contentValues, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/contents/`,
			contentValues,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const contentDeleted = createAsyncThunk(
	"content/contentDeleted",
	async (contentId, ThunkAPI) => {
		await axios.delete(`${apiEndPoint}/api/contents/${contentId}`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		return contentId;
	}
);

export const uploadCustomIcon = createAsyncThunk(
	"content/uploadedCustomIcon",
	async ({ bodyFormData, filename }, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const file = dataURLtoFile(bodyFormData, filename);
		const data = new FormData();
		data.append("template", file, file.name);
		const response = await axios.post(
			`${apiEndPoint}/api/contents/uploadIcons`,
			data,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
					"Content-Type": "multipart/form-data",
				},
			}
		);
		notification.success({ message: "Custom Icon Uploaded Successfully" });
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const getGenerativeContents = createAsyncThunk(
	"contents/getGenerativeContents",
	async (payload, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(
				`${apiEndPoint}/api/generative/get-suggestions`,
				payload
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return response.data;
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
		}
	}
);

export const getIconsforQuestion = createAsyncThunk(
	"contents/getGenerativeIcons",
	async (payload, ThunkAPI) => {
		try {
			ThunkAPI.dispatch(pageLoaded({ loading: true }));
			const response = await axios.post(
				`${apiEndPoint}/api/generative/get-icons-by-keywords`,
				payload
			);
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
			return response.data;
		} catch (err) {
			ThunkAPI.dispatch(pageLoaded({ loading: false }));
		}
	}
);

export const deleteCustomIcon = createAsyncThunk(
	"contents/deleteCustomIcon",
	async (iconId, ThunkAPI) => {
		await axios.delete(`${apiEndPoint}/api/contents/deleteIcon/${iconId}`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		return iconId;
	}
);

const contentSlice = createSlice({
	name: "contents",
	initialState,
	reducers: {
		contentRemoved: (state, action) => {
			const updatedContents = state.contentList.filter(
				(content) => content._id !== action.payload._id
			);
			state.contentList = updatedContents;
		},
		contentAdded: (state, action) => {
			state.contentList.push(action.payload);
		},
	},
	extraReducers: {
		[fetchContents.fulfilled]: (state, action) => {
			const { contents } = action.payload;
			state.contentList = [...contents];
		},

		[fetchContentIcons.fulfilled]: (state, action) => {
			const { contentIcons } = action.payload;
			state.iconList = contentIcons;
		},
		[createContent.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			state.contentList.push(payload.content);
		},
		[contentDeleted.fulfilled]: (state, action) => {
			const contentId = action.payload;
			const updatedState = state.contentList.filter(
				(content) => content._id !== contentId
			);
			state.contentList = updatedState;
		},
		[uploadCustomIcon.fulfilled]: (state, action) => {
			state.iconList.push(action.payload);
		},
		[deleteCustomIcon.fulfilled]: (state, action) => {
			const iconId = action.payload;
			const updatedState = state.iconList.filter((icon) => icon._id !== iconId);
			state.iconList = updatedState;
		},
		[getIconsforQuestion.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			const { icons } = payload;
			console.log(icons);
			const updatedIcons = icons.map((item) => {
				return {
					...item,
					iconURL: item["images"]["64"],
					_id: item["id"],
					tag: item["aiTag"],
				};
			});
			state.iconList = [...updatedIcons];
		},
	},
});

export const { contentAdded, contentRemoved } = contentSlice.actions;
export default contentSlice.reducer;
