// eslint-disable-next-line react-hooks/exhaustive-deps
import { Button, Form, notification } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ReviewfyTagLine } from "../../../assets";
import { Input } from "antd";
import { getInviteStatus } from "../../../store/slices/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { activateUserByForm } from "../../../store/slices/settingSlice";

const Container = styled.div`
	background-color: #8ec5fc;
	background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100vh;
`;

const LogoHolder = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1em;
	margin-bottom: 1em;
`;

const ActivateContainer = styled.div`
	width: 29%;
	height: 55vh;
	background: white;
	border-radius: 4px;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) and (max-width: 2900px) {
		width: 25%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Salutation = styled.h2`
	padding: 0.5em;
	text-align: left;
	::after {
		content: "${(props) => props.emailAddress}";
		display: block;
		font-size: 12px;
		color: #6c6c6d;
		font-weight: 500;
	}
`;

const LabelWrapper = styled.div`
	width: 100%;
	padding: 5px 5px 5px 0px;
	text-align: left;
	font-weight: 500;
	text-tranform: uppercase;
`;

const SSOGoogle = styled.button`
	margin-top: 0.5em;
	width: 100%;
`;

const InviteHandler = () => {
	const history = useHistory();
	const { orgId, userId } = useParams();
	const { activateUser } = useSelector((state) => state.settings);

	const dispatch = useDispatch();

	const handleGoogleSSO = () => {
		let host = window.location.host;

		let endPoint = "";
		if (host === "localhost:3000") {
			endPoint = "http://localhost:3001";
		} else {
			endPoint = `https://${host}`;
		}
		window.location = `${endPoint}/api/auth/sso/google?auth=activate`;
	};

	useEffect(() => {
		dispatch(getInviteStatus({ orgId, userId }));
	}, []);

	useEffect(() => {
		if (activateUser.activated) {
			history.push("/account-activation?auth=activate&status=success");
		}
	}, [activateUser]);

	const onFinish = async (values) => {
		const { password, confirm } = values;

		if (password !== confirm) {
			notification.error({
				message: "Password do not match",
			});
			return;
		}

		const response = await dispatch(
			activateUserByForm({ password, userId, orgId })
		).unwrap();
		console.log(response);
		if (response && response.code) {
			if (response.code === "USER_UPDATED")
				history.push("/account-activation?auth=activate&status=success");
		}
	};

	return (
		<Container>
			<LogoHolder>
				<img
					alt={""}
					src={ReviewfyTagLine}
					style={{ width: "15%", height: "100%" }}
				></img>
			</LogoHolder>
			<ActivateContainer>
				<Salutation emailAddress={`${activateUser && activateUser.email}`}>
					{`${activateUser && activateUser.name}`}
				</Salutation>

				<SSOGoogle
					type="button"
					className="login-with-google-btn"
					onClick={handleGoogleSSO}
				>
					Activate your account with Google
				</SSOGoogle>
				<h4 style={{ marginTop: "0.4em" }}>OR</h4>
				<Wrapper>
					<Form onFinish={onFinish}>
						<LabelWrapper>Password</LabelWrapper>

						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please enter password!",
								},
								{
									min: 5,
									message: "Password must be atleast 5 characters",
								},
							]}
							hasFeedback
						>
							<Input.Password />
						</Form.Item>

						<LabelWrapper name="password">Confirm Password</LabelWrapper>
						<Form.Item
							name="confirm"
							dependencies={["password"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please confirm your password!",
								},
								{
									min: 5,
									message: "Password must be atleast 5 characters",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						>
							<Input.Password />
						</Form.Item>
						<Form.Item>
							<Button
								block
								htmlType={"submit"}
								style={{
									marginTop: "1em",
									backgroundColor: "#12344d",
									color: "white",
								}}
							>
								Activate your Account
							</Button>
						</Form.Item>
					</Form>
				</Wrapper>
			</ActivateContainer>
		</Container>
	);
};

export default InviteHandler;
