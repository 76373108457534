import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Form, Input, notification } from "antd";
import { LoginDoodle, ForgotPasswordEmail } from "../../assets/index";
import { LeftCircleTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router";
import { SendResetLink } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";

const Container = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Toddle = styled.img`
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	z-index: -1;
`;

const ResetPwdContainer = styled.div`
	width: 40%;
	height: 60vh;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 1em;
	font-size: 1.2em;
	font-weight: 500;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) and (max-width: 2900px) {
		width: 25%;
	}
`;

const VerifyEmail = styled.div`
	padding: 10px;
	height: 100%;
	width: 100%;
`;

const NavigateIcon = styled.span`
	padding: 1em;
	display: flex;
	font-size: 1.2rem;
	width: 100%;
	cursor: pointer;
`;

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [linkSent, setLinkSent] = useState(false);
	const [errMsg, setErrorMsg] = useState("");
	const history = useHistory();
	const dispatch = useDispatch();

	const sendResetPasswordLink = async () => {
		try {
			const response = await dispatch(SendResetLink({ email })).unwrap();

			if (response.code === "RESET_LINK_SENT") {
				setLinkSent(true);
				setErrorMsg("");
				notification.success({
					message: "Reset Link Sent",
					description:
						"Instructions to reset password sent to your registered email address",
				});
			}
		} catch (err) {
			setErrorMsg(err.message);
		}
	};

	return (
		<Fragment>
			<Alert
				showIcon
				type={"error"}
				message={errMsg}
				closable={true}
				style={{ display: `${errMsg ? "block" : "none"}` }}
			></Alert>
			<Container>
				<ResetPwdContainer>
					<NavigateIcon>
						<LeftCircleTwoTone onClick={() => history.push("/signin")} />
					</NavigateIcon>
					<VerifyEmail>
						<img
							src={ForgotPasswordEmail}
							width={"50%"}
							height={"50%"}
							alt={"brokenImg"}
						/>
						<p>
							You will receive an link to your to your registered address with
							instructions to reset your password!.
						</p>
						<div style={{ display: `${linkSent ? "none" : "block"}` }}>
							<p>Please enter the registered email address.</p>
							<Form onFinish={sendResetPasswordLink}>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Please enter a valid email address!",
											type: "email",
										},
									]}
								>
									<Input
										required={true}
										type={"email"}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										style={{
											marginBottom: "1em",
										}}
									></Input>
								</Form.Item>
								<Form.Item>
									<Button block htmlType="submit">
										{"Reset Password"}
									</Button>
								</Form.Item>
							</Form>
						</div>
						<p style={{ display: `${linkSent ? "block" : "none"}` }}>
							{"Reset Link Sent!"}
						</p>
					</VerifyEmail>
				</ResetPwdContainer>
				<Toddle src={LoginDoodle} />
			</Container>
		</Fragment>
	);
};

export default ForgotPassword;
