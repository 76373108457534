import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { WorkspaceIcon } from "../../assets/index";
import { Button, Modal } from "antd";
import {
	PlayCircleTwoTone,
	GoldenFilled,
	ApartmentOutlined,
} from "@ant-design/icons";
import AddWorkspace from "./components/CreateWorkspace";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarVisible } from "../../store/slices/uiSlice";
import {
	getWorkspaces,
	setSelectedWorkspaceId,
	cancelWorkspaceAutomation,
	automateWorkspaceCreation,
} from "../../store/slices/environmentSlice";
import { setRootNavTab } from "../../store/slices/uiSlice";
import { fetchGroups } from "../../store/slices/settingSlice";
import Teams from "../teams/Teams";
import ManageUsers from "../teams/components/ManageUsers";
import ManageGroups from "../teams/components/ManageGroups";
import { formatDate } from "../../helpers/utils";
import PageEmptyState from "../common/PageEmptyState";

const Container = styled.div`
	height: calc(100vh -60px);
	width: 100%;
	display: flex;
`;

const HomeLeftNav = styled.div`
	width: 18%;
	max-width: 18%;
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	border-right: solid 1px #dee5f4;
`;

const NavItems = styled.div`
	padding: 1em;
	background: white;
	margin-top: 0.5em;
	width: 95%;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	background-color: ${(props) => (props.selected ? "#f5f7f9" : "#ffffff")};
`;

const CardWrapper = styled.div`
	width: 230px;
	height: 230px;
	margin: 1em;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	cursor: pointer;
	border: solid 1px #dee5f4;
	border-radius: 5px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: none;
	:hover {
		box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
		transition: 0.2s;
	}
`;

const WorkspaceFolder = styled.img`
	width: 40%;
	height: 50%;
	padding: 15px;
	object-fit: contain;
	padding: 1em;
`;

const EnterIconSpace = styled.div`
	width: 100%;
	font-size: 1.5em;
	padding: 0.5em;
	cursor-pointer;
	display: flex;
	justify-content: space-evenly;
`;

const WorkspaceName = styled.div`
	width: 100%;
	padding: 1em;
	border-top: 0.5px solid #8b8b8b26;
	border-bottom: 0.5px solid #8b8b8b26;
`;

const TopSecondaryNav = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding-right: 0.5em;

	background-color: #f5f7f9;
	border-bottom: 1px #cfd7df solid;
`;

const RightPanelContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const TimeStampSpan = styled.span`
	font-size: 0.5em;
	text-align: right;
	width: 100%;
	padding: 1em;
`;

const WorkSpaceCardContainer = styled.div`
	width: 100%;
	height: calc(100vh - 60px - 50px);
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 250px));
	grid-template-rows: repeat(auto-fit, minmax(250px, 260px));
	overflow-y: auto;
	background-color: #ebeff3;
`;

const SectionContainer = styled.div`
	display: flex;
	flex: 1;
`;

const Workspaces = (props) => {
	const { contextName } = props;
	const history = useHistory();
	const dispatch = useDispatch();
	const [isModalVisible, setModalVisible] = useState(true);
	const [selectedNav, setSelectedNav] = useState("");
	const [createDrawerVisible, setcreateDrawerVisible] = useState(false);
	const { workspaces, automateWorkspace } = useSelector(
		(state) => state.environment
	);

	useEffect(() => {
		if (contextName === "workspaces") {
			dispatch(getWorkspaces());
			dispatch(fetchGroups());
		}
	}, [contextName]);

	useEffect(() => {
		setSelectedNav(contextName);
	}, [contextName]);

	const showCreatePanel = () => {
		setcreateDrawerVisible(true);
	};

	const switchWorkspace = async (workspaceId) => {
		dispatch(setNavBarVisible(false));
		dispatch(setSelectedWorkspaceId({ workspaceId: workspaceId }));
		history.push(`/${workspaceId}/home`);
	};

	const handleAutomationCancel = () => {
		dispatch(cancelWorkspaceAutomation());
	};

	const automateWkspace = () => {
		setModalVisible(false);
		dispatch(automateWorkspaceCreation());
	};

	const renderContextComponent = (contextName) => {
		switch (contextName) {
			case "workspaces":
				return (
					<>
						<RightPanelContainer>
							<AddWorkspace
								isVisible={createDrawerVisible && isModalVisible}
								setVisible={setcreateDrawerVisible}
							></AddWorkspace>
							<TopSecondaryNav>
								<Button onClick={showCreatePanel}>Create Workspace</Button>
							</TopSecondaryNav>
							<>
								{automateWorkspace ? (
									<Modal
										visible={automateWorkspace}
										footer={false}
										centered
										onCancel={handleAutomationCancel}
										title={"Spin it up for me!"}
									>
										<p>
											{
												"It appears you don't have a workspace to begin with. Let us alleviate the stress, we will establish a group, include you in it, and set up a workspace promptly."
											}
										</p>
										<p>
											{
												"Would you like us to proceed with creating the workspace immediately?"
											}
										</p>
										<Button block={true} onClick={automateWkspace}>
											{"Set up a Workspace Now!"}
										</Button>
									</Modal>
								) : (
									""
								)}
							</>

							{workspaces && workspaces.length > 0 ? (
								<WorkSpaceCardContainer>
									{workspaces &&
										workspaces.map((item) => {
											return (
												<CardWrapper>
													<TimeStampSpan>
														{formatDate(item.createdAt)}
													</TimeStampSpan>
													<WorkspaceFolder src={WorkspaceIcon} />
													<WorkspaceName>{item.workspaceName}</WorkspaceName>
													{/* <ActionBar></ActionBar> */}
													<EnterIconSpace
														onClick={() => {
															switchWorkspace(item._id);
															dispatch(setRootNavTab("Dashboard"));
														}}
													>
														<PlayCircleTwoTone />
													</EnterIconSpace>
												</CardWrapper>
											);
										})}
								</WorkSpaceCardContainer>
							) : (
								<PageEmptyState
									srcImage={
										"https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
									}
									message={"No Workspaces Found!"}
								></PageEmptyState>
							)}
						</RightPanelContainer>
					</>
				);
			case "teams":
				return <Teams></Teams>;

			case "users":
				return <ManageUsers></ManageUsers>;

			case "groups":
				return <ManageGroups></ManageGroups>;

			default:
				return <></>;
		}
	};

	const NavElements = [
		{
			iconElement: (
				<GoldenFilled
					style={{ fontSize: "1.5em", padding: "0.5em" }}
				></GoldenFilled>
			),
			tabId: "workspaces",
			tabName: "Workspaces",
			description: "Exclusive environments for your forms collection.",
		},
		{
			iconElement: (
				<ApartmentOutlined
					style={{ fontSize: "1.5em", padding: "0.5em", color: "black" }}
				></ApartmentOutlined>
			),
			tabId: "teams",
			tabName: "Teams",
			description: "Define user access levels and permissions.",
		},
	];

	return (
		<Container>
			<HomeLeftNav>
				{NavElements.map((item) => {
					return (
						<NavItems
							selected={selectedNav === item.tabId}
							onClick={() => {
								setSelectedNav(item.tabId);
								history.push(`/${item.tabId}`);
							}}
						>
							{item.iconElement}
							<div
								style={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									textAlign: "left",
								}}
							>
								<span style={{ width: "100%", fontWeight: 500 }}>
									{item.tabName}
								</span>
								<p
									style={{ width: "100%", fontSize: "0.7em", color: "#475867" }}
								>
									{item.description}
								</p>
							</div>
						</NavItems>
					);
				})}
			</HomeLeftNav>
			<SectionContainer>{renderContextComponent(selectedNav)}</SectionContainer>
		</Container>
	);
};

export default Workspaces;
