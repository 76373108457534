import React from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import { getContentDisplayIcon } from "../../../../../helpers/utils";

const CardWrapper = styled.div`
	background: white;
	height: 100px;
	cursor: pointer;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-weight: 600;
	border-radius: 4px;
	font-size: ${(props) => (props.questionLength > 50 ? "9px" : "11px")};
	text-align: left;
	border: solid 1px #dee5f4;
	:hover {
		box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	}
`;

const ResponseTypeWrapper = styled.div`
	height: 20px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
`;

const QuestionCard = ({ contentItem, queryValue }) => {
	const [{ opacity }, drag] = useDrag({
		item: { type: "contents", ...contentItem },

		collect: (monitor) => ({
			opacity: monitor.isDragging() ? 0.4 : 1,
		}),
	});
	return (
		<>
			<CardWrapper
				ref={drag}
				contentItem={contentItem}
				questionLength={queryValue.length}
			>
				{queryValue}
				<ResponseTypeWrapper>
					<img
						alt={""}
						src={getContentDisplayIcon(contentItem.responseType)}
						style={{
							maxWidth: "100%",
							objectFit: "contain",
						}}
					></img>
				</ResponseTypeWrapper>
			</CardWrapper>
		</>
	);
};

export default QuestionCard;
