import { createSlice } from "@reduxjs/toolkit";
// import { message, notification } from "antd";

const initialState = {
	overallPerformance: null,
	selectedFormPerformance: null,
	selectedFormReviews: null,
	contentAnalytics: null,
};

const metricSlice = createSlice({
	name: "analytics",
	initialState,
	reducers: {
		setOVMetrics: (state, action) => {
			state.overallPerformance = action.payload;
		},
		setFormMetrics: (state, action) => {
			state.selectedFormPerformance = action.payload;
		},
		setFormReviews: (state, action) => {
			state.selectedFormReviews = action.payload;
		},
		setContentAnalytics: (state, action) => {
			state.contentAnalytics = action.payload;
		},
		clearContentAnalytics: (state, action) => {
			state.contentAnalytics = null;
		},
	},
});

export const {
	setOVMetrics,
	setFormMetrics,
	setFormReviews,
	setContentAnalytics,
	clearContentAnalytics,
} = metricSlice.actions;
export default metricSlice.reducer;
