import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getChannelConfig } from "../../../../../store/slices/settingSlice";
import { Input, Switch } from "antd";
import { supportedEmailProviders } from "../../../../../helpers/utils";
import { updateDefaultDispatcher } from "../../../../../store/slices/dispatcherSlice";
import { updateCorportateConfig } from "../../../../../store/slices/settingSlice";
import { MailIcon } from "../../../../../assets/index";
import { Breadcrumb } from "antd";

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1em;
	padding: 1em;
	box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px;
`;

const Message = styled.p`
	padding: 0.5em;
	text-transform: ${(props) =>
		props.textTransform ? props.textTransform : "uppercase"};
	text-align: left;
	line-height: 1.6;
`;

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 1em;
	background: #f5f6fa;
`;

const SubscriptionCard = styled.div`
	background: #ffffff;
	width: 50%;
	margin: 2em;
	border-radius: 2px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	display: flex;
	flex-direction: column;
	padding: 1em;
`;

const InputWrapper = styled.div`
	padding: 0.5em;
	font-weight: 500;
	display: flex;
	flex-direction: ${(props) => (props.flexDir ? props.flexDir : "row")};
	justify-content: space-between;
	align-items: ${(props) => (props.alignment ? props.alignment : "center")};
`;

const IntegLogo = styled.img`
	width: 60px;
	height: 60px;
	padding: 0.5em;
`;

const TitleWrap = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
`;

const Title = styled.span`
	font-weight: 600;
`;

const StyledButton = styled.button`
	padding: 0.5em 1em;
	border: none;
	font-weight: 500;
	border-radius: 4px;
	background: var(--primaryColor);
	color: white;
	cursor: pointer;
`;

const BreadcrumbWrapper = styled.div`
	text-transform: capitalize;
	font-size: 10px;
	padding: 1em;
`;

const SendGridConfig = () => {
	const dispatch = useDispatch();

	const [host, setHost] = useState("");
	const [port, setPort] = useState("");
	const [verifiedEmail, setVerifiedEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isEditable, setEditable] = useState(true);
	const [toggled, setToggled] = useState(false);

	const editButton = useRef("add");

	const { corporateDispatcher } = useSelector((state) => state.settings);
	const { defaultEmailDispatcher } = useSelector((state) => state.dispatcher);
	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const getCorpMailConfig = useCallback(
		() =>
			dispatch(
				getChannelConfig({ channel: "email", integration: "corporate" })
			),
		[dispatch]
	);

	useEffect(() => {
		getCorpMailConfig();
	}, [getCorpMailConfig]);

	useEffect(() => {
		setToggled(
			supportedEmailProviders.CORPORATE_EMAIL === defaultEmailDispatcher
		);
	}, [defaultEmailDispatcher]);

	useEffect(() => {
		if (corporateDispatcher) {
			setVerifiedEmail(corporateDispatcher.emailAddress);
			setPort(corporateDispatcher.port);
			setHost(corporateDispatcher.host);
			setPassword(corporateDispatcher.password);
			setEditable(false);
		}
	}, [corporateDispatcher, defaultEmailDispatcher]);

	const handleConfigChange = (toggleChanged = false) => {
		if (!isEditable) {
			editButton.current.name = "edit";
			editButton.current.innerHTML = "Update Configuration";
			setEditable(!isEditable);
		} else {
			setEditable(!isEditable);
			editButton.current.name = "add";
			editButton.current.innerHTML = "Add Configuration";
			const {
				emailAddress,
				host: hostServer,
				port: serverPort,
				password: emailPassword,
			} = corporateDispatcher;
			if (
				emailAddress !== verifiedEmail ||
				hostServer !== host ||
				serverPort !== port ||
				emailPassword !== password ||
				toggleChanged
			) {
				setToggled(!toggled);
				const defaultEmailDispatcher = toggled
					? supportedEmailProviders.NOT_CONFIGURED
					: supportedEmailProviders.CORPORATE_EMAIL;
				dispatch(
					updateDefaultDispatcher({ dispatcher: defaultEmailDispatcher })
				);
				console.log({
					host: host,
					port: port,
					password: password,
					emailAddress: verifiedEmail,
					defaultEmailDispatcher: defaultEmailDispatcher,
				});
				dispatch(
					updateCorportateConfig({
						host: host,
						port: port,
						password: password,
						emailAddress: verifiedEmail,
						defaultEmailDispatcher: defaultEmailDispatcher,
					})
				);
			}
		}
	};

	return (
		<Container>
			<Header>
				<BreadcrumbWrapper>
					<Breadcrumb separator=">">
						<Breadcrumb separator=">">
							<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings`}>
								{"Settings"}
							</Breadcrumb.Item>
							<Breadcrumb.Item
								href={`/${selectedWorkspaceId}/settings/channels`}
							>
								{"Channels"}
							</Breadcrumb.Item>
						</Breadcrumb>
					</Breadcrumb>
				</BreadcrumbWrapper>
				<Message>CORPORATE EMAIL CONFIGURATION</Message>
				<Message textTransform={"none"}>
					You can make use of your corporate email configuration to dispatch
					emails to your users!
				</Message>
			</Header>
			<FlexBox>
				<SubscriptionCard>
					<TitleWrap style={{ justifyContent: "space-between" }}>
						<TitleWrap style={{ maxWidth: "70%" }}>
							<IntegLogo src={MailIcon}></IntegLogo>
							<Title>Corporate Email Configuration</Title>
						</TitleWrap>
						<StyledButton ref={editButton} onClick={() => handleConfigChange()}>
							Modify Configuration
						</StyledButton>
					</TitleWrap>
					<Message textTransform={"none"}>
						Add your SMTP host, port, verified email address and password
					</Message>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your SMTP Host</p>
						<Input
							value={host}
							onChange={({ target }) => setHost(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your Server Port</p>
						<Input
							value={port}
							onChange={({ target }) => setPort(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your email address to be used to dispatch emails</p>
						<Input
							value={verifiedEmail}
							onChange={({ target }) => setVerifiedEmail(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your email password</p>
						<Input
							type="password"
							value={password}
							onChange={({ target }) => setPassword(target.value)}
							disabled={!isEditable}
						></Input>
					</InputWrapper>
					<InputWrapper
						style={{
							background: "#f5f6fa",
							padding: "1em",
							margin: "0.5em",
							alignItems: "center",
						}}
					>
						Make this your default email dispatcher
						<Switch
							checked={toggled}
							disabled={!isEditable}
							onChange={() => handleConfigChange(true)}
							style={{
								background: `${toggled ? "var(--primaryColor)" : "grey"}`,
							}}
						/>
					</InputWrapper>
				</SubscriptionCard>
			</FlexBox>
		</Container>
	);
};

export default SendGridConfig;
