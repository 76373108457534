import React from "react";
import styled from "styled-components";
import { Input, List, Radio } from "antd";
import { FormHeaderLogo } from "../../../../assets/index";
import { ContentResponseTypes } from "../../../../helpers/utils";

const StyledButton = styled.button`
	width: 100%;
	height: 50px;
	padding: 10px;
	border-radius: 5px;
	background-color: var(--primaryColor);
	color: white;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`;

const ChooseIcon = styled.div`
	width: 100%;
	background-color: white;
	display: ${(props) => (props.showIcon ? "flex" : "none")};
	flex-direction: row;
	align-items: center;
	padding: 13px 20px;
	gap: 10px;
	border-radius: 10px;
	height: 55px;
	margin-top: 1.8em;
	border: 1px solid #e1e4f2;
	cursor: pointer;
`;

const ResponseTypeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	// background: green;
	margin-top: 1.8em;
	width: 100%;
`;

const SelectedIcon = styled.div`
	display: ${(props) => (props.showIcon ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	padding: 1em;
`;

const CreateManualQuestion = (props) => {
	const {
		selectedIcon,
		createQuestion,
		closePane,
		question,
		setQuestion,
		setAiText,
	} = props;

	const handleSubmit = () => {
		createQuestion(question);
		setQuestion({
			responseType: "",
			questionText: "",
			iconURL: "",
		});
	};

	return (
		<>
			<SelectedIcon showIcon={selectedIcon && selectedIcon._id}>
				<img
					alt={""}
					src={selectedIcon.iconURL}
					width="86px"
					height="86px"
				></img>
			</SelectedIcon>
			<Input
				showCount={true}
				placeholder={"Type your Question (100 chars max)"}
				style={{ padding: "17px 20px", borderRadius: "10px" }}
				maxLength={100}
				value={question && question.questionText}
				onChange={({ target }) => {
					setQuestion({ ...question, questionText: target.value });
					setAiText(target.value);
				}}
			></Input>
			<ChooseIcon
				onClick={() => closePane(true)}
				showIcon={!(selectedIcon && selectedIcon._id)}
			>
				<img alt={""} src={FormHeaderLogo}></img>
				<span
					style={{
						color: "#6080F5",
						fontWeight: 700,
					}}
				>
					Choose Icon
				</span>
			</ChooseIcon>
			<ResponseTypeWrapper>
				<span
					style={{
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "12px",
						color: "#474E6C",
						padding: "0.4em 0px",
					}}
				>
					SELECT RESPONSE TYPE
				</span>
				<Radio.Group
					onChange={({ target }) =>
						setQuestion({ ...question, responseType: target.value })
					}
					value={question && question.responseType}
				>
					<List
						bordered
						dataSource={ContentResponseTypes}
						renderItem={(item) => (
							<List.Item
								style={{
									// border: "2px solid var(--primaryColor)",
									display: "flex",
									height: "60px",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Radio value={item.responseType}></Radio>
									<img
										alt={""}
										src={item.responseTypeIcon}
										height="100%"
										style={{ marginLeft: "1em" }}
									></img>
								</div>
							</List.Item>
						)}
					/>
				</Radio.Group>
			</ResponseTypeWrapper>
			<StyledButton onClick={() => handleSubmit()}>{"SUBMIT"}</StyledButton>
		</>
	);
};

export default CreateManualQuestion;
