/* eslint-disable react/jsx-filename-extension */
import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import SignIn from "./components/auth/SignIn";
import ForgotPassword from "./components/auth/ForgotPassword";
import "./App.css";
import "antd/dist/antd.css";

import Main from "./components/common/Main";
import PrivateRoute from "./components/common/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/common/NotFound";
import Error from "./components/common/Error";
import FormManagementWrapper from "./components/form-management/index";
import FormDispatcherWrapper from "./components/form-dispatcher/index";
import ReviewResponses from "./components/review-responses/index";
import Settings from "./components/settings/index";
import DisplaySettings from "./components/settings/components/display/index";
import Channels from "./components/settings/components/channels/index";
import ThemeSwitcher from "react-css-vars";
import UserManagement from "./components/settings/components/users";
import ReviewfyTxn from "./components/reviewfy-transactions/index";
import Loading from "./components/common/Loading";
import Webhook from "./components/settings/components/webhooks/index";
import Billing from "./components/settings/components/billing/index";
import axios from "axios";
import ReviewfyFreeTrial from "./components/settings/components/channels/components/ReviewfyFreeTrial";
import SendGridConfig from "./components/settings/components/channels/components/SendGrid";
import WhatsAppConfig from "./components/settings/components/channels/components/WhatsApp";
import CorporateConfig from "./components/settings/components/channels/components/Corporate";
import Profile from "./components/settings/components/users/Profile";
import AccountActivation from "./components/common/AccountActivation";
import { isLoggedIn } from "./store/slices/authSlice";
import { isSessionExpired } from "./store/slices/uiSlice";
import { useDispatch } from "react-redux";
import Workspaces from "./components/workspaces/Workspace";
import InviteHandler from "./components/teams/components/ManageInvite";
import ResetPassword from "./components/auth/ResetPassword";

function App() {
	const dispatch = useDispatch();

	const { themePalette, sessionExpired } = useSelector((state) => state.ui);
	axios.interceptors.response.use(null, (error) => {
		const { status } = error.response;

		if (status === 401) {
			dispatch(isSessionExpired(true));
			dispatch(isLoggedIn(false));
			window.localStorage.clear();
		}
		return Promise.reject(error);
	});

	return (
		<div className="App">
			<Loading />
			<Router>
				<ThemeSwitcher theme={themePalette}>
					<Switch>
						<Route
							exact
							path="/signin"
							render={(props) => {
								return <SignIn tab="login" {...props} />;
							}}
						/>
						<Route exact path="/forgot-password" component={ForgotPassword} />
						<Route
							exact
							path="/"
							render={(props) => {
								return localStorage.getItem("authInfo") ? (
									<Redirect to={"/:workspaceId/home"} />
								) : (
									<Redirect to={"/signin"} />
								);
							}}
						/>

						<Route exact path="/txnHash/:hashId" component={ReviewfyTxn} />
						<Route
							exact
							path="/:orgId/invite/:userId"
							component={InviteHandler}
						/>
						<Route
							exact
							path="/:orgId/reset-password/:userId"
							component={ResetPassword}
						/>
						<Route
							exact
							path="/account-activation"
							component={AccountActivation}
						/>
						<Route exact path="/not-found" component={NotFound} />

						<Main>
							<Switch>
								<PrivateRoute
									exact
									path="/workspaces"
									component={Workspaces}
									hideNavBar={true}
									contextName={"workspaces"}
								/>
								<PrivateRoute
									exact
									path="/teams"
									component={Workspaces}
									hideNavBar={true}
									contextName={"teams"}
								/>
								<PrivateRoute
									exact
									path="/teams/users"
									component={Workspaces}
									hideNavBar={true}
									contextName={"users"}
								/>
								<PrivateRoute
									exact
									path="/teams/groups"
									component={Workspaces}
									hideNavBar={true}
									contextName={"groups"}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/review-responses"
									component={ReviewResponses}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/form-management"
									component={FormManagementWrapper}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/home"
									component={Dashboard}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/form-dispatcher"
									component={FormDispatcherWrapper}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/channels"
									component={Channels}
								/>

								<PrivateRoute
									exact
									path="/profile"
									hideNavBar={false}
									component={Profile}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/channel/reviewfy-free-trial"
									component={ReviewfyFreeTrial}
								/>

								<PrivateRoute
									exact
									path="/:workspaceId/settings/channel/sendgrid"
									component={SendGridConfig}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/channel/corporate-email"
									component={CorporateConfig}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/channel/whatsapp"
									component={WhatsAppConfig}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/people"
									component={UserManagement}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/webhooks"
									component={Webhook}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/display"
									component={DisplaySettings}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings/billing"
									component={Billing}
								/>
								<PrivateRoute
									exact
									path="/:workspaceId/settings"
									component={Settings}
								/>
								{/* <Redirect to="/not-found" /> */}
							</Switch>
						</Main>
					</Switch>
					<Error sessionExpired={sessionExpired}></Error>
				</ThemeSwitcher>
			</Router>
		</div>
	);
}

export default App;
