import React from "react";
import {
	ContentIconSmiley,
	ContentRateIcon,
	ContentTextIcon,
	URSExcellent,
	URSFair,
	URSGood,
	URSPoor,
	URSVeryGood,
} from "../assets";
import { message } from "antd";
import moment from "moment";
import styled from "styled-components";
import Rater from "../components/form-management/common/ResponseTypes/Rate";
import EmojiResponse from "../components/form-management/common/ResponseTypes/Emoji";
import LikeDisLike from "../components/form-management/common/ResponseTypes/LikeDisLike";
import {
	LikeDisLikeIcon,
	SliderIcon,
	GroupEmoji,
	ContentSlider,
	ContentLikeDisLike,
	ContentRate,
	TextResponse,
	UREExcellent,
	UREVeryGood,
	UREGood,
	UREFair,
	UREPoor,
	URLLike,
	URLDisLike,
	URRExcellent,
	URRVeryGood,
	URRGood,
	URRFair,
	URRPoor,
} from "../assets/index";
import SliderResponse from "../components/form-management/common/ResponseTypes/Slider";

const DisabledInput = styled.input`
	margin: 5px;
	width: 100%;
`;

export const supportedEmailProviders = Object.freeze({
	TWILIO_SENDGRID: "TWILIO_SENDGRID",
	REVIEWFY_FREE_TRIAL: "REVIEWFY_FREE_TRIAL",
	CORPORATE_EMAIL: "CORPORATE_EMAIL",
	NOT_CONFIGURED: null,
});

export const getBase64 = (img) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result));
		reader.readAsDataURL(img);
	});
};

export const renderResponseTypeStyle = (responseType) => {
	if (responseType === "rate") {
		return {
			backgroundColor: "#D8E6FD",
			responseIcon: ContentRateIcon,
			color: "#1d7aff",
		};
	} else if (responseType === "text") {
		return {
			backgroundColor: "#DFCFEE",
			responseIcon: ContentTextIcon,
			color: "#7209b7",
		};
	} else if (responseType === "emoji") {
		return {
			backgroundColor: "#ffca4240",
			responseIcon: ContentIconSmiley,
			color: "#FFCA42",
		};
	} else if (responseType === "like") {
		return {
			backgroundColor: "#79fd4b40",
			responseIcon: LikeDisLikeIcon,
			color: "#45d02b",
		};
	} else if (responseType === "slider") {
		return {
			backgroundColor: "#e67e2261",
			responseIcon: SliderIcon,
			color: "#e67e22",
		};
	}
};

export const getContentDisplayIcon = (responseType) => {
	switch (responseType) {
		case "text":
			return TextResponse;
		case "rate":
			return ContentRate;
		case "emoji":
			return GroupEmoji;
		case "like":
			return ContentLikeDisLike;
		case "slider":
			return ContentSlider;
		default:
			return null;
	}
};

export const renderUserResponse = (responseType, responseValue) => {
	switch (responseType) {
		case "emoji":
			let emojiResponseSet = [
				UREPoor,
				UREFair,
				UREGood,
				UREVeryGood,
				UREExcellent,
			];
			return emojiResponseSet[parseInt(responseValue)];

		case "like":
			let likeResponseSet = [URLDisLike, URLLike];
			return likeResponseSet[parseInt(responseValue)];

		case "rate":
			let rateResponseSet = [
				URRPoor,
				URRFair,
				URRGood,
				URRVeryGood,
				URRExcellent,
			];
			return rateResponseSet[parseInt(responseValue)];

		case "slider":
			let sliderResponseSet = [
				URSPoor,
				URSFair,
				URSGood,
				URSVeryGood,
				URSExcellent,
			];
			return sliderResponseSet[parseInt(responseValue)];

		default:
			return ContentRateIcon;
	}
};

export const getResponseTypeIcon = (responseType) => {
	switch (responseType) {
		case "text":
			return ContentTextIcon;
		case "icon":
			return ContentRateIcon;
		case "emoji":
			return ContentIconSmiley;
		case "like":
			return LikeDisLikeIcon;
		case "slider":
			return SliderIcon;
		default:
			return ContentRateIcon;
	}
};

export const getBaseURL = () => {
	let apiEndPoint = window.location.origin;

	if (window.location.origin === "http://localhost:3000") {
		apiEndPoint = "http://localhost:3001";
	}
	return apiEndPoint;
};

export const getSessionToken = () => {
	try {
		const { token } = JSON.parse(window.localStorage.getItem("authInfo"));
		return token;
	} catch (err) {
		return null;
	}
};

export const formatDate = (dateString) => {
	const localeDateString = moment(dateString);
	return localeDateString.format("LLL");
};

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const dataURLtoFile = (dataurl, filename) => {
	const arr = dataurl.split(",");
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n) {
		u8arr[n - 1] = bstr.charCodeAt(n - 1);
		n -= 1; // to make eslint happy
	}
	return new File([u8arr], filename, { type: mime });
};

export const beforeImgUpload = (file) => {
	const isJpgOrPng =
		file.type === "image/jpeg" ||
		file.type === "image/jpg" ||
		file.type === "image/png" ||
		file.type === "image/svg+xml";
	if (!isJpgOrPng) {
		message.error("You can only upload JPG/PNG file!");
	}
	const isLt2M = file.size / 1024 / 1024 < 4;
	if (!isLt2M) {
		message.error("Image must smaller than 4MB!");
	}
	return isJpgOrPng && isLt2M;
};

export const renderResponseType = (responseType) => {
	switch (responseType) {
		case "text":
			return <DisabledInput disabled={true}></DisabledInput>;

		case "rate":
			return <Rater isDisabled={true}></Rater>;

		case "emoji":
			return <EmojiResponse disabled={true}></EmojiResponse>;

		case "like":
			return <LikeDisLike disabled={true}></LikeDisLike>;

		case "slider":
			return <SliderResponse disabled={true}></SliderResponse>;

		default:
			return <h2>{"Invalid Input Field"}</h2>;
	}
};

export const getBearerToken = () => {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
};

export const csv2JSON = (csv) => {
	var lines = csv.split("\n");
	var result = [];
	var headers = lines[0].split(",");
	for (var i = 1; i < lines.length; i++) {
		var obj = {};
		var currentline = lines[i].split(",");

		for (var j = 0; j < headers.length; j++) {
			obj[headers[j]] = currentline[j];
		}
		result.push(obj);
	}
	return result; //JSON
};

export const validateOrgInfo = (orgInfo) => {
	const { email, orgName, domain, password, mobileNumber, confirmPassword } =
		orgInfo;
	let isValidForm = true;

	if (!validateEmail(email)) {
		document.getElementById("email-span").style.display = "block";
		document.getElementById(`email-span`).innerHTML =
			"Please enter a valid email address!";
		setTimeout(() => {
			document.getElementById("email-span").style.display = "none";
			document.getElementById(`email-span`).innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	if (!orgName.length) {
		document.getElementById("orgName-span").style.display = "block";
		document.getElementById("orgName-span").innerHTML =
			"Organisation Name cannot be empty!";
		setTimeout(() => {
			document.getElementById("orgName-span").style.display = "none";
			document.getElementById("orgName-span").innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	if (mobileNumber.length < 5) {
		document.getElementById("mobile-span").style.display = "block";
		document.getElementById("mobile-span").innerHTML =
			"Not a valid mobile number !";
		setTimeout(() => {
			document.getElementById("mobile-span").style.display = "none";
			document.getElementById("mobile-span").innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	if (password !== confirmPassword || confirmPassword.length === 0) {
		document.getElementById("confirm-password-span").style.display = "block";
		document.getElementById("confirm-password-span").innerHTML =
			"Passwords do not match !";
		setTimeout(() => {
			document.getElementById("confirm-password-span").style.display = "none";
			document.getElementById("confirm-password-span").innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	if (password.length < 8) {
		document.getElementById("password-span").style.display = "block";
		document.getElementById("password-span").innerHTML =
			"Password should be minimum of 8 characters!";
		setTimeout(() => {
			document.getElementById("password-span").style.display = "none";
			document.getElementById("password-span").innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	if (domain.length) {
		let regexExp = /[^A-Za-z 0-9.]/g;
		if (regexExp.test(domain)) {
			document.getElementById("domain-span").style.display = "block";
			document.getElementById("domain-span").innerHTML =
				"Domain Names cannot contain special characters";
			setTimeout(() => {
				document.getElementById("domain-span").style.display = "none";
				document.getElementById("domain-span").innerHTML = "";
			}, 10000);
			isValidForm = false;
		}
	} else {
		document.getElementById("domain-span").style.display = "block";
		document.getElementById("domain-span").innerHTML =
			"Domain Names cannot be empty";
		setTimeout(() => {
			document.getElementById("domain-span").style.display = "none";
			document.getElementById("domain-span").innerHTML = "";
		}, 10000);
		isValidForm = false;
	}

	return isValidForm;
};

export const ContentResponseTypes = [
	{
		responseType: "emoji",
		responseTypeIcon: GroupEmoji,
		selected: true,
	},
	{
		responseType: "rate",
		responseTypeIcon: ContentRate,
		selected: false,
	},
	{
		responseType: "slider",
		responseTypeIcon: ContentSlider,
		selected: false,
	},
	{
		responseType: "like",
		responseTypeIcon: ContentLikeDisLike,
		selected: false,
	},
	{
		responseType: "text",
		responseTypeIcon: TextResponse,
		selected: false,
	},
];
