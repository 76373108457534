import React from "react";
import { useDispatch } from "react-redux";
import { Modal, message } from "antd";
import styled from "styled-components";
import { WarningIcon } from "../../../../../assets/index";
import { removeForm } from "../../../../../store/slices/formSlice";

const WarningWrapper = styled.div`
	height: 80px;
	width: 100%;
	margin-top: 30px;
`;

const WarningIconWrapper = styled.img`
	display: block;
	margin: auto;
	width: 50%;
	height: 100%;
	padding: 5px;
`;

const DeleteTextWrapper = styled.div`
	width: 100%;
	height: 30px;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.81;
	letter-spacing: normal;
	text-align: center;
	color: #001845;
	// background-color: green;
`;

const ActionButtonWrapper = styled.div`
	// background-color: blue;
	width: 100%;
	height: auto;
	padding: 10px;
`;

const ActionDeleteButton = styled.div`
	width: 45%;
	float: left;
	margin: 5px;
	height: 36px;
	color: #ffffff;
	padding: 7px;
	font-weight: 700;
	border: solid 1px #f70000;
	border-radius: 5px;
	background-color: #f70000;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
`;

const DeleteModal = (props) => {
	const dispatch = useDispatch();

	const { formId, setIsModalVisible } = props;
	const handleDeleteForm = async () => {
		try {
			dispatch(removeForm(formId));
			setIsModalVisible(false);
			message.info(`Form Deleted Successfully!`);
		} catch (error) {
			message.error("Unable to delete form! Try Again Later! ");
		}
	};

	return (
		<Modal
			visible={props.isVisible}
			footer={null}
			centered={true}
			maskClosable={false}
			onCancel={() => props.setIsModalVisible(false)}
			bodyStyle={{ borderRadius: "30px", height: "270px" }}
		>
			<WarningWrapper>
				<WarningIconWrapper src={WarningIcon}></WarningIconWrapper>
			</WarningWrapper>
			<DeleteTextWrapper>
				{"Are you sure you want to delete this Form ?"}
			</DeleteTextWrapper>
			<ActionButtonWrapper>
				<ActionDeleteButton onClick={() => handleDeleteForm()}>
					{"Yes, delete this form"}
				</ActionDeleteButton>
				<ActionDeleteButton
					style={{
						backgroundColor: "#dee5f4",
						color: "#95abd3",
						border: "none",
					}}
					onClick={() => props.setIsModalVisible(false)}
				>
					{"Cancel, keep this form"}
				</ActionDeleteButton>
			</ActionButtonWrapper>
		</Modal>
	);
};
// 

export default DeleteModal;
