import React from "react";
import styled from "styled-components";
import { Modal, Button } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { isSessionExpired } from "../../store/slices/uiSlice";

const ModalTitle = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 50px;
	width: 50%;
`;

const ItemSpan = styled.div`
	padding: 8px;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
`;

const Error = (props) => {
	const { sessionExpired } = props;
	const dispatch = useDispatch();

	const logoutConsole = () => {
		window.localStorage.clear();
		window.location.reload();
		dispatch(isSessionExpired(false));
	};

	return (
		<Modal
			title={
				<ModalTitle>
					<ItemSpan fontSize={"24px"}>
						<HistoryOutlined />
					</ItemSpan>
					<ItemSpan>{"Session Timed Out"}</ItemSpan>
				</ModalTitle>
			}
			visible={sessionExpired}
			okButtonProps={{ style: { display: "none" } }}
			cancelButtonProps={{ style: { display: "none" } }}
			closable={false}
			centered
		>
			<p>{"Your Session Has Expired! Please Login Again!"}</p>
			<Button onClick={() => logoutConsole()}>Login Again</Button>
		</Modal>
	);
};

export default Error;
