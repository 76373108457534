import React from "react";
import styled from "styled-components";
import { Tag } from "antd";

const Container = styled.div`
	border-bottom: solid 1px #d1d9e5;
	min-height: 60px;
	display: flex;
`;

const Label = styled.span`
	height: 100%;
	width: 20%;
	padding: 1em;
	text-align: left;
	color: #505f7b;
	font-weight: 400;
`;

const LabelValue = styled.span`
	flex: 1;
	padding: 1em;
	text-align: left;
	font-weight: 500;
	color: #001845;
	word-wrap: break-word;
	font-size: 0.8em;
	display: flex;
	align-items: center;
`;

const TxnFormItem = (props) => {
	const { label, labelValue, tag } = props;
	return (
		<Container>
			<Label>{label}</Label>
			{tag ? (
				<LabelValue>
					<Tag color={"green"}>{labelValue}</Tag>
				</LabelValue>
			) : (
				<LabelValue>{labelValue}</LabelValue>
			)}
		</Container>
	);
};

export default TxnFormItem;
