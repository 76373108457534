import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import TxnFormItem from "./components/TxnFormItem";
import ContentsContainer from "./components/ContentsContainer";
import { getTxnInformation } from "../../store/slices/approvalSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReviewfyLogo } from "../../assets/index";
import { formatDate } from "../../helpers/utils";
import { Button } from "antd";

const Container = styled.div`
	height: 100vh;
	width: 100%;
	background-color: #f6f8fa;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: auto;
`;

const Header = styled.div`
	min-height: 100px;
	display: flex;
	justify-content: flex-start;
	padding: 10px;
	margin: 20px 20px 0px 20px;
	border-radius: 8px;
	background-color: white;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.13);
	align-items: center;
`;

const StateContainer = styled.div`
	display: flex;
	flex: 1;
	padding: 20px;
	@media only screen and (max-width: 600px) {
		background-color: lightblue;
		flex-wrap: wrap;
	}
`;

const TxnDetails = styled.div`
	width: 50%;
	min-width: 300px;
	display: flex;
	flex-direction: column;
	flex: 1 1;
	margin: 10px;
	border-radius: 8px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.13);
	background-color: #ffffff;
`;

const StyledLogo = styled.img`
	object-fit: contain;
	padding: 0px 15px 0px 15px;
`;

const OrgName = styled.span`
	font-size: 2rem;
	font-weight: 800;
`;

const TxnDetailsHeader = styled.div`
	height: 60px;
	text-align: left;
	border-bottom: solid 1px #d1d9e5;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.03);
`;

const StyledHeading = styled.div`
	padding: 17px;
	font-weight: 600;
	color: #408eff;
	display: inline-block;
	border-bottom: 6px solid #408eff;
	margin-left: 5px;
`;

const ReviewfyTxn = (props) => {
	const dispatch = useDispatch();
	const { txnInfo } = useSelector((state) => state.approvals);

	console.log(txnInfo);

	const fetchTxnStatus = useCallback(
		(payload) => dispatch(getTxnInformation(payload)),
		[dispatch]
	);

	useEffect(() => {
		const queryString = new URLSearchParams(window.location.search);
		const orgId = queryString.get("orgId");
		const reviewId = queryString.get("reviewId");
		fetchTxnStatus({ orgId, reviewId });
	}, [fetchTxnStatus]);

	return (
		<Container>
			<Header>
				<StyledLogo src={ReviewfyLogo} />
				<OrgName>Reviewfy</OrgName>
			</Header>
			<StateContainer>
				<TxnDetails>
					<TxnDetailsHeader>
						<StyledHeading>Transaction Overview</StyledHeading>
					</TxnDetailsHeader>
					<TxnFormItem
						label={"Transaction Hash:"}
						labelValue={txnInfo && txnInfo["txnHash"]}
					/>
					<TxnFormItem label={"Status:"} labelValue={"Success"} tag={true} />
					<TxnFormItem
						label={"Token Rewarded:"}
						labelValue={txnInfo && txnInfo["tokenValue"]}
					/>
					<div style={{ padding: "1em" }}>
						<Button>
							<a
								href={txnInfo && txnInfo["polyscanURL"]}
								target="_blank"
								rel="noopener noreferrer"
							>
								{"View on PolyScan"}
							</a>
						</Button>
					</div>

					{/* <TxnFormItem
						label={"BlockHash:"}
						labelValue={txnInfo && txnInfo["blockHash"]}
					/>
					<TxnFormItem
						label={"Block# :"}
						labelValue={parseInt(txnInfo && txnInfo["blockNumber"], 16)}
					/>
					<TxnFormItem
						label={"Timestamp:"}
						labelValue={formatDate(txnInfo && txnInfo["createdAt"])}
					/>
					<TxnFormItem
						label={"From:"}
						labelValue={txnInfo && txnInfo["from"]}
					/>
					<TxnFormItem label={"To:"} labelValue={txnInfo && txnInfo["to"]} />
					<TxnFormItem
						label={"Gas Price"}
						labelValue={parseInt(txnInfo && txnInfo["gasPrice"], 16)}
					/>
					<TxnFormItem
						label={"Gas"}
						labelValue={parseInt(txnInfo && txnInfo["gas"], 16)}
					/>
					<TxnFormItem
						label={"Nonce"}
						labelValue={parseInt(txnInfo && txnInfo["nonce"], 16)}
					/> */}
				</TxnDetails>
				<TxnDetails>
					<TxnDetailsHeader>
						<StyledHeading>Form Information</StyledHeading>
					</TxnDetailsHeader>
					<TxnFormItem
						label={"Form Name:"}
						labelValue={txnInfo && txnInfo["formName"]}
					/>
					<TxnFormItem
						label={"Submitted Time:"}
						labelValue={formatDate(txnInfo && txnInfo["createdAt"])}
					/>
					<TxnFormItem
						label={"Subset of Batch:"}
						labelValue={`${txnInfo && txnInfo["surveyHash"]}.txt`}
					/>

					<TxnFormItem label={"Your Submitted Responses:"} labelValue={""} />

					<ContentsContainer
						form={txnInfo && txnInfo["feedbackForm"]}
					></ContentsContainer>
				</TxnDetails>
			</StateContainer>
		</Container>
	);
};

export default ReviewfyTxn;
