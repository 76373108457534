import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, message, Image, Tooltip } from "antd";
import { sendEmail } from "../../../../store/slices/dispatcherSlice";
import styled from "styled-components";
import { NoFormSelected } from "../../../../assets/index";
import FormSelector from "../../common/FormSelector";
import { validateEmail } from "../../../../helpers/utils";
import { InfoCircleTwoTone } from "@ant-design/icons";
import introJs from "intro.js";

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 20px;
	padding: 10px;
	height: calc(100vh - 125px);
	background: #f5f6fa;
`;

const MailerContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border-radius: 12px;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
	position: relative;
	background: #ffffff;
`;

const HeadingText = styled.div`
	width: 100%;
	padding: 12px;
	font-weight: 600;
	text-align: left;
	color: var(--primaryColor);
	border-radius: 12px 12px 0px 0px;
	text-transform: uppercase;
	background-color: rgb(29, 189, 255, 0.1);
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 5px;
`;

const ReceipientWrapper = styled.div`
	width: 100%;
	height: 80px;
	padding: 10px;
`;

const StyledLabel = styled.label`
	display: block;
	padding: 6px;
	text-align: left;
	font-weight: 500;
`;

const FormPreviewer = styled.div`
	width: 100%;
	padding: 8px;
	min-height: 40%;
	max-height: 70%;
`;

const PreviewImage = styled.div`
	margin: 0px auto;
	height: 80%;
	width: 50%;
	border-radius: 10px;
	cursor: pointer;
	border: solid 1px #c4d2eb;
	padding: 2px;
`;

const StyledButton = styled.div`
	width: 15%;
	height: 45px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 8px 12px 0 #1db6ff5c;
	border: solid 1px var(--primaryColor);
	background-color: ${(props) =>
		props.color ? props.color : `var(--primaryColor);`}
	color: white;
	cursor: pointer;
	margin-left: 20px;
	font-weight: 500;
`;

const defaultMailOptions = {
	custEmail: "",
	mailSubject: "",
};

const SingleDispatcher = () => {
	const dispatch = useDispatch();
	const [mailOptions, setMailOptions] = useState({ ...defaultMailOptions });
	const [selectedForm, setSelectedForm] = useState("");

	const { orgId } = JSON.parse(localStorage.getItem("authInfo"));

	const handleSendForm = async () => {
		if (!validateEmail(mailOptions["custEmail"])) {
			message.error("Please enter a valid email.");
			return null;
		} else {
			let emailValues = {
				email: {
					id: mailOptions["custEmail"],
					subject: mailOptions["mailSubject"],
				},
				formId: selectedForm,
				orgId: orgId,
			};
			try {
				dispatch(sendEmail(emailValues));
				setMailOptions({ ...defaultMailOptions });
			} catch (error) {
				message.error(error);
			}
		}
	};

	const handleMailOptions = ({ name, value }) => {
		if (name === "email") {
			setMailOptions({ ...mailOptions, custEmail: value });
		} else if (name === "subject") {
			setMailOptions({ ...mailOptions, mailSubject: value });
		}
	};

	const renderFormPreview = () => {
		if (selectedForm) {
			return `https://kuber-dev.s3.amazonaws.com/${orgId}/resources/templates/${selectedForm}.png`;
		}
		return NoFormSelected;
	};

	const startTutorial = () => {
		introJs()
			.setOptions({
				showProgress: true,
				steps: [
					{
						element: document.getElementById("introjs-step-1"),
						intro:
							"<h3>Enter the email address you want the form to be sent.</h3>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-2"),
						intro: "<h3>Add Subject of the email here.<h3>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-3"),
						intro:
							"<h3>Select a form from here to dispatch it to your Customer/End user.</h3>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-4"),
						intro: "<h3>Click here to send the form.</h3>",
						position: "top",
					},
				],
			})
			.start();
	};

	return (
		<Container>
			<MailerContainer>
				<HeadingText>
					{"Send Form to individuals \t"}
					<span className="pt-cursor" onClick={startTutorial}>
						<Tooltip title={"Click here to learn how to create a Question"}>
							{<InfoCircleTwoTone></InfoCircleTwoTone>}
						</Tooltip>
					</span>
				</HeadingText>
				<ReceipientWrapper id="introjs-step-1">
					<StyledLabel for="email-input">To</StyledLabel>
					<Input
						id="email-input"
						type={"email"}
						name={"email"}
						required={true}
						value={mailOptions.custEmail}
						onChange={(e) => handleMailOptions(e.target)}
					></Input>
				</ReceipientWrapper>
				<ReceipientWrapper id="introjs-step-2">
					<StyledLabel for="email-subject">Subject</StyledLabel>
					<Input
						id="email-subject"
						type={"text"}
						name={"subject"}
						value={mailOptions.mailSubject}
						onChange={(e) => handleMailOptions(e.target)}
					></Input>
				</ReceipientWrapper>
				<FormPreviewer>
					<StyledLabel>Form Preview</StyledLabel>
					<PreviewImage>
						{selectedForm ? (
							<Image
								src={renderFormPreview()}
								preview={true}
								height={"100%"}
								style={{
									borderRadius: "30px",
									objectFit: "contain",
									boxShadow: "0 3px 16px 0 rgba(149, 171, 211, 0.22)",
								}}
							/>
						) : (
							<div
								style={{
									height: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>
								<img
									src={NoFormSelected}
									height={"60%"}
									alt={"brokenImg"}
								></img>
								<span
									style={{
										color: "#6c80a3",
										fontWeight: 500,
										padding: "10px",
									}}
								>
									{"Please select a form to be dispatched"}
								</span>
							</div>
						)}
					</PreviewImage>
				</FormPreviewer>
				<ButtonContainer>
					<StyledButton
						color={"white"}
						onClick={() => {
							setMailOptions({ ...defaultMailOptions });
							setSelectedForm("");
						}}
					>
						Discard
					</StyledButton>
					<StyledButton id="introjs-step-4" onClick={() => handleSendForm()}>
						Send
					</StyledButton>
				</ButtonContainer>
			</MailerContainer>
			<div id="introjs-step-3">
				<FormSelector
					style={{ background: "red" }}
					title={"Select Form to Dispatch"}
					setSelectedForm={setSelectedForm}
					selectedForm={selectedForm}
				/>
			</div>
		</Container>
	);
};

export default SingleDispatcher;
