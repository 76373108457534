import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { pageLoaded } from "./uiSlice";
import { supportedEmailProviders } from "../../helpers/utils";
import FileSaver from "file-saver";
import axios from "axios";

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

function getBearerToken() {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
}

export const sendEmail = createAsyncThunk(
	"dispatch/email",
	async (emailValues, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;

		const response = await axios.post(
			`${apiEndPoint}/api/dispatcher/email`,
			{ ...emailValues, workspaceId: selectedWorkspaceId },
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		notification.success({
			message: "Email dispatched successfully!",
			description: "Recipient would have received the form!",
		});
		return response.data;
	}
);

export const sendFormViaWhatsapp = createAsyncThunk(
	"dispatch/whatsapp",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;
		const response = await axios.post(
			`${apiEndPoint}/api/dispatcher/whatsapp`,
			{ ...params, workspaceId: selectedWorkspaceId },
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const sendDiscordMsg = createAsyncThunk(
	"dispatch/discord",
	async (discordValues, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;

		const response = await axios.post(
			`${apiEndPoint}/api/dispatcher/discord`,
			{ ...discordValues, workspaceId: selectedWorkspaceId },
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		notification.success({
			message: "Discord Message Dispatched!",
			description: "The form is posted in the discord channel!",
		});
		return response.data;
	}
);

export const sendSlackMsg = createAsyncThunk(
	"dispatch/slack",
	async (slackValues, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;

		const response = await axios.post(
			`${apiEndPoint}/api/dispatcher/slack`,
			{ ...slackValues, workspaceId: selectedWorkspaceId },
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		notification.success({
			message: "Dispatched form to Slack!",
			description: "The form is posted in the slack channel!",
		});
		return response.data;
	}
);

export const fetchFreeTrialStatus = createAsyncThunk(
	"dispatcher/freeTrialStatus",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(
			`${apiEndPoint}/api/dispatcher/freetrail-status`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const updateFreeTrialState = createAsyncThunk(
	"dispatcher/updateFreeTrialState",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/dispatcher/freetrail-status`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const downloadTemplate = createAsyncThunk(
	"dispatcher/downloadTemplate",
	async (params = {}, ThunkAPI) => {
		const response = await axios.get(
			`${apiEndPoint}/api/dispatcher/download-template?channel=${params.channel}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		const csvData = new Blob([response.data], {
			type: "text/csv;charset=utf-8;",
		});
		FileSaver.saveAs(
			csvData,
			`${params.channel}-bulk-dispatcher-format-reviewfy.csv`
		);
		return response.data;
	}
);

const dispatcherSlice = createSlice({
	name: "dispatcher",
	initialState: {
		freeTrialActivated: false,
		freeTrialUnitsRemaining: "-",
		defaultEmailDispatcher: null,
	},
	reducers: {
		updateDefaultDispatcher: (state, action) => {
			const { dispatcher } = action.payload;
			if (supportedEmailProviders.hasOwnProperty(dispatcher))
				state.defaultEmailDispatcher = supportedEmailProviders[dispatcher];
		},
	},
	extraReducers: {
		[sendEmail.fulfilled]: (state, action) => {},
		[sendDiscordMsg.fulfilled]: (state, action) => {},
		[fetchFreeTrialStatus.fulfilled]: (state, action) => {
			const {
				freeTrialActivated,
				freeTrialUnitsRemaining,
				defaultEmailDispatcher,
			} = action.payload;
			state.freeTrialActivated = freeTrialActivated;
			state.freeTrialUnitsRemaining = freeTrialUnitsRemaining;
			state.defaultEmailDispatcher = defaultEmailDispatcher;
		},
		[updateFreeTrialState.fulfilled]: (state, action) => {
			const {
				freeTrialActivated,
				defaultEmailDispatcher,
				freeTrialUnitsRemaining,
			} = action.payload;
			state.freeTrialActivated = freeTrialActivated;
			state.defaultEmailDispatcher = defaultEmailDispatcher;
			state.freeTrialUnitsRemaining = freeTrialUnitsRemaining;
		},
		[sendFormViaWhatsapp.fulfilled]: (state, action) => {},
	},
});

export const { updateDefaultDispatcher } = dispatcherSlice.actions;
export default dispatcherSlice.reducer;
