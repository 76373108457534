import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useDrop } from "react-dnd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getContentDisplayIcon } from "../../../../../helpers/utils";
import { removeBuilderContent } from "../../../../../store/slices/formSlice";
import { contentAdded } from "../../../../../store/slices/contentSlice";

const DropBox = styled.div`
	width: 100%;
	height: 100%;
	min-height: 200px;
	border-radius: 10px;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 5px;
	}
`;

const HighlightWrapper = styled.div`
	display: block;
	cursor: pointer;
	border-radius: 7px;
	margin: 8px;
	height: 80px;
	border: solid 1px #dee5f4;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border: solid 1px #dee5f4;
	:hover {
		border: solid 1.5px var(--primaryColor);
		box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	}
`;

const ContentIconWrapper = styled.div`
	height: 80px;
	width: 100%;
	position: relative;
	padding: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #001845;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ContentIconView = styled.img`
	max-width: 60px;
	max-height: 60px;
	display: inline-block;
	float: left;
`;

const IconLabelWrapper = styled.span`
	text-transform: capitalize;
	color: #001845;
`;

const RemoveContent = styled.span`
	position: absolute;
	right: 5px;
	padding: 5px;
	font-size: 14px;
	color: #dee5f5;
`;

const ResponseTypeIcon = styled.img`
	height: 12px;
	margin: 3px;
`;

const ResponseTypeWrapper = styled.div`
	height: 25px;
	width: 70px;
	text-align: center;
	font-size: 8px;
	font-weight: 500;
	position: absolute;
	right: 35px;
	border-radius: 30px;
	padding: 5px;
`;

const ContentTextDisplayCard = styled.div`
	height: 100%;
	width: 80%;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;
	background-color: #fff;
	padding: 5px;
`;

const ActionWrapper = styled.div`
	height: 100%;
	width: 20%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
`;

const ContentItemText = styled.span`
	font-size: 14px;
	color: #001845;
	position: absolute;
	left: 10px;
	top: 30%;
	margin: 5px;
	letter-spacing: 0.42px;
`;

const NoBuilderContents = styled.div`
	height: 100%;
	font-size: 14px;
	font-weight: 500;
	color: rgb(149, 171, 211);
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ContentFormDropBox = (props) => {
	const dispatch = useDispatch();

	const { builderContents } = useSelector((state) => state.forms);
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: props.accept,
		drop: props.onDrop,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop:
				builderContents && builderContents.length < 5 && monitor.canDrop(),
		}),
	});

	const isActive = isOver && canDrop;
	let backgroundColor = "#fff";

	if (isActive) {
		backgroundColor = "#d3d3d3";
	} else if (canDrop) {
		//dragging
		backgroundColor = "pink";
	}

	const handleRemoveContent = (content) => {
		dispatch(removeBuilderContent(content));
		dispatch(contentAdded(content));
	};

	return (
		<DropBox ref={drop} style={{ backgroundColor }} accept={["contents"]}>
			<div>
				{builderContents &&
					builderContents.map((item) => {
						const { queryValue, responseType, iconLabel } = item;
						if (item.queryType === "text") {
							return (
								<HighlightWrapper>
									<ContentTextDisplayCard>
										<ContentItemText>{queryValue}</ContentItemText>
									</ContentTextDisplayCard>
									<ActionWrapper>
										<ResponseTypeWrapper>
											<ResponseTypeIcon
												src={getContentDisplayIcon(responseType)}
											/>
										</ResponseTypeWrapper>
										<RemoveContent onClick={() => handleRemoveContent(item)}>
											<CloseCircleOutlined />
										</RemoveContent>
									</ActionWrapper>
								</HighlightWrapper>
							);
						} else if (item.queryType === "icon") {
							return (
								<HighlightWrapper>
									<ContentIconWrapper>
										<IconLabelWrapper>{iconLabel}</IconLabelWrapper>
										<ContentIconView src={queryValue}></ContentIconView>
									</ContentIconWrapper>
									<ActionWrapper>
										<ResponseTypeWrapper>
											<ResponseTypeIcon
												src={getContentDisplayIcon(responseType)}
											/>
										</ResponseTypeWrapper>
										<RemoveContent onClick={() => handleRemoveContent(item)}>
											<CloseCircleOutlined />
										</RemoveContent>
									</ActionWrapper>
								</HighlightWrapper>
							);
						}
						// This is just to suppress the warning
						return null;
					})}
			</div>

			{builderContents && builderContents.length === 0 && (
				<NoBuilderContents>
					<span>
						{"Drag and Drop contents from the left to build a form ! "}
					</span>
				</NoBuilderContents>
			)}
		</DropBox>
	);
};

export default ContentFormDropBox;
