import { combineReducers } from "redux";

import authSlice from "../store/slices/authSlice";
import approvalSlice from "../store/slices/approvalSlice";
import formSlice from "../store/slices/formSlice";
import uiSlice from "../store/slices/uiSlice";
import contentSlice from "../store/slices/contentSlice";
import metricSlice from "../store/slices/metricSlice";
import dispatcherSlice from "../store/slices/dispatcherSlice";
import settingsSlice from "../store/slices/settingSlice";
import environmentSlice from "../store/slices/environmentSlice";

export default combineReducers({
	ui: uiSlice,
	auth: authSlice,
	analytics: metricSlice,
	approvals: approvalSlice,
	contents: contentSlice,
	dispatcher: dispatcherSlice,
	forms: formSlice,
	environment: environmentSlice,
	settings: settingsSlice,
});
