import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";

const Container = styled.div`
	background-color: #8ec5fc;
	background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100vh;
`;

const ActivateContainer = styled.div`
	width: 50%;
	background: white;
	border-radius: 4px;
	padding: 1em;
	height: auto;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
	@media screen and (min-width: 801px) and (max-width: 1200px) {
		width: 50%;
	}
	@media screen and (min-width: 1200px) and (max-width: 2900px) {
		width: 25%;
	}
`;

const AccountActivation = () => {
	const [activation, setActivation] = useState(true);
	const history = useHistory();
	const searchResponse = useLocation().search;
	const authType = new URLSearchParams(searchResponse).get("authType");
	const pageState = new URLSearchParams(searchResponse).get("state");

	useEffect(() => {
		if (authType === "activate" && pageState === "success") {
			setActivation(true);
		} else if (authType === "login" && pageState === "success") {
			const orgName = new URLSearchParams(searchResponse).get("orgName");
			const orgId = new URLSearchParams(searchResponse).get("orgId");
			const email = new URLSearchParams(searchResponse).get("email");
			const token = new URLSearchParams(searchResponse).get("token");
			const ssoAuth = new URLSearchParams(searchResponse).get("ssoAuth");
			let tokenStorage = {
				orgName,
				orgId,
				email,
				token,
				ssoAuth,
			};
			localStorage.setItem("authInfo", JSON.stringify(tokenStorage));
			history.push("/workspaces");
		}
	}, []);

	return (
		<Container>
			{activation ? (
				<ActivateContainer>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h3>Account Activated Successfully!</h3>
						<p>You account has been activated successfully!</p>
						<Button
							block
							style={{
								marginTop: "1em",
								backgroundColor: "#12344d",
								color: "white",
							}}
							onClick={() => {
								window.location = "https://console.reviewfy.io/signin";
							}}
						>
							Login to your account now!
						</Button>
					</div>
				</ActivateContainer>
			) : (
				""
			)}
		</Container>
	);
};

export default AccountActivation;
