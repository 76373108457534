import React from "react";
import styled from "styled-components";
import { ReviewfyCoin } from "../../../assets/index";
import { formatDate } from "../../../helpers/utils";
import { Tag } from "antd";
import { FormIcon } from "../../../assets/index";

const CellWrapper = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em;
	font-size: 0.8em;
	word-wrap: break-word;
	text-transform: ${(props) => (props.capsOn ? "capitalize" : "none")};
`;

export const unApprovedColumns = [
	{
		title: "S.No",
		dataIndex: "formName",
		key: "formName",
		width: "5%",
		align: "center",
		render: (item, record, index) => <p>{index + 1}</p>,
	},
	{
		title: "Recipient",
		dataIndex: "customerEmail",
		key: "email",
		align: "center",
		render: (tag, index) => {
			return <CellWrapper>{tag}</CellWrapper>;
		},
	},
	{
		title: "Form Name",
		dataIndex: "formName",
		key: "formName",
		align: "center",
		render: (formName, record) => {
			return (
				<CellWrapper capsOn={true}>
					<img
						src={(record.formHeader && record.formHeader.formIcon) || FormIcon}
						alt={"formIcon"}
						width={"30px"}
						height={"30px"}
					/>
					<span style={{ marginLeft: "1em" }}>{formName || ""}</span>
				</CellWrapper>
			);
		},
	},
	{
		title: "Token Value",
		dataIndex: "tokenValue",
		key: "tokenValue",
		align: "center",
		render: (tokenValue) => {
			return (
				<CellWrapper>
					<img
						src={ReviewfyCoin}
						alt={"formIcon"}
						width={"25px"}
						height={"25px"}
					/>
					<span style={{ marginLeft: "0.5em" }}>{tokenValue || 0}</span>
				</CellWrapper>
			);
		},
	},
	{
		title: "Submitted Time",
		dataIndex: "createdAt",
		key: "createdAt",
		align: "center",
		render: (createdAt) => {
			return <CellWrapper>{formatDate(createdAt)}</CellWrapper>;
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		align: "center",
		render: (tag, index) => {
			return (
				<CellWrapper>
					<Tag color={"red"} key={index}>
						{"UN-APPROVED"}
					</Tag>
				</CellWrapper>
			);
		},
	},
];

export const approvedColumns = [
	{
		title: "Recepient Id",
		dataIndex: "customerEmail",
		key: "email",
		render: (tag, index) => {
			return <CellWrapper>{tag}</CellWrapper>;
		},
	},
	{
		title: "Form Name",
		dataIndex: "formName",
		key: "formName",
		render: (formName, record) => {
			return (
				<CellWrapper capsOn={true}>
					<img
						src={(record.formHeader && record.formHeader.formIcon) || FormIcon}
						alt={"formIcon"}
						width={"30px"}
						height={"30px"}
					/>
					<span style={{ marginLeft: "1em" }}>{formName || ""}</span>
				</CellWrapper>
			);
		},
	},
	{
		title: "Token Value",
		dataIndex: "tokenValue",
		key: "tokenValue",
		render: (tokenValue) => {
			return (
				<CellWrapper>
					<img
						src={ReviewfyCoin}
						alt={"formIcon"}
						width={"25px"}
						height={"25px"}
					/>
					<span style={{ marginLeft: "0.5em" }}>{tokenValue || 0}</span>
				</CellWrapper>
			);
		},
	},
	{
		title: "Hash Value",
		dataIndex: "hashValue",
		key: "hashValue",
		align: "center",
		render: (tag) => {
			return (
				<p style={{ fontSize: "8px" }} key={tag}>
					{tag.toUpperCase()}
				</p>
			);
		},
	},
	{
		title: "Submitted Time",
		dataIndex: "createdAt",
		key: "createdAt",
		align: "center",
		render: (createdAt) => {
			return <CellWrapper>{formatDate(createdAt)}</CellWrapper>;
		},
	},
	{
		title: "Review Approved Time",
		dataIndex: "approvedAt",
		key: "approvedAt",
		align: "center",
		render: (approvedAt) => {
			return <CellWrapper>{formatDate(approvedAt)}</CellWrapper>;
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		align: "center",
		render: (tag, index) => {
			return (
				<Tag color={"green"} key={index}>
					{"APPROVED"}
				</Tag>
			);
		},
	},
];
