import React, { useState, useEffect } from "react";
import { Badge, Table } from "antd";
import { getReviewResponses } from "../../../store/slices/approvalSlice";
import styled from "styled-components";
import { approvedColumns } from "./TableConfig";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
	height: calc(100vh - 105px);
	width: calc(100vw - 70px);
	background-color: #f5f6fa;
`;

const FilterBar = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 1em;
`;

const ApprovedResponses = (props) => {
	const [dataSource, setDataSource] = useState([]);
	const dispatch = useDispatch();

	const { approvedResponses } = useSelector((state) => state.approvals);
	useEffect(() => {
		dispatch(getReviewResponses({ responseState: "approved" }));
	}, [dispatch]);

	useEffect(() => {
		setDataSource([...approvedResponses]);
	}, [approvedResponses]);

	return (
		<Container>
			<FilterBar>
				<span>
					Total Approved Responses :{" "}
					<Badge count={approvedResponses.length} showZero={true} />
				</span>
			</FilterBar>
			<Table
				dataSource={dataSource}
				columns={approvedColumns}
				align={"center"}
				scroll={{ y: "calc(65vh - 0.5em)" }}
				pagination={true}
				bordered={true}
			/>
		</Container>
	);
};

export default ApprovedResponses;
