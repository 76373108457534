import React, { useState } from "react";
import { Button, Drawer, Input, message, Select } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createWorkspace } from "../../../store/slices/environmentSlice";

const StyledLabel = styled.label`
	display: block;
	padding: 0.5em;
	width: 100%;
	margin-top: 1em;
`;

const AddWorkspace = (props) => {
	const dispatch = useDispatch();
	const { isVisible, setVisible } = props;
	const [wkName, setWkName] = useState("");
	const [selectedTeams, setSelectedTeams] = useState([]);
	const { groups } = useSelector((state) => state.settings);

	const addNewWrkSpace = async () => {
		if (wkName.trim().length > 3 && selectedTeams.length > 0) {
			const teamIds = selectedTeams.map((item) => item.value);
			const newWkSpace = {
				workspaceName: wkName.trim(),
				teamIds: [...teamIds],
			};
			dispatch(createWorkspace(newWkSpace));
			setVisible(false);
		} else {
			if (wkName.trim().length < 4) {
				message.error(
					"Workspace names cannot be empty and less than 3 characters."
				);
			} else {
				message.error("Workspaces must be assigned with teams to access them.");
			}
		}
	};

	return (
		<Drawer
			width={"25%"}
			title="Create New Workspace"
			placement="right"
			onClose={() => setVisible(false)}
			visible={isVisible}
		>
			<p>
				A workspace is a segregation of your question library, review forms and
				all your other configurations. Workspace can be access-restricted by
				assigning them to one or multiple teams.
			</p>
			<form>
				<div>
					<StyledLabel>Workspace Name</StyledLabel>
					<Input
						value={wkName}
						onChange={({ target }) => {
							setWkName(target.value);
						}}
					></Input>
				</div>
				<div>
					<StyledLabel>Assign Team</StyledLabel>
					<Select
						allowClear
						placeholder="Assign Groups to this workspace"
						labelInValue
						filterOption={false}
						style={{ width: "100%" }}
						mode="multiple"
						onChange={(newValue) => {
							setSelectedTeams(newValue);
						}}
						value={selectedTeams}
					>
						{groups &&
							groups.map((item) => {
								return (
									<Select.Option value={item._id}>
										{item.groupName}
									</Select.Option>
								);
							})}
					</Select>
				</div>
				<Button
					type="submit"
					block
					style={{ marginTop: "1em" }}
					onClick={addNewWrkSpace}
					disabled={wkName.trim().length > 3 ? false : true}
				>
					Create Workspace
				</Button>
			</form>
		</Drawer>
	);
};

export default AddWorkspace;
