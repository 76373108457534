import React, { useState } from "react";
import styled from "styled-components";
import { Image, Input, Button, message } from "antd";
import FormSelector from "../common/FormSelector";
import { NoFormSelected } from "../../../assets/index";
import { useSelector } from "react-redux";
import { DownloadOutlined, CopyTwoTone } from "@ant-design/icons";
import QRCode from "qrcode.react";
import { Fragment } from "react";

const Container = styled.div`
	width: 100%;
	padding: 20px;
	height: calc(100vh - 65px);
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 20px;
	place-items: center;
`;

const DispatchContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border-radius: 12px;
	border: solid 1px #dee5f4;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	display: flex;
	flex-direction: column;
`;
const HeadingText = styled.div`
	width: 100%;
	padding: 12px;
	font-weight: 600;
	text-align: left;
	color: var(--primaryColor);
	border-radius: 12px 12px 0px 0px;
	text-transform: uppercase;
	background-color: rgb(29, 189, 255, 0.1);
`;

const FormPreviewer = styled.div`
	width: 100%;
	padding: 8px;
	min-height: 50%;
	max-height: 80%;
	display: flex;
	flex-direction: ${(props) => (props.direction ? "column" : "row")};
	justify-content: space-evenly;
	align-items: center;
`;

const PreviewImage = styled.div`
	height: 80%;
	width: 50%;
	border-radius: 10px;
	cursor: pointer;
	border: solid 1px #c4d2eb;
	padding: 2px;
	display: flex;
	justify-content: center;
`;

const StyledLabel = styled.label`
	display: block;
	padding: 10px;
	text-align: left;
	font-weight: 500;
	color: ${(props) => (props.color ? props.color : "black")};
`;

const LinkWrapper = styled.div`
	width: 100%;
	min-height: 100px;
	padding: 10px;
	display: ${(props) => (props.visible ? "block" : "none")};
`;

const QRContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 25px;
	min-height: 200px;
`;

const LinkContainer = styled.div`
	width: 100%;
	backokground-color: green;
`;

const MessageText = styled.div`
	display: flex;
	min-height: 100px;
	justify-content: center;
	align-items: center;
	color: #6c80a3;
	font-weight: 500;
`;

const GlobalLink = () => {
	const [selectedForm, setSelectedForm] = useState("");
	const { allForms } = useSelector((state) => state.forms);

	const { orgId } = JSON.parse(localStorage.getItem("authInfo"));
	const selectedItem = allForms.find((form) => form._id === selectedForm);
	const publicURI = selectedItem && selectedItem.publicURI;


	const renderFormPreview = () => {
		if (selectedForm) {
			return `https://kuber-dev.s3.amazonaws.com/${orgId}/resources/templates/${selectedForm}.png`;
		}
		return NoFormSelected;
	};

	const copyToClipBoard = (shareLink) => {
		navigator.clipboard.writeText(shareLink);
		message.info("Form Link Copied to Clipboard");
	};

	const downloadQRCode = () => {
		const canvas = document.getElementById("form-qr-code");
		const pngUrl = canvas.toDataURL("image/png");

		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `form-${selectedForm}-qr-code.png`;
		downloadLink.click();
	};

	return (
		<Container>
			<DispatchContainer>
				<HeadingText>{"Get Publically Shareable form Link"}</HeadingText>
				<StyledLabel>Form Preview</StyledLabel>

				<FormPreviewer direction={selectedForm ? false : true}>
					{selectedForm ? (
						<Fragment>
							<PreviewImage>
								<Image
									src={renderFormPreview()}
									preview={true}
									height={"100%"}
									style={{
										borderRadius: "30px",
										objectFit: "contain",
										boxShadow: "0 3px 16px 0 rgba(149, 171, 211, 0.22)",
									}}
								/>
							</PreviewImage>
							<QRContainer>
								<StyledLabel color={"#6c80a3"}>Download QRCode</StyledLabel>
								<QRCode
									id={"form-qr-code"}
									size={200}
									level={"H"}
									value={`${publicURI}`}
								/>
								<Button
									icon={<DownloadOutlined />}
									type={"default"}
									style={{ marginTop: "15px" }}
									onClick={downloadQRCode}
								>
									{" "}
									Download
								</Button>
							</QRContainer>
						</Fragment>
					) : (
						<Fragment>
							<PreviewImage>
								<div
									style={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<img
										src={NoFormSelected}
										height={"60%"}
										alt={"brokenImg"}
									></img>
									<span
										style={{
											color: "#6c80a3",
											fontWeight: 500,
											padding: "10px",
										}}
									>
										{"Please select a form to be dispatched"}
									</span>
								</div>
							</PreviewImage>
							<MessageText>
								{
									"Once you select a form, You are provided with a sharable link and QR Code to embed anywhere "
								}
							</MessageText>
						</Fragment>
					)}
				</FormPreviewer>
				<LinkWrapper visible={selectedForm ? true : false}>
					<LinkContainer>
						<StyledLabel>Shareable Link</StyledLabel>
						<Input
							suffix={
								<CopyTwoTone onClick={() => copyToClipBoard(publicURI)} />
							}
							value={`${publicURI}`}
							disabled
							width={"100%"}
						></Input>
					</LinkContainer>
				</LinkWrapper>
			</DispatchContainer>
			<FormSelector
				title={"Select Form to post globally"}
				selectedForm={selectedForm}
				setSelectedForm={setSelectedForm}
			></FormSelector>
		</Container>
	);
};

export default GlobalLink;
