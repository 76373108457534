import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { message, Upload, Tooltip } from "antd";
import { ArrowLeftOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import ContentFormDropBox from "../DragAndDrop/ContentDropBox";
import {
	createForm,
	setBuilderContents,
	clearBuilderContents,
} from "../../../../../store/slices/formSlice";
import { contentRemoved } from "../../../../../store/slices/contentSlice";
import { FormManagementContext } from "../../../index";
import { getBase64, beforeImgUpload } from "../../../../../helpers/utils";
import { FormHeaderLogo } from "../../../../../assets/index";
import ImgCrop from "antd-img-crop";
import ContentList from "./ContentsList";
import ConfirmFormCreation from "./ConfirmFormCreation";
import introJs from "intro.js";

const Container = styled.div`
	width: 100%;
	height: calc(100vh - 60px);
	position: relative;
	display: flex;
	flex-direction: column;
	overflow-y: hidden; ;
`;

const FormNavBar = styled.div`
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const NavBarWrapper = styled.div`
	width: 30%;
	height: 60px;
	display: flex;
	align-items: center;
`;

const PageContext = styled.span`
	font-size: 1em;
	text-transform: uppercase;
	font-weight: 600;
	margin-left: 0.5em;
`;

const StyledButton = styled.button`
	width: 45%;
	height: 40px;
	min-width: 120px;
	margin-left: 0.5rem;
	border-radius: 5px;
	border: ${(props) =>
		props.primary ? "solid 0px #7b8eaf" : "solid 1px #7b8eaf"};
	background-color: ${(props) =>
		props.primary ? "var(--primaryColor)" : "#ffffff"};
	color: ${(props) => (props.primary ? "#ffffff" : "#000000")};
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FormCreationContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 1rem;
	padding: 1rem;
`;

const FormBuilderContainer = styled.div`
	width: 100%;
	height: calc(100vh - 150px);
	border: solid 1px #dee5f4;
	border-radius: 10px;
	background-color: #f8fbff;
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
`;

const FormHeader = styled.div`
	height: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 1rem 0 1rem;
	padding: 2px;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='grey' stroke-width='2.5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color: #ffffff;
`;

const FormMessage = styled.div`
	height: 10%;
	margin: 1rem 1rem 0 1rem;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='grey' stroke-width='2.5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FormBuilderBody = styled.div`
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='grey' stroke-width='2.5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 1rem;
	overflow-y: auto;
	padding: 1rem;
	min-height: 30vh;
`;

const FormMessageSpan = styled.span`
	padding: 10px;
	min-width: 200px;
	font-weight: 500;
	text-align: center;
	:empty::before {
		content: "Edit here to add a message";
		display: inline-block;
		font-weight: 400;
		color: #95abd3;
	}
	overflow: hidden;
`;

const FormIcon = styled.img`
	width: 50%;
	height: 50%;
	text-align: center;
`;

const AddNewForm = () => {
	const dispatch = useDispatch();
	const [previewForm, setPreviewForm] = useState(false);
	const [formHeader, setFormHeader] = useState({
		formName: "",
		formIcon: "",
		message: "",
		iconType: "",
		fileName: "",
	});

	const [tokenValue, setTokenValue] = useState(0);
	const { builderContents } = useSelector((state) => state.forms);
	const { selectedWorkspaceId } = useSelector((state) => state.environment);
	const { setSelectedSubNav } = useContext(FormManagementContext);

	const handleDrop = (content) => {
		if (builderContents && builderContents.length < 5) {
			dispatch(setBuilderContents(content));
			dispatch(contentRemoved(content));
		} else {
			message.warn("Cannot add more than 5 questions to a form");
		}
	};

	const handleTokenValue = (value) => {
		setTokenValue(value);
	};

	const handleCreateForm = async (formSS) => {
		try {
			let newFormValues = {
				formHeader,
				tokenValue,
				feedbackForm: builderContents,
				workspaceId: selectedWorkspaceId,
			};

			if (builderContents.length === 0) {
				message.error("Cannot create form without contents");
				return;
			} else if (formHeader["formName"] === "") {
				message.error("Cannot create form without form name");
				return;
			}
			dispatch(
				createForm({ formHeader, formValues: newFormValues, template: formSS })
			);
			message.success(
				"Form Creation Successful",
				3,
				(onclose = () => {
					setSelectedSubNav("displayForms");
					dispatch(clearBuilderContents());
				})
			);
			setPreviewForm(false);
		} catch (error) {
			console.log("Error", error);
			message.error(error.message);
		}
	};

	const validateCreateButton = () => {
		if (builderContents.length > 0 && formHeader["formName"] !== "") {
			setPreviewForm(true);
		} else if (formHeader["formName"] === "") {
			message.error("Please add a form title to create a form !");
		} else {
			message.error("Add contents to form to create !");
		}
	};

	const handleChange = async (info) => {
		if (info.file.status === "done") {
			const imageUrl = await getBase64(info.file.originFileObj);
			setFormHeader({ ...formHeader, formIcon: imageUrl });
		}
	};

	const handleCancel = () => {
		setSelectedSubNav("displayForms");

		dispatch(clearBuilderContents());
	};

	const startTutorial = () => {
		introJs()
			.setOptions({
				showProgress: true,
				steps: [
					{
						element: document.getElementById("introjs-step-1"),
						intro: "<h3>Add a Icon and Title to your form!</h3>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-2"),
						intro:
							"<h3>Add an optional descriptive message to your end users!<h3>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-3"),
						intro:
							"<div><h3>Drag Question from questions panel in the left to create your form</h3><img src='https://s3.amazonaws.com/reviewfy.io/images/DNDDemo.gif' width='100%'/></div>",
						position: "left",
					},
					{
						element: document.getElementById("introjs-step-4"),
						intro: "<h3>Click here to create you form.</h3>",
						position: "bottom",
					},
				],
			})
			.start();
	};

	return (
		<Container>
			<ConfirmFormCreation
				formHeader={formHeader}
				previewForm={previewForm}
				setPreviewForm={setPreviewForm}
				handleTokenValue={handleTokenValue}
				handleCreateForm={handleCreateForm}
			/>
			<FormNavBar>
				<NavBarWrapper>
					<ArrowLeftOutlined
						onClick={handleCancel}
						style={{ marginLeft: "1rem", fontSize: "1rem" }}
					/>
					<PageContext>
						{"create form \t\t"}
						<span className="pt-cursor" onClick={startTutorial}>
							<Tooltip title={"Click here to learn how to create a Question"}>
								{<InfoCircleTwoTone></InfoCircleTwoTone>}
							</Tooltip>
						</span>
					</PageContext>
				</NavBarWrapper>
				<NavBarWrapper>
					<StyledButton primary={false} onClick={handleCancel}>
						{"cancel"}
					</StyledButton>
					<StyledButton
						id="introjs-step-4"
						primary={true}
						onClick={() => validateCreateButton()}
					>
						{"create form"}
					</StyledButton>
				</NavBarWrapper>
			</FormNavBar>
			<FormCreationContainer>
				<ContentList />
				<FormBuilderContainer>
					<FormHeader id="introjs-step-1" data-step="1">
						<div
							style={{
								height: "100%",
							}}
						>
							<ImgCrop rotate>
								<Upload
									name="avatar"
									listType="picture-card"
									className="avatar-uploader"
									showUploadList={false}
									beforeUpload={beforeImgUpload}
									customRequest={({ file, onSuccess }) => {
										setTimeout(() => {
											onSuccess("ok");
										}, 0);
									}}
									onChange={handleChange}
									style={{ height: "80%" }}
								>
									{formHeader["formIcon"] ? (
										<img
											width={"100%"}
											height={"100%"}
											style={{ objectFit: "contain" }}
											src={formHeader["formIcon"]}
											alt="avatar"
										/>
									) : (
										<FormIcon src={FormHeaderLogo} />
									)}
								</Upload>
							</ImgCrop>
						</div>
						<FormMessageSpan
							contentEditable={true}
							suppressContentEditableWarning={true}
							onInput={(e) =>
								setFormHeader({
									...formHeader,
									formName: e.currentTarget.textContent,
								})
							}
						>
							{formHeader["formMessage"]}
						</FormMessageSpan>
					</FormHeader>
					<FormMessage id="introjs-step-2" data-step="2">
						<FormMessageSpan
							contentEditable={true}
							suppressContentEditableWarning={true}
							onInput={(e) =>
								setFormHeader({
									...formHeader,
									message: e.currentTarget.textContent,
								})
							}
						>
							{formHeader["formMessage"]}
						</FormMessageSpan>
					</FormMessage>
					<FormBuilderBody id="introjs-step-3" data-step="3">
						<ContentFormDropBox
							accept={["contents"]}
							onDrop={handleDrop}
						></ContentFormDropBox>
					</FormBuilderBody>
				</FormBuilderContainer>
			</FormCreationContainer>
		</Container>
	);
};

export default AddNewForm;
