import React, { useContext } from "react";
import styled from "styled-components";
import { SearchIcon } from "../../../assets/index";
import { FormManagementContext } from "../index";

const Container = styled.div`
	width: 100%;
	height: 70px;
	color: #001845;
	display: flex;
	justify-content: space-between;
`;

const HeadingText = styled.span`
	padding: 1rem;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	text-align: left;
`;

const RightItemsWrapper = styled.div`
	padding: 1em;
	min-width: 35%;
	display: flex;
	justify-content: space-between;
`;

const SearchBox = styled.div`
	width: 100%;
	height: 45px;
	background-color: #f8fbff;
	border-radius: 5px;
	padding: 10px;
	margin-right: 1rem;
	border: solid 1px #e7e7e7;
`;

const AddFormButton = styled.button`
	color: white;
	width: 30%;
	height: 45px;
	min-width: 200px;
	cursor: pointer;
	padding: 10px;
	font-weight: 600;
	background-color: var(--primaryColor);
	margin: 0px 0.5em;
	text-transform: uppercase;
	border-radius: 5px;
	border: solid 1px var(--primaryColor);
	box-shadow: 0 10px 20px 0 rgb(168 171 189 / 50%);
`;

const SearchInput = styled.input`
	background-color: #f8fbff;
	width: 80%;
	border: none;
	:focus {
		outline: none;
	}
`;

const TopNavBar = (props) => {
	const { setSelectedSubNav, setSelectedFormNavTab } = useContext(
		FormManagementContext
	);
	const {
		tabName,
		buttonText,
		setSearchText,
		navPath,
		secondaryButtonText,
		secondaryNavPath,
	} = props;
	return (
		<Container>
			<HeadingText>{tabName}</HeadingText>
			<RightItemsWrapper>
				<SearchBox>
					<SearchInput
						placeholder={`Search ${tabName}...`}
						onChange={(e) => setSearchText(e.target.value)}
					></SearchInput>
					<img src={SearchIcon} alt={""}></img>
				</SearchBox>
				<AddFormButton
					style={{ display: `${buttonText ? "block" : "none"}` }}
					onClick={() => setSelectedSubNav(navPath)}
				>
					{buttonText}
				</AddFormButton>
				<AddFormButton
					style={{ display: `${secondaryButtonText ? "block" : "none"}` }}
					onClick={() => {
						setSelectedFormNavTab("aicontent");
						setSelectedSubNav(secondaryNavPath);
					}}
				>
					{secondaryButtonText}
				</AddFormButton>
			</RightItemsWrapper>
		</Container>
	);
};

export default TopNavBar;
