import * as React from "react";
import "./waveStyles.css";

const WaveSvg = (props) => (
	<svg
		// className="waves"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 24 200 28"
		preserveAspectRatio="none"
		shapeRendering="auto"
		{...props}
	>
		<defs>
			<path
				id="gentle-wave"
				d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
			/>
		</defs>
		<g className="parallax">
			<use
				xlinkHref="#gentle-wave"
				x={48}
				y={0}
				fill="rgba(236,240,248,0.42)"
			/>
			<use
				xlinkHref="#gentle-wave"
				x={48}
				y={3}
				fill="rgba(236,240,248,0.42)"
			/>
			<use
				xlinkHref="#gentle-wave"
				x={48}
				y={5}
				fill="rgba(236,240,248,0.42)"
			/>
			<use xlinkHref="#gentle-wave" x={48} y={7} fill="#ecf0f8" />
		</g>
	</svg>
);

export default WaveSvg;
