import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataURLtoFile } from "../../helpers/utils";
import { pageLoaded } from "./uiSlice";
import axios from "axios";

const initialState = {
	allForms: [],
	builderContents: [],
};

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

function getBearerToken() {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
}

export const fetchForms = createAsyncThunk(
	"form/fetchForms",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;
		const response = await axios.get(
			`${apiEndPoint}/api/forms?workspaceId=${selectedWorkspaceId}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const removeForm = createAsyncThunk("form/delete", async (formId) => {
	const response = await axios.delete(`${apiEndPoint}/api/forms/${formId}`, {
		headers: {
			Authorization: `Bearer ${getBearerToken()}`,
		},
	});
	return { formId, ...response.data };
});

export const setFormAcceptance = createAsyncThunk(
	"form/acceptResponse",
	async (payload, ThunkAPI) => {
		const response = await axios.put(
			`${apiEndPoint}/api/forms/accept-responses`,
			payload,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		return response.data;
	}
);

export const createForm = createAsyncThunk(
	"form/create",
	async ({ formHeader, formValues, template }, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const data = new FormData();

		if (formHeader && formHeader["formIcon"] && formHeader["formIcon"] !== "") {
			const file = dataURLtoFile(
				formHeader["formIcon"],
				String(new Date().getTime())
			);
			data.append("formIcon", file, file.name);
		}
		if (template) {
			const file = dataURLtoFile(template, String(new Date().getTime()));
			data.append("template", file, file.name);
		}

		data.append("metadata", JSON.stringify(formValues));

		const response = await axios.post(`${apiEndPoint}/api/forms`, data, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
				"Content-Type": "multipart/form-data",
			},
		});
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

const formSlice = createSlice({
	name: "forms",
	initialState,
	reducers: {
		setBuilderContents: (state, action) => {
			state.builderContents.push(action.payload);
		},

		clearBuilderContents: (state, action) => {
			state.builderContents = [];
		},

		removeBuilderContent: (state, action) => {
			const updatedContents = state.builderContents.filter(
				(content) => content._id !== action.payload._id
			);

			state.builderContents = updatedContents;
		},
	},
	extraReducers: {
		[fetchForms.fulfilled]: (state, action) => {
			state.allForms = [...action.payload.forms];
		},

		[createForm.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			state.allForms.push(payload);
		},

		[removeForm.fulfilled]: (state, action) => {
			const updatedForms = state.allForms.filter(
				(form) => form._id !== action.payload.formId
			);
			state.allForms = updatedForms;
		},
		[setFormAcceptance.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			let index = state.allForms.findIndex(
				(form) => form._id === payload.formId
			);
			state.allForms[index]["acceptResponses"] = payload.acceptResponses;
		},
	},
});

export const {
	setBuilderContents,
	clearBuilderContents,
	removeBuilderContent,
} = formSlice.actions;
export default formSlice.reducer;
