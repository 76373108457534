import React from "react";
import styled from "styled-components";
import { message, Upload } from "antd";
import { CloseModal } from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { MagicWand, IconUpload } from "../../../../assets";
import {
	getIconsforQuestion,
	uploadCustomIcon,
} from "../../../../store/slices/contentSlice";
import ImgCrop from "antd-img-crop";

const SliderDrawer = styled.div`
	position: absolute;
	bottom: 0px;
	height: 100vh;
	width: 100%;
	background-color: rgba(30, 30, 30, 0.8);
	overflow: hidden;
	display: ${(props) => (props.open ? "block" : "none")};
	@keyframes slide-up {
		from {
			margin-top: 100%;
			height: 300%;
		}
		to {
			margin-top: 0%;
			height: 100%;
		}
	}
	animation: 1s slide-up;
	z-index: 1000;
`;

const IconContainer = styled.div`
	height: 65%;
	width: 100%;
	position: absolute;
	bottom: 0px;
	background: white;
	opacity: 1;
	z-index: 1000;
`;

const SliderHeader = styled.div`
	height: 80px;
	display: flex;
	padding: 1em;
	align-items: center;
	color: #ffffff;
	justify-content: space-between;
	background-color: var(--primaryColor);
`;

const IconGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(86px, 1fr));
	grid-auto-rows: 86px;
	grid-gap: 1em;
	padding: 1em;
	height: 65%;
	overflow-y: auto;
`;

const StyledButton = styled.button`
	width: 100%;
	height: 50px;
	padding: 10px;
	border-radius: 5px;
	background-color: var(--primaryColor);
	color: white;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;
`;

const IconWrap = styled.div`
	padding: 1em;
	border: ${(props) =>
		props.selected ? "1.3px solid var(--primaryColor)" : "1px solid #e1e4f2"};
	border-radius: 10px;
	cursor: pointer;
	box-shadow: ${(props) =>
		props.selected ? "0px 5px 10px rgba(96, 128, 245, 0.5)" : "none"};
	position: relative;
`;

const IconButton = styled.div`
	background-color: ${(props) =>
		props.primary ? "var(--primaryColor)" : "white"};
	color: ${(props) => (props.primary ? "white" : "var(--primaryColor)")};
	border: 2px solid #ffffff;
	padding: 0.6em;
	min-width: 100px;
	text-transform: uppercase;
	border-radius: 8px;
	margin-right: 0.8em;
	font-weight: 700;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	cursor: pointer;
`;

const CustomIconUpload = styled(Upload)`
	.ant-upload-list.ant-upload-list-text {
		display: none;
	}
`;

const IconPane = (props) => {
	const dispatch = useDispatch();
	const {
		selectedIcon,
		setSelectedIcon,
		showIconPanel,
		setShowIconPanel,
		aiText,
	} = props;
	const { iconList } = useSelector((state) => state.contents);

	const uploadContentIcons = async ({ file, fileList }) => {
		const uploadedFile = fileList.slice(-1).pop();
		let reader = new FileReader();
		reader.onload = async (e) => {
			try {
				console.log(
					"e.target.resulte.target.resulte.target.result",
					e.target.result
				);
				dispatch(
					uploadCustomIcon({
						bodyFormData: e.target.result,
						filename: uploadedFile.name.replace(" ", ""),
					})
				);
				// dispatch(updateIconList(data, "add"));
			} catch (err) {
				console.log(err);
				message.info("Unable to upload custom icon , Please try again later!");
			}
		};
		reader.readAsDataURL(uploadedFile.originFileObj);
	};

	const getIconsByTag = async () => {
		if (aiText.length > 5) {
			await dispatch(getIconsforQuestion({ questionText: aiText })).unwrap();
		} else {
			message.error("Question needed to generate icons.");
		}
	};

	return (
		<SliderDrawer open={showIconPanel}>
			<IconContainer>
				<SliderHeader>
					<span>{"Choose Icon"}</span>
					<div
						style={{
							width: "50%",
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							padding: "0px 1em",
						}}
					>
						<IconButton
							primary={true}
							style={{
								cursor: `${aiText.length > 0 ? "pointer" : "not-allowed"}`,
							}}
							onClick={() => getIconsByTag()}
						>
							<img
								alt={""}
								src={MagicWand}
								style={{ marginRight: "0.5em" }}
							></img>
							{"Ask AI"}
						</IconButton>
						<ImgCrop rotate={true} modalOk={"Crop and Upload"}>
							<CustomIconUpload
								accept={"image/png, image/jpeg, image/svg+xml, image/jpg"}
								onChange={({ file, fileList, event }) => {
									if (event) {
										(async () => {
											await uploadContentIcons({ file, fileList });
										})();
									}
								}}
								style={{ display: "none" }}
							>
								<IconButton>
									<img
										alt={""}
										src={IconUpload}
										style={{ marginRight: "0.5em" }}
									></img>
									{"Upload"}
								</IconButton>
							</CustomIconUpload>
						</ImgCrop>
						<img
							alt={""}
							src={CloseModal}
							width={"14px"}
							height={"14px"}
							style={{ cursor: "pointer" }}
							onClick={() => setShowIconPanel(false)}
						></img>
					</div>
				</SliderHeader>
				<IconGrid>
					{iconList &&
						iconList.map((item) => (
							<IconWrap
								onClick={() => setSelectedIcon({ ...item })}
								selected={item._id === (selectedIcon && selectedIcon._id)}
							>
								<img
									src={item.iconURL}
									width="100%"
									height="100%"
									style={{ objectFit: "contain" }}
									alt={""}
								></img>
								<div
									style={{
										position: "absolute",
										top: "5px",
										left: "-5px",
										fontSize: "8px",
										padding: "0px 5px",
										background: "var(--primaryColor)",
										color: "#ffffff",
										textTransform: "capitalize",
										fontWeight: 800,
										display: `${item && item["aiTag"] ? "block" : "none"}`,
									}}
								>
									{item && item["aiTag"]}
								</div>
							</IconWrap>
						))}
				</IconGrid>
				<div style={{ padding: "1em" }}>
					<StyledButton
						onClick={() => setShowIconPanel(false)}
						disabled={!(selectedIcon && selectedIcon._id)}
					>
						CHOOSE
					</StyledButton>
				</div>
			</IconContainer>
		</SliderDrawer>
	);
};

export default IconPane;
