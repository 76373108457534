import React from "react";
import sanitizeHtml from "sanitize-html";
import styled from "styled-components";
import {
	getContentDisplayIcon,
} from "../../../../helpers/utils";

const HighlightWrapper = styled.div`
	cursor: pointer;
	border-radius: 7px;
	margin: 8px;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: solid 1px #dee5f4;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	background-color: ${(props) =>
		props.selected ? "var(--primaryColor)" : "white"};
	:hover {
		box-shadow: 0 3px 16px 0 rgba(29, 122, 255, 0.15);
	}
`;

const ContentTextDisplayCard = styled.div`
	height: 100%;
	width: 80%;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;
	padding: 5px;
`;

const ContentItemText = styled.span`
	font-size: 14px;
	color: #001845;
	position: absolute;
	left: 10px;
	top: 30%;
	margin: 5px;
	letter-spacing: 0.42px;
`;

const ResponseTypeWrapper = styled.div`
	height: 20px;
	width: 70px;
	text-align: center;
	font-size: 8px;
	font-weight: 500;
	margin: 10px;
	border-radius: 30px;
`;

const IconLabelWrapper = styled.span`
	position: absolute;
	top: 35%;
	left: 90px;
	padding: 4px;
	text-transform: capitalize;
	color: #001845;
`;

const ContentIconView = styled.img`
	max-width: 60px;
	max-height: 60px;
	display: inline-block;
	float: left;
`;

const ResponseTypeIcon = styled.img`
	height: 12px;
	margin: 3px;
`;

const ContentIconWrapper = styled.div`
	height: 70px;
	width: 100%;
	position: relative;
	padding: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #001845;
`;

const ContentsCard = (props) => {
	const {
		queryValue,
		queryType,
		responseType,
		iconLabel,
		selectedId,
		handleContentSelect,
		_id,
	} = props;

	const sanitizeConf = {
		allowedTags: ["b", "i", "em", "strong", "a", "p", "u"],
		allowedAttributes: { a: ["href"] },
	};

	return (
		<div onClick={() => handleContentSelect(_id, responseType)}>
			{queryType === "text" ? (
				<HighlightWrapper contentId={_id} selected={selectedId === _id}>
					<ContentTextDisplayCard>
						<ContentItemText>
							{sanitizeHtml(queryValue, sanitizeConf)}
						</ContentItemText>
					</ContentTextDisplayCard>
					<ResponseTypeWrapper>
						<ResponseTypeIcon
							src={getContentDisplayIcon(responseType)}
						></ResponseTypeIcon>
					</ResponseTypeWrapper>
				</HighlightWrapper>
			) : (
				<HighlightWrapper selected={selectedId === _id}>
					<ContentIconWrapper>
						<ContentIconView src={queryValue}></ContentIconView>
						<IconLabelWrapper>{iconLabel}</IconLabelWrapper>
					</ContentIconWrapper>
					<ResponseTypeWrapper>
						<ResponseTypeIcon
							src={getContentDisplayIcon(responseType)}
						></ResponseTypeIcon>
					</ResponseTypeWrapper>
				</HighlightWrapper>
			)}
		</div>
	);
};

export default ContentsCard;
