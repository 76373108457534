import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Input } from "antd";
import {
	getChannelConfig,
	updateAPIKeys,
} from "../../../../../store/slices/settingSlice";
import { WhatsappIcon } from "../../../../../assets/index";
import { Breadcrumb } from "antd";

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1em;
	padding: 1em;
	box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px;
`;

const Message = styled.p`
	padding: 0.5em;
	text-transform: ${(props) =>
		props.textTransform ? props.textTransform : "uppercase"};
	text-align: left;
	line-height: 1.6;
`;

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding: 1em;
	background: #f5f6fa;
`;

const SubscriptionCard = styled.div`
	background: #ffffff;
	width: 40%;
	margin: 2em;
	border-radius: 2px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	display: flex;
	flex-direction: column;
	padding: 1em;
`;

const InputWrapper = styled.div`
	padding: 0.5em;
	font-weight: 500;
	display: flex;
	flex-direction: ${(props) => (props.flexDir ? props.flexDir : "row")};
	justify-content: space-between;
	align-items: ${(props) => (props.alignment ? props.alignment : "center")};
`;

const IntegLogo = styled.img`
	width: 60px;
	height: 60px;
	padding: 0.5em;
`;

const TitleWrap = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.5em;
`;

const Title = styled.span`
	font-weight: 600;
`;

const StyledButton = styled.button`
	padding: 0.5em 1em;
	border: none;
	font-weight: 500;
	border-radius: 4px;
	background: var(--primaryColor);
	color: white;
	cursor: pointer;
`;

const BreadcrumbWrapper = styled.div`
	text-transform: capitalize;
	font-size: 10px;
	padding: 1em;
`;

const WhatsAppConfig = () => {
	const dispatch = useDispatch();
	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const { whatsAppDispatcher } = useSelector((state) => state.settings);

	const [messageSID, setMessageSID] = useState("");
	const [twilioAuthToken, setAuthToken] = useState("");
	const [isEditable, setEditable] = useState(true);
	const editButton = useRef("add");

	const getWhatsAppConfig = useCallback(
		() =>
			dispatch(
				getChannelConfig({ channel: "whatsapp", integration: "twilio" })
			),
		[dispatch]
	);

	useEffect(() => {
		if (whatsAppDispatcher) {
			const { msgSid, authToken } = whatsAppDispatcher;
			setMessageSID(msgSid);
			setAuthToken(authToken);
			setEditable(false);
		}
	}, [whatsAppDispatcher]);

	useEffect(() => {
		getWhatsAppConfig();
	}, [getWhatsAppConfig]);

	const handleAPIKeyChange = async () => {
		setEditable(!isEditable);
		if (!isEditable) {
			editButton.current.name = "edit";
			editButton.current.innerHTML = "Update Configuration";
		} else {
			editButton.current.name = "add";
			editButton.current.innerHTML = "Modify Configuration";
			const { msgSid, authToken } = whatsAppDispatcher;
			if (
				messageSID &&
				twilioAuthToken &&
				(messageSID !== msgSid || twilioAuthToken !== authToken)
			) {
				dispatch(
					updateAPIKeys({
						msgSid: messageSID,
						authToken: twilioAuthToken,
						channel: "whatsapp",
					})
				);
			}
		}
	};

	return (
		<Container>
			<Header>
				<BreadcrumbWrapper>
					<Breadcrumb separator=">">
						<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings`}>
							{"Settings"}
						</Breadcrumb.Item>
						<Breadcrumb.Item href={`/${selectedWorkspaceId}/settings/channels`}>
							{"Channels"}
						</Breadcrumb.Item>
					</Breadcrumb>
				</BreadcrumbWrapper>
				<Message>TWILIO WHATSAPP BUSINESS</Message>
				<Message textTransform={"none"}>
					Integrate your Twilio Whatsapp with Reviewfy, You can send out forms
					to your users to their whatsapp - Paste in your Message SID and API
					keys to get started!
				</Message>
			</Header>
			<FlexBox>
				<SubscriptionCard>
					<TitleWrap style={{ justifyContent: "space-between" }}>
						<TitleWrap style={{ maxWidth: "70%" }}>
							<IntegLogo src={WhatsappIcon}></IntegLogo>
							<Title>WhatsApp Business Configuration</Title>
						</TitleWrap>
						<StyledButton
							name={"add"}
							ref={editButton}
							onClick={handleAPIKeyChange}
						>
							Add Configuration
						</StyledButton>
					</TitleWrap>
					<Message textTransform={"none"}>
						Add your Twilio Whatsapp Message SID and API Keys ! If your not sure
						on how to get your Message SID and API keys - Follow this{" "}
						<a
							target="_blank"
							href="https://www.twilio.com/docs/iam/access-tokens#creating-tokens"
							rel="noopener noreferrer"
						>
							link
						</a>{" "}
						!
					</Message>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your Whatsapp Message SID Key</p>
						<Input
							value={messageSID}
							onChange={({ target }) => setMessageSID(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
					<InputWrapper flexDir={"column"} alignment={"flex-start"}>
						<p>Your Twilio Whatsapp API Key</p>
						<Input
							value={twilioAuthToken}
							onChange={({ target }) => setAuthToken(target.value)}
							disabled={!isEditable}
						/>
					</InputWrapper>
				</SubscriptionCard>
			</FlexBox>
		</Container>
	);
};

export default WhatsAppConfig;
