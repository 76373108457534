import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getBearerToken } from "../../helpers/utils";
import { pageLoaded } from "./uiSlice";

const initialState = {
	unapprovedResponses: [],
	approvedResponses: [],
};

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

export const getReviewResponses = createAsyncThunk(
	"approval/unapprovedresponses",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const { environment } = ThunkAPI.getState();
		const { selectedWorkspaceId } = environment;
		const response = await axios.get(
			`${apiEndPoint}/api/approval/reviewfy-responses?workspaceId=${selectedWorkspaceId}&status=${params.responseState}`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		)

		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const approveResponses = createAsyncThunk(
	"approval/approveresponses",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/approval/approveResponses`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const getResponseHash = createAsyncThunk(
	"approval/getResponseHash",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/approval/get-survey-hash`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const validateReviewState = createAsyncThunk(
	"approvals/validate-reviews-state",
	async (params, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/approval/validate-reviews-state`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

export const getTxnInformation = createAsyncThunk(
	"approvals/getTxnInfo",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/transactions/getTxnInformation`,
			params,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

const approvalSlice = createSlice({
	name: "approvals",
	initialState,
	reducers: {
		updateUnApprovedResponses: (state, action) => {
			state.unapprovedResponses = [...action.payload];
		},
	},
	extraReducers: {
		[getReviewResponses.fulfilled]: (state, action) => {
			const { approvalState, responses } = action.payload;
			if (approvalState === "unapproved") {
				state.unapprovedResponses = responses;
				
			} else if (approvalState === "approved") {
				state.approvedResponses = responses;
			}
		},
		[getTxnInformation.fulfilled]: (state, action) => {
			state.txnInfo = { ...action.payload };
		},
		[approveResponses.fulfilled]: (state, action) => {},
		[validateReviewState.fulfilled]: (state, action) => {},
		[getResponseHash.fulfilled]: (state, action) => {},
	},
});

export const { updateUnApprovedResponses } = approvalSlice.actions;
export default approvalSlice.reducer;
