import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { pageLoaded } from "./uiSlice";

let apiEndPoint = window.location.origin;

if (window.location.origin === "http://localhost:3000") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

function getBearerToken() {
	const bearerToken = JSON.parse(localStorage.getItem("authInfo"));
	return bearerToken ? bearerToken.token : null;
}

const initialState = {
	selectedWorkspaceId: null,
	automateWorkspace: false,
	workspaces: [],
	teams: [],
};

export const createWorkspace = createAsyncThunk(
	"workspace/create",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));

		const response = await axios.post(`${apiEndPoint}/api/workspaces`, params, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});

		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		if (response.data && response.data.code === "NEW_WORKSPACE_CREATED") {
			message.success("Workspace Created Successfully !");
			return response.data;
		}
	}
);

export const getWorkspaces = createAsyncThunk(
	"workspace/fetch",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(`${apiEndPoint}/api/workspaces`, {
			headers: {
				Authorization: `Bearer ${getBearerToken()}`,
			},
		});
		ThunkAPI.dispatch(pageLoaded({ loading: false }));

		return response.data;
	}
);

export const automateWorkspaceCreation = createAsyncThunk(
	"workspace/automate",
	async (params = {}, ThunkAPI) => {
		ThunkAPI.dispatch(pageLoaded({ loading: true }));
		const response = await axios.get(
			`${apiEndPoint}/api/workspaces/auto-generate-workspace`,
			{
				headers: {
					Authorization: `Bearer ${getBearerToken()}`,
				},
			}
		);
		ThunkAPI.dispatch(pageLoaded({ loading: false }));
		return response.data;
	}
);

const environmentSlice = createSlice({
	name: "workspaces-teams",
	initialState,
	reducers: {
		setSelectedWorkspaceId: (state, action) => {
			const { workspaceId } = action.payload;
			state.selectedWorkspaceId = workspaceId;
		},
		cancelWorkspaceAutomation: (state, action) => {
			state.automateWorkspace = false;
		},
	},
	extraReducers: {
		[createWorkspace.fulfilled]: (state, action) => {
			const responsePayload = action.payload;
			state.workspaces.push(responsePayload.payload);
		},
		[getWorkspaces.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			const { workspaces } = payload;
			if (workspaces.length === 0) {
				state.automateWorkspace = true;
			}
			state.workspaces = [...workspaces];
		},
		[automateWorkspaceCreation.fulfilled]: (state, action) => {
			const { payload } = action.payload;
			const { workspaces } = payload;
			state.automateWorkspace = false;
			state.workspaces = [...workspaces];
		},
	},
});

export const { setSelectedWorkspaceId, cancelWorkspaceAutomation } =
	environmentSlice.actions;
export default environmentSlice.reducer;
