import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
	ReviewfyLogo,
	TwillioSendGrid,
	MailIcon,
	WhatsappIcon,
} from "../../../../assets/index";

const Container = styled.div`
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1em;
	padding: 1em;
	box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px;
`;

const Message = styled.p`
	padding: 0.5em;
	text-transform: ${(props) =>
		props.textTransform ? props.textTransform : "uppercase"};
`;

const FlexBox = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 1em;
	background: #f5f6fa;
`;

const ChannelCardGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(200px, 220px));
	place-self: center;
	grid-gap: 1em;
	padding: 1em;
`;

const ChannelCard = styled.div`
	height: 220px;
	width: 220px;
	border: solid 1px #dee5f4;
	box-shadow: 0 4px 14px 0 rgba(80, 95, 123, 0.16);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #ffffff;
`;

const CardIcon = styled.img`
	width: 40%;
	height: 40%;
	padding: 1em;
`;

const ChannelSettings = () => {
	const history = useHistory();
	const { selectedWorkspaceId } = useSelector((state) => state.environment);
	const integrations = [
		{
			id: "reviewfy-freetrial",
			channelName: "Reviewfy Free Trial",
			navPath: "/settings/channel/reviewfy-free-trial",
			icon: ReviewfyLogo,
			description:
				"Reviewfy offers 10 free emails to send to your users as a trial.",
		},
		{
			id: "twillio-sendgrid",
			channelName: "Twilio SendGrid",
			navPath: "/settings/channel/sendgrid",
			icon: TwillioSendGrid,
			description: "Integrate your SendGrid account to send emails",
		},
		{
			id: "corporate-email",
			channelName: "Corporate Email",
			navPath: "/settings/channel/corporate-email",
			icon: MailIcon,
			description: "Use you organisation's email server to send out emails",
		},
		{
			id: "twillio-whatsapp",
			channelName: "WhatsApp Business",
			navPath: "/settings/channel/whatsapp",
			icon: WhatsappIcon,
			description:
				"Integrate your Twilio Whatsapp to send forms to whatsapp users ",
		},
	];
	return (
		<Container>
			<Header>
				<Message>Channel Configurations</Message>
				<Message textTransform={"none"}>
					{
						"Channel configuration allows you to select a channel of your choice like your own email, whatsapp etc., with the help of API keys to integrate."
					}
				</Message>
			</Header>
			<FlexBox>
				<ChannelCardGrid>
					{integrations.map((card) => (
						<ChannelCard
							onClick={() => {
								history.push(`/${selectedWorkspaceId}${card.navPath}`);
							}}
						>
							<CardIcon src={card.icon}></CardIcon>
							<p style={{ fontWeight: 500 }}>{card.channelName}</p>
							<p style={{ padding: "1em", fontSize: "10px" }}>
								{card.description}
							</p>
						</ChannelCard>
					))}
				</ChannelCardGrid>
			</FlexBox>
		</Container>
	);
};

export default ChannelSettings;

// import ChannelManager from "./Channels";
// import EmailConfiguration from "./components/EmailConfiguration";
// import WhatsappConfiguration from "./components/WhatsappConfiguration";

// export const Channels = React.createContext({
// 	selectedChannelTab: "",
// 	setSelectedChannelTab: (arg) => {},
// });

// const renderSelectedNavComponent = (selectedChannelTab) => {
// 	if (selectedChannelTab === "email") {
// 		return <EmailConfiguration></EmailConfiguration>;
// 	} else if (selectedChannelTab === "whatsapp") {
// 		return <WhatsappConfiguration></WhatsappConfiguration>;
// 	}
// };

// export default function ChannelConfiguration() {
// 	// const [selectedChannelTab, setSelectedChannelTab] = useState("email");

// 	return (
// 		<Channels.Provider
// 			value={{
// 				selectedChannelTab,
// 				setSelectedChannelTab,
// 			}}
// 		>
// 			<ChannelManager>
// 				{renderSelectedNavComponent(selectedChannelTab)}
// 			</ChannelManager>
// 		</Channels.Provider>
// 	);
// }
