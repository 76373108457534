import React, { useCallback } from "react";
import { Drawer, message } from "antd";
import styled from "styled-components";

import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchContentIcons,
	createContent,
} from "../../../../store/slices/contentSlice";
import CreateManualQuestion from "./CreateManually";
import { CloseModal, MagicWand } from "../../../../assets";
import IconPane from "./IconPane";
import CreateByAI from "./CreateByAI";

const Container = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 20%;
	padding: 1em;
	color: white;
	background-color: var(--primaryColor);
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
`;

const StyledDrawer = styled(Drawer)`
	.ant-drawer-body {
		padding: 0px;
	}
`;

const TabBar = styled.div`
	width: 100%;
	background-color: var(--primaryColor);
	display: flex;
	padding-left: 2em;
`;

const TabName = styled.div`
	padding: 0.5em;
	border-bottom: ${(props) => (props.selected ? "2px solid white" : "none")};
	font-weight: ${(props) => (props.selected ? "700" : "400")};
	font-size: 14px;
	line-height: 16px;
	margin-left: 0.2em;
	color: white;
	cursor: pointer;
`;

const ContentContainer = styled.div`
	padding: 2em;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
`;

const CreateContentPane = (props) => {
	const { isOpen, changePane, activeTab, setActiveTab } = props;
	const dispatch = useDispatch();
	const [showIconPanel, setShowIconPanel] = useState(false);
	const [selectedIcon, setSelectedIcon] = useState({});

	const [aiText, setAiText] = useState("");
	const [question, setQuestion] = useState({
		responseType: "",
		questionText: "",
		iconURL: "",
	});

	const { selectedWorkspaceId } = useSelector((state) => state.environment);

	const getContentIcons = useCallback(
		() => dispatch(fetchContentIcons()),
		[dispatch]
	);
	useEffect(() => {
		// introJs().addHints();
		getContentIcons();
	}, [getContentIcons]);

	const handleQuestionCreation = async (question) => {
		let contentTextValues = {};
		let questionType = "text";
		if (selectedIcon && Object.keys(selectedIcon).length) {
			questionType = "icon";
		}
		if (questionType === "icon") {
			contentTextValues = {
				queryType: "icon",
				iconLabel:
					activeTab === 1 ? aiText.slice(3) : question.questionText.trim(),
				queryValue: selectedIcon.iconURL,
				responseType: question.responseType,
				workspaceId: selectedWorkspaceId,
			};
		} else {
			contentTextValues = {
				queryType: "text",
				queryValue:
					activeTab === 1 ? aiText.slice(3) : question.questionText.trim(),
				responseType: question.responseType,
				workspaceId: selectedWorkspaceId,
			};
		}

		dispatch(createContent(contentTextValues));
		changePane(false);
		setQuestion({
			responseType: "",
			questionText: "",
			iconURL: "",
		});
		setSelectedIcon({});
		message.success("Question Creation Successful", 1);
	};

	return (
		<StyledDrawer
			visible={isOpen}
			width={"40%"}
			closeIcon={null}
			style={{ padding: "0px" }}
		>
			<Container>
				<Header>
					<span>{"Create a Question"}</span>
					<img
						alt={""}
						src={CloseModal}
						width={"18px"}
						height={"18px"}
						onClick={() => changePane(false)}
						style={{ cursor: "pointer" }}
					></img>
				</Header>
				<TabBar>
					<TabName selected={activeTab === 0} onClick={() => setActiveTab(0)}>
						{"Manual"}
					</TabName>
					<TabName selected={activeTab === 1} onClick={() => setActiveTab(1)}>
						<img
							alt={""}
							src={MagicWand}
							style={{ marginRight: "0.5em" }}
						></img>
						{"Ask AI"}
					</TabName>
				</TabBar>
				<IconPane
					showIconPanel={showIconPanel}
					setShowIconPanel={setShowIconPanel}
					selectedIcon={selectedIcon}
					setSelectedIcon={setSelectedIcon}
					aiText={aiText}
				></IconPane>
				<ContentContainer>
					{activeTab === 0 ? (
						<CreateManualQuestion
							setAiText={setAiText}
							question={question}
							setQuestion={setQuestion}
							selectedIcon={selectedIcon}
							closePane={setShowIconPanel}
							createQuestion={handleQuestionCreation}
							setSelectedIcon={setSelectedIcon}
						></CreateManualQuestion>
					) : (
						<CreateByAI
							aiText={aiText}
							setAiText={setAiText}
							question={question}
							setQuestion={setQuestion}
							selectedIcon={selectedIcon}
							closePane={setShowIconPanel}
							createQuestion={handleQuestionCreation}
							setSelectedIcon={setSelectedIcon}
						/>
					)}
				</ContentContainer>
			</Container>
		</StyledDrawer>
	);
};

export default CreateContentPane;
