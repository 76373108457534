import React, { useState, useEffect, useCallback } from "react";
import { Button, Input, message, notification, Select, Tooltip } from "antd";
import styled from "styled-components";
import {
	DiscordColorLogo,
	SlackLogo,
	DeleteIcon,
	NoDataFound,
} from "../../../../assets/index";
import { useDispatch, useSelector } from "react-redux";
import {
	addNewWebHook,
	getWebhooks,
	deleteWebhook,
} from "../../../../store/slices/settingSlice";
import PageEmptyState from "../../../common/PageEmptyState";
import { CopyOutlined } from "@ant-design/icons";

const Container = styled.div`
	height: calc(100vh - 60px);
	width: calc(100vw - 65px);
	display: flex;
	flex-direction: column;
`;

const ContextContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 4px;
	background: #f5f6fa;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1em;
`;

const AddWebHook = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 0.5fr;
	justify-content: center;
	align-items: end;
`;

const StyledParagraph = styled.p`
	font-weight: 500;
	font-size: 1em;
	text-align: left;
	padding: 0.5em;
`;

const Header = styled.div`
	text-align: left;
	padding: 0.5em;
	font-weight: 500;
`;

const FormItem = styled.div`
	padding: 0.5em;
	text-align: left;
`;

const StyledOption = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const SelectIcon = styled.img`
	width: 20px;
	height: 20px;
	margin-top: 2px;
	margin-right: 1em;
`;

const WebHookCardsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 250px));
	grid-template-rows: repeat(auto-fit, minmax(140px, 140px));
	grid-gap: 1rem;
	padding: 1em;
	height: calc(100vh - 35vh);
`;

const EmptyStateWrap = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	justify-content: center;
`;

const WebhookCard = styled.div`
	height: 130px;
	width: 100%;
	display: flex;
	padding: 0.5em;
	box-shadow: 0 10px 20px 0 rgba(168, 171, 189, 0.5);
	border: solid 1px #dee5f4;
	:hover {
		box-shadow: none;
		transition: 0.7s;
	}
`;

const HookName = styled.div`
	width: 80%;
	display: flex;
	border-right: solid 1px #dee5f4;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1em;
`;

const IconsTab = styled.div`
	width: 20%;
	height: 100%;
	display: flex;
	padding: 1em;
	cursor: pointer;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const Webhook = () => {
	const dispatch = useDispatch();

	const [webhookURL, setWebhookURL] = useState("");
	const [webhookName, setWebhookName] = useState("");
	const [dispatcher, setDispatcher] = useState("discord");

	const { webhooks } = useSelector((state) => state.settings);
	const fetchWebhooks = useCallback(() => dispatch(getWebhooks()), [dispatch]);

	useEffect(() => {
		fetchWebhooks();
	}, [fetchWebhooks]);

	const addWebHook = async () => {
		try {
			await dispatch(
				addNewWebHook({
					webhookURL,
					webhookName,
					dispatcher,
				})
			);
			setWebhookURL("");
			setWebhookName("");
		} catch (err) {
			console.log(err);
		}
	};

	const handleDelete = async (webHookId) => {
		try {
			dispatch(deleteWebhook({ webHookId }));
			notification.success({
				message: "Webhook Removed Successfully!",
			});
		} catch (err) {}
	};

	const copyURLtoClipboard = (webhookURL) => {
		navigator.clipboard.writeText(webhookURL);
		message.success("URL copied to clipboard");
	};

	return (
		<Container>
			<ContextContainer>
				<Header>WEBHOOKS</Header>
				<StyledParagraph>
					Add your Discord or Slack webhooks here to easily dispatch your forms
					to multiple channels, save them and use them later.
				</StyledParagraph>
				<AddWebHook>
					<FormItem>
						<label>{"Enter Webhook URL"}</label>
						<Input
							value={webhookURL}
							onChange={({ target }) => setWebhookURL(target.value)}
						></Input>
					</FormItem>
					<FormItem>
						<label>{"Name the Webhook URL"}</label>{" "}
						<Input
							value={webhookName}
							minLength={1}
							maxLength={25}
							onChange={({ target }) => setWebhookName(target.value)}
						></Input>
					</FormItem>
					<FormItem>
						<label>{"Dispatcher"}</label>
						<Select
							value={dispatcher}
							style={{ width: "100%" }}
							defaultValue={dispatcher}
							onChange={(value) => setDispatcher(value)}
						>
							<Select.Option value="discord">
								<StyledOption>
									<SelectIcon
										src={DiscordColorLogo}
										alt="discord icon"
									></SelectIcon>
									<span>{"Discord"}</span>
								</StyledOption>
							</Select.Option>
							<Select.Option value="slack">
								<StyledOption>
									<SelectIcon src={SlackLogo} alt="slack icon"></SelectIcon>
									<span>{"Slack"}</span>
								</StyledOption>
							</Select.Option>
						</Select>
					</FormItem>
					<FormItem>
						<Button
							disabled={webhookURL.length === 0 || webhookName.length === 0}
							onClick={() => addWebHook()}
						>
							{"Add WebHook"}
						</Button>
					</FormItem>
				</AddWebHook>
			</ContextContainer>
			{webhooks.length > 0 ? (
				<WebHookCardsGrid>
					{webhooks.map((item) => {
						const { _id, dispatcher, webhookName, webhookURL } = item;
						return (
							<WebhookCard key={_id}>
								<HookName>
									<img
										width={"35px"}
										height={"35px"}
										src={
											dispatcher === "discord" ? DiscordColorLogo : SlackLogo
										}
										alt={"channelLogo"}
									/>
									<span
										style={{
											padding: "1em",
											fontWeight: 400,
											fontSize: "0.8em",
										}}
									>
										{webhookName}
									</span>
								</HookName>
								<IconsTab>
									<Tooltip title={"Copy Webhook URL to clipboard"}>
										<CopyOutlined
											width={"35px"}
											height={"35px"}
											style={{ fontSize: "1.5rem" }}
											onClick={() => copyURLtoClipboard(webhookURL)}
										/>
									</Tooltip>
									<Tooltip title={"Delete this Webhook"}>
										<img
											width={"35px"}
											height={"35px"}
											src={DeleteIcon}
											alt={"deleteicon"}
											onClick={() => handleDelete(item._id)}
										/>
									</Tooltip>
								</IconsTab>
							</WebhookCard>
						);
					})}
				</WebHookCardsGrid>
			) : (
				<EmptyStateWrap>
					<PageEmptyState
						srcImage={NoDataFound}
						message={
							"You do not have any webhooks! Add one by filling out the fields above !"
						}
					></PageEmptyState>
				</EmptyStateWrap>
			)}
		</Container>
	);
};

export default Webhook;
