import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
	Integration,
	ThemeingIcon,
	WebhookIcon,
	Billing,
} from "../../assets/index";
// APIToken,

const Container = styled.div`
	width: 100%;
	height: 100%;
	background: #f5f6fa;
`;

const PageHeader = styled.div`
	text-align: left;
	height: 60px;
	display: block;
	padding: 1rem;
	font-size: 1em;
	font-weight: 600;
`;

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(180px, 180px));
	grid-template-rows: repeat(auto-fit, minmax(180px, 180px));
`;

const SettingItemCard = styled.div`
	width: 170px;
	height: 170px;
	margin: 1rem;
	padding: 1rem;
	border-radius: 5px;
	border: solid 1px #dee5f4;
	box-shadow: 0 4px 14px 0 rgba(80, 95, 123, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	cursor: pointer;
	background: #ffffff;
	:hover {
		box-shadow: none;
		transition: 0.7s;
		font-weight: 600;
	}
`;

const SettingItemIcon = styled.img`
	height: 70%;
	padding: 0.5rem;
	:hover {
		transform: rotate(-15deg);
		transition-duration: 0.8s;
	}
`;

const SettingItemName = styled.p`
	width: 100%;
	margin: 2px;
	padding: 0.5rem;
	color: #001845;
	text-transform: uppercase;
`;

const Settings = (props) => {
	const { selectedWorkspaceId } = useSelector((state) => state.environment);
	const settingItems = [
		{
			name: "Channels",
			icon: Integration,
			navToPath: `/${selectedWorkspaceId}/settings/channels`,
		},

		{
			name: "Display",
			icon: ThemeingIcon,
			navToPath: `/${selectedWorkspaceId}/settings/display`,
		},
		{
			name: "Webhooks",
			icon: WebhookIcon,
			navToPath: `/${selectedWorkspaceId}/settings/webhooks`,
		},
		{
			name: "Billing",
			icon: Billing,
			navToPath: `/${selectedWorkspaceId}/settings/billing`,
		},
	];

	return (
		<Container>
			<PageHeader>{"CONFIGURATION"}</PageHeader>
			<CardsContainer>
				{settingItems.map((card) => (
					<SettingItemCard onClick={() => props.history.push(card.navToPath)}>
						<SettingItemIcon src={card.icon}></SettingItemIcon>
						<SettingItemName>{card.name}</SettingItemName>
					</SettingItemCard>
				))}
			</CardsContainer>
		</Container>
	);
};

export default Settings;
